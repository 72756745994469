<template>
  <v-container fluid>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-2 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 my-2">
          連絡編集
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="d-flex flex-column">
          <v-row dense>
            <v-col cols="12" sm="7" md="8" lg="8" xl="8" class="mb-2 text-subtitle-1">
              対象グループ：{{ groupName }}
            </v-col>
            <v-col cols="12" sm="5" md="4" lg="4" xl="4" class="d-flex justify-end mb-2">

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small color="accent" v-bind="attrs" v-on="on" class="ma-2 black--text" @click="onClickCopyContact">コピー</v-btn>
                </template>
                <div>内容をコピーして新しい連絡を作成します</div>
                <div>※添付ファイルは引継ぎません</div>
              </v-tooltip>
            </v-col>
          </v-row>
        
          <ValidationProvider name="ユーザー区分" rules="required|checkboxRequired" v-slot="{ errors }">
            <v-row dense>
              <v-col cols="12" class="d-flex mb-2">
                <v-row>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3" class="d-flex">
                    <v-icon v-if="isTargetSelect == false">$cet_required</v-icon><div class="ml-2">対象ユーザー区分選択</div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-2" v-bind="attrs" v-on="on" color="orange">mdi-help-circle-outline</v-icon>
                      </template>
                      <div>グループに所属するどのユーザー区分へ</div>
                      <div>連絡を届けるか選択してください</div>
                    </v-tooltip>
                  </v-col>  
                  <v-col cols="4" sm="3" md="3" lg="3" xl="3" class="d-flex">
                    <v-checkbox v-model="selectedUserKbn"
                      label="生徒"
                      value="0"
                      class="mt-0"
                      :disabled="isTargetSelect == true || selectedUserKbnOld.has('0')"
                      hide-details="auto"
                    ></v-checkbox><div class="ml-2 mt-1" v-if="isTargetSelect">({{ studentIds.length }}人)</div>
                  </v-col>
                  <v-col cols="4" sm="3" md="3" lg="3" xl="2" class="d-flex">
                    <v-checkbox v-model="selectedUserKbn"
                      label="保護者"
                      value="1"
                      class="mt-0"
                      :disabled="isTargetSelect == true || selectedUserKbnOld.has('1')"
                      hide-details="auto"
                    ></v-checkbox><div class="ml-2 mt-1" v-if="isTargetSelect">({{ parentIds.length }}人)</div>
                  </v-col>
                  <v-col cols="4" sm="3" md="3" lg="3" xl="3" class="d-flex">
                    <v-checkbox v-model="selectedUserKbn"
                      label="教員"
                      value="8"
                      class="mt-0"
                      :disabled="isTargetSelect == true || selectedUserKbnOld.has('8')"
                      hide-details="auto"
                    ></v-checkbox><div class="ml-2 mt-1" v-if="isTargetSelect">({{ teacherIds.length }}人)</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <p v-if="isTargetSelect == false" class="error">{{ errors[0] }}</p>
          </ValidationProvider>
          <v-divider></v-divider>
          <v-row dense>
            <v-col cols="12" class="d-flex mt-4 justify-end">
              下書き
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="green" v-bind="attrs" v-on="on" small class="black--text mx-2" @click="openDraftSelect">参照</v-btn>
                </template>
                <span>保存されている下書き一覧を表示します</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="green" v-bind="attrs" v-on="on" small class="black--text mx-2" @click="addDraft">保存</v-btn>
                </template>
                <span>タイトルと本文を下書きとして保存します</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <ValidationProvider name="連絡タイトル" rules="required|max:50" v-slot="{ errors }">
            <v-row dense>
              <v-col cols="12" class="d-flex mb-0 mt-4">
                <v-text-field
                  v-model="title"
                  prepend-icon="$cet_required"
                  label="連絡タイトル"
                  placeholder=""
                  :error-messages="errors[0]"
                  outlined
                  dense
                  :counter="50"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </ValidationProvider>
          <ValidationProvider name="連絡本文" rules="required|max:3000" v-slot="{ errors }">
            <v-row dense>
              <v-col cols="12" class="d-flex mb-0">
                <v-textarea
                  v-model="body"
                  prepend-icon="$cet_required"
                  label="連絡本文"
                  placeholder=""
                  :error-messages="errors[0]"
                  outlined
                  dense
                  auto-grow
                  :counter="3000"
                  hide-details="auto"
                ></v-textarea>
              </v-col>
            </v-row>
          </ValidationProvider>

          <ValidationProvider name="公開開始日" rules="required" v-slot="{ errors }">
            <v-row dense>
              <v-col class="d-flex mb-2" cols="12" sm="12" md="12" lg="12" xl="12">
                <v-text-field
                  v-model="openDate"
                  prepend-icon="$cet_required"
                  label="公開開始日"
                  type="datetime-local"
                  :error-messages="errors[0]"
                  placeholder=""
                  outlined
                  dense
                  :disabled="lockOpenDateFlg"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </ValidationProvider>

          <ValidationProvider name="公開終了日" rules="required|datehourAfter:@公開開始日" v-slot="{ errors }">
            <v-row dense>
              <v-col cols="12" class="d-flex mb-2">
                <v-text-field
                  v-model="closeDate"
                  prepend-icon="$cet_required"
                  label="公開終了日"
                  placeholder=""
                  type="datetime-local"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </ValidationProvider>

          <v-row no-gutters>
            <v-col class="d-flex justify-end mb-1" cols="12" sm="7" md="7" lg="7" xl="7">
              <v-checkbox class="mt-1" v-model="clearFlg" label="既読をクリアする" hide-details="auto" />
            </v-col>
            <v-col class="d-flex justify-end mb-1" cols="12" sm="5" md="5" lg="5" xl="5">
              <v-checkbox class="mt-1" v-model="commentFlg" label="コメント設定" :disabled="!canCommentFlg" hide-details="auto" />
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-2 mt-2" v-bind="attrs" v-on="on" color="orange">mdi-help-circle-outline</v-icon>
                </template>
                <div>学年・学級・任意グループへの連絡は</div>
                <div>コメントをやり取りする設定が可能です。</div>
                <div>※対象ユーザー全員がコメントの登録・参照を出来ます。</div>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pt-0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    ファイル名
                  </th>
                  <th class="text-right">
                    削除
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="viewfileList.length == 0">
                  <td class="text-left">添付ファイル無し</td>
                  <td class="text-right"></td>
                </tr>
                <tr v-for="item in viewfileList" :key="item.attachmentId">
                  <td class="text-left"><a @click="downloadItem(item)" class="text-decoration-none">{{ item.fileName }}</a></td>
                  <td class="text-right">
                    <v-row class="justify-end pb-4">
                      <v-checkbox
                        v-model="delFileList"
                        :value="item.attachmentId"
                        :id="item.attachmentId"
                        color="primary"
                        hide-details="auto"
                      />
                  </v-row>
                  </td>     
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <dashboard :uppy="uppy" :props="{
              theme: 'light',
              inline: true,
              width: 3000,
              height: 350,
              disableThumbnailGenerator:true,
              showRemoveButtonAfterComplete:true,
              proudlyDisplayPoweredByUppy:false,
            }" />
            ※添付ファイルは(容量：50MB、個数：10)が上限となっております
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey" class="mr-auto mt-0 black--text" @click="canselContactEdit"  width="45%">戻る</v-btn>
          <v-btn color="accent" class="ml-auto mt-0 black--text" @click="saveContactEdit" :disabled="invalid || (!tounendoFlg)" width="45%">更新</v-btn>
        </v-card-actions>  
      </v-card>
    </ValidationObserver>
    <!-- 下書き選択ダイアログ -->
    <v-dialog
      v-model="viewDraftSelectDialog"
      max-width="864"
    >
      <DraftSelect
        draftType="0"
        v-bind:openTime="openTime"
        @closeDialog="closeDialog"
        @setDraft="setDraft"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { Dashboard } from '@uppy/vue'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import ja_JP from '../../plugins/puppy_lang'
import FirebaseCloudStorage from "../../plugins/FirebaseCloudStorage";
import { getStorage } from "firebase/storage";
import { saveAsCommon } from '../../common';

import * as Validator from 'validatorjs';
Validator.useLang('ja');
import DraftSelect from '../common/DraftSelect.vue'

import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  props: ['id'],
  // ファイルアップロード実装 START-------------------------
  components: {
    Dashboard,
    DraftSelect
  },
  computed: {
    uppy() {
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var uppy = new Uppy()
      .use(FirebaseCloudStorage, { storage, storagePath });
      return uppy;
    },
  },
  mounted() {
    this.uppy.on('upload', () => {
      this.$root.loading = true;
    });
    this.uppy.on('upload-success', (file) => {
      // 配列にファイルを追加
      this.addFileList.add(file.meta.refId);
    });
    this.uppy.on('complete', () => {
      this.$root.loading = false;
    });
    this.uppy.on('file-removed', (file) => {
      // 配列からファイルを削除
      this.addFileList.delete(file.meta.refId);
    });
    // 50MB制限+10ファイル制限
    this.uppy.setOptions({
      debug: true,
      locale: ja_JP,
      autoProceed: true,
      restrictions: {
        maxFileSize: (50 * 1024 * 1024),
        maxNumberOfFiles: 10,
      },
    });
  },
  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },
  // ファイルアップロード実装 END-------------------------
  data() {
    return {
      // 対象グループ
      groupId: "",
      groupName: "",

      // 対象選択フラグ
      isTargetSelect: false,
      // コメント可能グループフラグ
      canCommentFlg: false,

      // 下書き選択ダイアログ
      viewDraftSelectDialog: false,
      openTime: 0,

      // 対象ユーザー区分
      selectedUserKbn: [],
      selectedUserKbnOld: new Set(),

      // 対象選択ユーザーID
      studentIds: [],
      parentIds: [],
      teacherIds: [],

      title: "",
      body: "",
      openDate: "" ,
      closeDate : "",

      // 添付ファイルリスト
      viewfileList: [],
      // 添付ファイル追加選択
      addFileList: new Set(),
      // 添付ファイル削除選択
      delFileList: [],

      clearFlg: false,
      commentFlg: false,
      tounendoFlg: false,
      lockOpenDateFlg: false,
    };
  },
  async created() {
    // ローディング開始
    this.$root.loading = true;

    const data = {
      funcName: "loadContactEdit",
      contactId: this.id
    };

    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
    await schoolAidHomeLogined(data).then((res) => {
      this.groupId = res.data.groupId;
      this.groupName = res.data.groupName;
      this.selectedUserKbn = res.data.selectedUserKbn;
      // ユーザー区分削除抑制用
      this.selectedUserKbnOld = new Set(res.data.selectedUserKbn);
      // 対象選択時
      this.isTargetSelect = res.data.targetLimitedFlg;
      // コメント制御
      this.canCommentFlg = res.data.canCommentFlg;

      this.title = res.data.title;
      this.body = res.data.body;
      this.openDate = res.data.openDate;
      this.closeDate = res.data.closeDate;
      this.viewfileList = res.data.fileList;
      this.studentIds = res.data.studentIds;
      this.parentIds = res.data.parentIds;
      this.teacherIds = res.data.teacherIds;
      this.commentFlg = res.data.commentFlg;
      this.tounendoFlg = res.data.tounendoFlg;
      this.lockOpenDateFlg = res.data.lockOpenDateFlg;
      
      // ローディング終了
      this.$root.loading = false;
    }).catch((e) => {
      console.log(e.message);
      this.$root.loading = false;
    });
  },
  methods: {
    // 連絡情報編集
    async saveContactEdit() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "saveContactEdit",
        contactId: this.id,
        selectedUserKbn: this.selectedUserKbn, 
        title: this.title,
        body: this.body,
        openDate: this.openDate,
        closeDate: this.closeDate,
        delFileList : this.delFileList,
        addFileList : Array.from(this.addFileList),
        commentFlg: this.commentFlg,
        clearFlg: this.clearFlg,
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        if (res.data == "success"){
          alert("更新しました。");
        }  
        else {
          alert("データ更新に失敗しました。");
        }
      });
      this.$router.push({
        name: "ContactList",
      });
    },
    canselContactEdit(){
      this.$router.push({
        name: "ContactList",
      });
    },
    async downloadItem(item){
      saveAsCommon(item.url, item.fileName);
    },
    // 連絡コピー
    onClickCopyContact() {
      if(this.tounendoFlg == true){
        // 当年度の連絡の場合は対象選択状況もコピー
        this.$router.push({
          name: "ContactRegist",
          params: {
            groupId: this.groupId,
            groupName: this.groupName,
            paramUserKbns:this.selectedUserKbn,
            paramTitle:this.title,
            paramBody:this.body,
            paramOpenDate:this.openDate,
            paramCloseDate:this.closeDate,
            paramCommentFlg:this.commentFlg,
            paramStudentIds:this.studentIds,
            paramParentIds:this.parentIds,
            paramTeacherIds:this.teacherIds,
          }
        });
      } else {
        // 過年度の連絡の場合は対象選択状況はクリア
        this.$router.push({
          name: "ContactRegist",
          params: {
            groupId: this.groupId,
            groupName: this.groupName,
            paramUserKbns:this.selectedUserKbn,
            paramTitle:this.title,
            paramBody:this.body,
            paramOpenDate:this.openDate,
            paramCloseDate:this.closeDate,
            paramCommentFlg:this.commentFlg,
            paramStudentIds:[],
            paramParentIds:[],
            paramTeacherIds:[],
          }
        });
      }
    },
    // ダイアログを閉じる
    closeDialog(){
      this.viewDraftSelectDialog = false;
    },
    // 下書き選択ダイアログを表示
    async openDraftSelect(){
      this.openTime = new Date().getTime();
      this.viewDraftSelectDialog = true;
    },
    // 下書き選択ダイアログから反映
    setDraft(data){
      this.title = data.title;
      this.body = data.body;
    },
    // 下書き保存
    async addDraft(){
      // 入力値チェック
      let rules = {
        連絡タイトル: 'max:50',
        連絡本文:'max:3000',
      }
      let data = {
        連絡タイトル: this.title,
        連絡本文: this.body,
      }
      let validation = new Validator(data, rules);
      if(validation.fails()){
        var hash = validation.errors.all();
        var errors = [];
        for (let key in hash) {
          errors.push(...hash[key]);
        }
        alert(errors.join("\r\n"));
        return;
      }
      // 両方未入力チェック
      if(!this.title && !this.body){
        alert("タイトルと本文が未入力です");
        return;
      }

      var param = {
        funcName: "addDraft",
        draftType: "0",
        title: this.title,
        body: this.body,
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data == "success") {
          alert("下書きに登録しました。");
        }
      });
    },
  },
};
</script>
