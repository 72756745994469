<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="3" sm="3" md="3" lg="3" xl="3">
        <div align="left">
          <v-btn color="grey" small class="black--text" @click="toBack" :disabled="lockBack">前の連絡へ</v-btn>
        </div>
      </v-col>
      <v-col cols="1" sm="1" md="1" lg="1" xl="1">

      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4" justify="center">
        <v-btn color="green" small class="white--text" @click="changeFavoriteContacts" v-if="this.favoriteFlg">お気に入り解除<v-icon color="orange">mdi-star</v-icon></v-btn>
        <v-btn color="green" small class="white--text" @click="changeFavoriteContacts" v-if="!this.favoriteFlg">お気に入り追加<v-icon>mdi-star-outline</v-icon></v-btn>
      </v-col>
      <v-col cols="1" sm="1" md="1" lg="1" xl="1">

      </v-col>
      <v-col cols="3" sm="3" md="3" lg="3" xl="3">
        <div align="right">
          <v-btn color="grey" small class="black--text" @click="toForward" :disabled="lockForward">次の連絡へ</v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- 連絡ブロック -->
    <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 mt-2 mb-2">{{ title }}</v-card-title>
        <v-card-text class="d-flex justify-left pa-0 pl-4 mt-0">投稿者：{{ createUser }}</v-card-text>
        <v-card-text class="d-flex justify-center flex-column">
          <p style="white-space:pre-wrap;" v-html="autoLink(body)"></p>
        </v-card-text>   
        <v-card
          class="d-flex justify-center flex-column mx-auto my-0 flat"
          width="1200"
          color="#fff"
        >
        <v-list>
          <v-subheader>添付ファイル</v-subheader>
          <v-list-item-group  v-if="fileList.length" color="primary">
            <v-list-item v-for="item in fileList" @click="onClickDwonload(item)" :key="item.attachmentId"  >
              <a class="text-decoration-none">{{ item.fileName }}</a>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group  v-if="!fileList.length" color="primary">
            <v-list-item>ファイルはありません</v-list-item>
          </v-list-item-group>
        </v-list>

      </v-card>   
    </v-card>

    <!-- コメントブロック -->
    <v-card
      v-if="this.commentFlg"
      class="d-flex justify-center flex-column mx-auto my-6 flat"
      width="1200"
      color="#fff"
    >
      <v-card-title class="d-flex justify-center pa-0 mt-2 mb-2">コメント</v-card-title>
      <v-card
          v-if="!commentList.length"
          class="d-flex justify-center flex-column mx-auto my-0 flat"
          width="1200"
          color="#fff"
        >
        <v-card-text class="d-flex justify-center flex-column">
          <label color="primary">メッセージはありません</label>
        </v-card-text>
      </v-card>
      <v-card v-if="commentList.length">
        <div v-for="item in commentList" :key="item.commentId">
          <v-divider></v-divider>
          <v-row dense class="lime lighten-4">
            <v-col cols="10">
              <div class="text-caption">
                <div>{{ item.userNm }}が</div>
                <span>{{ item.postDate }}に更新</span>
              </div>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom v-if="item.fileList.length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="red" icon small v-bind="attrs" v-on="on" @click="openFileDownloadDialog(item)"><v-icon>mdi-paperclip</v-icon></v-btn>
                </template>
                <span>添付ファイルをダウンロードします</span>
              </v-tooltip>
            </v-col>
            <v-col cols="1">
              <v-card-actions class="justify-center pl-0 pr-0" v-if="item.editFlg">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red" icon outlined x-small v-bind="attrs" v-on="on" @click="onClickDeleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
                  </template>
                  <span>メッセージを削除する</span>
                </v-tooltip>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row no-gutters><v-divider></v-divider></v-row>
          <v-row dense>
            <v-card-text class="d-flex justify-center flex-column pt-0">
              <v-row dense>
                <v-col cols="12">
                  <p style="white-space:pre-wrap;" v-html="autoLink(item.comment)"></p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-row>
        </div>
      </v-card>
      <v-divider></v-divider>
      <v-card
        class="d-flex justify-center flex-column mx-auto pa-2 flat"
        width="1200"
        color="#fff"
      >
        <ValidationObserver ref="obs" v-slot="{ invalid }">
          <ValidationProvider name="コメント本文" rules="max:3000" v-slot="{ errors }">
            <v-row dense justify="end">
              <v-col cols="12" class="d-flex text-right">
                <v-btn color="accent" class="ml-auto mt-0 black--text" @click="sendComment" :disabled="invalid">送信</v-btn>
              </v-col>
            </v-row>
            <v-row dense class="pt-0 mt-0">
              <v-col cols="12" class="pb-0">
                <v-textarea
                  v-model="commentBody"
                  label="コメント本文"
                  placeholder=""
                  outlined
                  dense
                  :error-messages="errors[0]"
                  :counter="3000"
                  class="text-body-2 pt-0 mb-0"
                  hide-details="auto"
                >
                  <template v-slot:prepend>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon outlined x-small v-bind="attrs" v-on="on" @click="openModal" class="ml-auto"><v-icon color="black">mdi-paperclip</v-icon></v-btn>
                      </template>
                      <span>ファイルを添付する</span>
                    </v-tooltip>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
            <v-row dense justify="end">
              <v-col cols="12" class="d-flex text-right">
                <div class="text-caption">
                  <div v-if="uploadFileList.size == 0">添付ファイルなし</div>
                  <div v-if="uploadFileList.size > 0"><p class="font-weight-bold red--text">添付ファイルあり</p></div>
                </div>
              </v-col>
              <dashboard-modal
                :uppy="uppy"
                :open="viewModal"
                :props="{
                  theme: 'light',
                  disableThumbnailGenerator:true,
                  showRemoveButtonAfterComplete:true,
                  proudlyDisplayPoweredByUppy:false,
                  onRequestCloseModal: closeModal
                }" />
            </v-row>
            <v-row dense justify="end">
              <v-col cols="12" class="d-flex text-right">
                <div class="text-caption">
                  ※添付ファイルは(容量：50MB、個数：10)が上限となっております
                </div>
              </v-col>
            </v-row>
          </ValidationProvider>
        </ValidationObserver>
      </v-card>
    </v-card>

    <div id="return-to-bottom"></div>

    <v-tooltip top v-if="this.commentFlg">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom left color="secondary" @click="loadCommentArea" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <v-tooltip top v-if="this.commentFlg">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom right color="grey" style="bottom: 50px" v-scroll-to="{ element: '#return-to-bottom', duration: 1000 }">
          <v-icon color="white">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <span>ページの最後を表示する</span>
    </v-tooltip>
    
    <!-- ファイルダウンロードモーダル -->
    <v-dialog
      v-model="fileDownloadDialog"
      max-width="800"
    >
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="800"
          color="#fff"
        >
          <v-card-subtitle class="d-flex justify-center pa-0 mt-6" white-space="pre">
            <p>ファイルダウンロード</p>
          </v-card-subtitle>
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item v-for="item in dialogFileList" @click="onClickDwonload(item)" :key="item.attachmentId"  >
                <a class="text-decoration-none">{{ item.fileName }}</a>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { DashboardModal } from '@uppy/vue'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import ja_JP from '../../plugins/puppy_lang'
import FirebaseCloudStorage from "../../plugins/FirebaseCloudStorage";
import { getStorage } from "firebase/storage";
import Autolinker from 'autolinker';

import { saveAsCommon } from '../../common';
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['messageId','contactListBuff','contactListIndex'],
  // ファイルアップロード実装 START-------------------------
  components: {
    DashboardModal
  },
  computed: {
    uppy() {
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var uppy = new Uppy()
      .use(FirebaseCloudStorage, { storage, storagePath });
      return uppy;
    },
  },
  mounted() {
    this.uppy.on('upload', () => {
      this.$root.loading = true;
    });
    this.uppy.on('upload-success', (file) => {
      this.uploadFileList.add(file.meta.refId);
    });
    this.uppy.on('complete', () => {
      this.$root.loading = false;
    });
    this.uppy.on('file-removed', (file) => {
      console.log("file-removed");
      this.uploadFileList.delete(file.meta.refId);
    });
    // 50MB制限+10ファイル制限
    this.uppy.setOptions({
      debug: true,
      locale: ja_JP,
      autoProceed: true,
      restrictions: {
        maxFileSize: (50 * 1024 * 1024),
        maxNumberOfFiles: 10,
      },
    });
  },
  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },
  // ファイルアップロード実装 END-------------------------
  data() {
    return {
      title : "",
      body : "",
      contactId : "",
      groupId : "",
      createUser : "",
      fileList: [],
      commentFlg: false,
      commentList: [],
      commentBody: "",
      uploadFileList: new Set(),

      lockBack: false,
      lockForward: false,
      favoriteFlg: false,

      viewModal: false,
      fileDownloadDialog: false,
      dialogFileList: [],
    };
  },
  watch: {
    messageId: async function () {
      await this.load();
    }
  },
  // 初期表示時データ読み込み
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      this.lockBack = (this.contactListIndex == 0)?true:false;
      this.lockForward = (this.contactListBuff.length == (this.contactListIndex+1))?true:false;
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var data = {
        funcName: "loadContactDetails",
        messageId : this.messageId
      }
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((doc) => {
        this.title = doc.data.title;
        this.body = doc.data.body;
        this.contactId = doc.data.contactId;
        this.groupId = doc.data.groupId;
        this.createUser = doc.data.createUser;
        this.fileList = doc.data.fileList;
        this.commentFlg = doc.data.commentFlg;
        this.favoriteFlg = doc.data.favoriteFlg;
      });

      // コメント情報取得
      if (this.commentFlg) {
        await this.loadCommentArea();
        this.commentBody = "";
        this.uppy.cancelAll();
      }

      // ローディング非表示
      this.$root.loading = false;
    },
    // 前の連絡へボタン
    toBack() {
      this.$router.push({
        name: "ContactDetails",
        params: {
          messageId: this.contactListBuff[this.contactListIndex - 1],
          contactListBuff: this.contactListBuff,
          contactListIndex: this.contactListIndex - 1,
        } 
      });
    },
    // 次の連絡へボタン
    toForward() {
      this.$router.push({
        name: "ContactDetails",
        params: {
          messageId: this.contactListBuff[this.contactListIndex + 1],
          contactListBuff: this.contactListBuff,
          contactListIndex: this.contactListIndex + 1,
        } 
      });
    },
    // お気に入り切り替えボタン
    async changeFavoriteContacts()  {      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "changeFavoriteContacts",
        contactId: this.contactId,
        favoriteFlg: this.favoriteFlg
      };
      await schoolAidHomeLogined(data);
      this.favoriteFlg = !this.favoriteFlg;
    },
    async loadCommentArea() {
      this.commentList = [];
      console.log("this.contactId：", this.contactId);
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var data = {
        funcName: "loadCommentArea",
        contactId : this.contactId
      }
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((doc) => {
        this.commentList = doc.data ? doc.data : [];
      });
    },

    async onClickDwonload(item) {
      saveAsCommon(item.url, item.fileName);
    },

    // コメント送信
    async sendComment() {
      if (!this.commentBody) {
        alert("コメント本文が入力されていません。");
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";

      var param = {
        funcName: "sendComment",
        messageId: this.messageId,
        comment: this.commentBody,
        fileList : Array.from(this.uploadFileList),
      };

      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      await this.loadCommentArea();
      this.commentBody = "";
      this.uppy.cancelAll();
    },

    // 論理削除
    async onClickDeleteItem(item) {
      var result = confirm("削除してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "deleteComment",
        messageId: this.messageId,
        commentId: item.commentId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      await this.loadCommentArea();
    },

    // ファイルダウンロードダイアログを開く
    openFileDownloadDialog(item) {
      this.dialogFileList = item.fileList;
      this.fileDownloadDialog = true;
    },

    openModal() { this.viewModal = true },
    closeModal() { this.viewModal = false },
    autoLink(text) {
      const autolinker = new Autolinker();
      return autolinker.link(text);
    },
  },  
};
</script>