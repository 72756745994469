<template>
  <v-container fluid>
    <v-card
      class="d-flex justify-center flex-column mx-auto my-6 flat"
      width="1200"
      color="#fff"
    >
      <v-card-title class="d-flex justify-left">学習のまとめ</v-card-title>
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <!-- 記入欄 -->
        <ValidationProvider name="教科・科目の学習（目標）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record01Goal"
              label="教科・科目の学習（目標）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <ValidationProvider name="教科・科目の学習（やったこと・考えたこと）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record01Evidence"
              label="教科・科目の学習（やったこと・考えたこと）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>

        <ValidationProvider name="探究学習（目標）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record02Goal"
              label="探究学習（目標）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <ValidationProvider name="探究学習（やったこと・考えたこと）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record02Evidence"
              label="探究学習（やったこと・考えたこと）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>

        <ValidationProvider name="特徴・特技（目標）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record03Goal"
              label="特徴・特技（目標）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <ValidationProvider name="特徴・特技（やったこと・考えたこと）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record03Evidence"
              label="特徴・特技（やったこと・考えたこと）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>

        <ValidationProvider name="部活動（目標）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record04Goal"
              label="部活動（目標）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <ValidationProvider name="部活動（やったこと・考えたこと）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record04Evidence"
              label="部活動（やったこと・考えたこと）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>

        <ValidationProvider name="ボランティア活動（目標）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record05Goal"
              label="ボランティア活動（目標）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <ValidationProvider name="ボランティア活動（やったこと・考えたこと）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record05Evidence"
              label="ボランティア活動（やったこと・考えたこと）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>

        <ValidationProvider name="留学・海外経験（目標）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record06Goal"
              label="留学・海外経験（目標）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <ValidationProvider name="留学・海外経験（やったこと・考えたこと）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record06Evidence"
              label="留学・海外経験（やったこと・考えたこと）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>

        <ValidationProvider name="資格・検定（目標）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record07Goal"
              label="資格・検定（目標）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <ValidationProvider name="資格・検定（やったこと・考えたこと）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record07Evidence"
              label="資格・検定（やったこと・考えたこと）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>

        <ValidationProvider name="表彰・顕彰（目標）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record08Goal"
              label="表彰・顕彰（目標）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <ValidationProvider name="表彰・顕彰（やったこと・考えたこと）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record08Evidence"
              label="表彰・顕彰（やったこと・考えたこと）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>

        <ValidationProvider name="クラス委員（目標）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record09Goal"
              label="クラス委員（目標）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <ValidationProvider name="クラス委員（やったこと・考えたこと）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record09Evidence"
              label="クラス委員（やったこと・考えたこと）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>

        <ValidationProvider name="生徒会関連の委員（目標）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record10Goal"
              label="生徒会関連の委員（目標）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <ValidationProvider name="生徒会関連の委員（やったこと・考えたこと）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record10Evidence"
              label="生徒会関連の委員（やったこと・考えたこと）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>

        <ValidationProvider name="行事関連の委員（目標）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record11Goal"
              label="行事関連の委員（目標）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <ValidationProvider name="行事関連の委員（やったこと・考えたこと）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record11Evidence"
              label="行事関連の委員（やったこと・考えたこと）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>

        <ValidationProvider name="学びの見通し（目標）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record12Goal"
              label="学びの見通し（目標）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <ValidationProvider name="学びの見通し（やったこと・考えたこと）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record12Evidence"
              label="学びの見通し（やったこと・考えたこと）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>

        <ValidationProvider name="キャリアの見通し（目標）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record13Goal"
              label="キャリアの見通し（目標）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <ValidationProvider name="キャリアの見通し（やったこと・考えたこと）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record13Evidence"
              label="キャリアの見通し（やったこと・考えたこと）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>

        <ValidationProvider name="その他（目標）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record14Goal"
              label="その他（目標）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <ValidationProvider name="その他（やったこと・考えたこと）" rules="max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="record14Evidence"
              label="その他（やったこと・考えたこと）"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <!-- 添付ファイル -->
        <v-card-text justify="center">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    ファイル名
                  </th>
                  <th class="text-right">
                    削除
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in viewfileList"
                  :key="item.attachmentId"
                >
                  <td class="text-left"><a @click="onClickDwonload(item)" class="text-decoration-none">{{ item.fileName }}</a></td>
                  <td class="text-right">
                    <v-row class="justify-end">
                      <v-checkbox
                        v-model="selectData"
                        :value="item.attachmentId"
                        :id="item.attachmentId"
                        color="primary"
                        hide-details="auto"
                      />
                  </v-row>
                  </td>     
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <dashboard :uppy="uppy" :props="{
              theme: 'light',
              inline: true,
              width: 3000,
              height: 350,
              disableThumbnailGenerator:true,
              showRemoveButtonAfterComplete:true,
              proudlyDisplayPoweredByUppy:false,
            }" />
            ※添付ファイルは(容量：50MB、個数：10)が上限となっております
        </v-card-text>
        <!-- 更新ボタン -->
        <v-card-actions>
          <v-row class="justify-center">
            <v-col cols="6" justify="center">
              <v-btn color="accent" class="ml-auto mt-0 black--text" @click="poSavePoStudyStoryRegist(false)" :disabled="invalid" width="90%">保存(未提出)</v-btn>
            </v-col>
            <v-col cols="6" justify="center">
              <v-btn color="accent" class="ml-auto mt-0 black--text" @click="poSavePoStudyStoryRegist(true)" :disabled="invalid" width="90%">提出</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
    <!-- コメントブロック -->
    <v-card
      class="d-flex justify-center flex-column mx-auto my-6 flat"
      width="1200"
      color="#fff"
      v-if="this.existsFlg"
    >
      <v-card v-if="!commentList.length">
        <v-card-text class="d-flex justify-center flex-column">
          <label color="primary">コメントはありません</label>
        </v-card-text>
      </v-card>
      <v-card v-if="commentList.length">
        <div v-for="item in commentList" :key="item.commentId">
          <v-divider></v-divider>
          <v-row dense class="lime lighten-4">
            <v-col cols="11">
              <div class="text-caption">
                <div>{{ item.userNm }}が</div>
                <span>{{ item.postDate }}に更新</span>
              </div>
            </v-col>
            <v-col cols="1">
              <v-card-actions class="justify-right pl-0 pr-0">
                <v-tooltip bottom v-if="item.editFlg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red" icon outlined x-small v-bind="attrs" v-on="on" @click="onClickDeleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
                  </template>
                  <span>コメントを削除する</span>
                </v-tooltip>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row no-gutters><v-divider></v-divider></v-row>
          <v-row dense>
            <v-card-text class="d-flex justify-center flex-column pt-0">
              <v-row dense>
                <v-col cols="12">
                  <p style="white-space:pre-wrap;" v-html="autoLink(item.comment)"></p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-row>
        </div>
      </v-card>
      <v-divider></v-divider>
      <v-card>
        <ValidationObserver ref="obs2" v-slot="{ invalid2 }">
          <ValidationProvider name="コメント本文" rules="max:3000" v-slot="{ errors }">
            <v-row dense justify="end">
              <v-col cols="12" class="d-flex text-right">
                <v-btn color="accent" class="ml-auto mt-0 black--text" @click="poSavePoStudyStoryRegistComment" :disabled="invalid2">送信</v-btn>
              </v-col>
            </v-row>
            <v-row dense class="pt-0 mt-0">
              <v-col cols="12" class="pb-0">
                <v-textarea
                  v-model="commentBody"
                  label="コメント本文"
                  placeholder=""
                  outlined
                  dense
                  :error-messages="errors[0]"
                  :counter="3000"
                  class="text-body-2 pt-0 mb-0"
                  hide-details="auto"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </ValidationProvider>
        </ValidationObserver>
      </v-card>
    </v-card>
    <div id="return-to-bottom"></div>

    <v-tooltip top v-if="this.existsFlg">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom left color="secondary" @click="loadCommentArea" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom right color="grey" style="bottom: 50px" v-scroll-to="{ element: '#return-to-bottom', duration: 1000 }">
          <v-icon color="white">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <span>ページの最後を表示する</span>
    </v-tooltip>

  </v-container>
</template>

<script>
import { Dashboard } from '@uppy/vue'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import ja_JP from '../../../plugins/puppy_lang'
import FirebaseCloudStorage from "../../../plugins/FirebaseCloudStorage";
import { getStorage } from "firebase/storage";
import Autolinker from 'autolinker';

import { saveAsCommon } from '../../../common';
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['storyCd'],
  // ファイルアップロード実装 START-------------------------
  components: {
    Dashboard
  },
  computed: {
    uppy() {
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var uppy = new Uppy()
      .use(FirebaseCloudStorage, { storage, storagePath });
      return uppy;
    },
  },
  mounted() {
    this.uppy.on('upload', () => {
      this.$root.loading = true;
    });
    this.uppy.on('upload-success', (file) => {
      // 配列にファイルを追加
      this.fileList.add(file.meta.refId);
    });
    this.uppy.on('complete', () => {
      this.$root.loading = false;
    });
    this.uppy.on('file-removed', (file) => {
      // 配列からファイルを削除
      this.fileList.delete(file.meta.refId);
    });
    // 50MB制限+10ファイル制限
    this.uppy.setOptions({
      debug: true,
      locale: ja_JP,
      autoProceed: true,
      restrictions: {
        maxFileSize: (50 * 1024 * 1024),
        maxNumberOfFiles: 10,
      },
    });
  },
  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },
  // ファイルアップロード実装 END-------------------------
  data() {
    return {
      // 記入欄
      record01Goal : "",
      record01Evidence : "",
      record02Goal : "",
      record02Evidence : "",
      record03Goal : "",
      record03Evidence : "",
      record04Goal : "",
      record04Evidence : "",
      record05Goal : "",
      record05Evidence : "",
      record06Goal : "",
      record06Evidence : "",
      record07Goal : "",
      record07Evidence : "",
      record08Goal : "",
      record08Evidence : "",
      record09Goal : "",
      record09Evidence : "",
      record10Goal : "",
      record10Evidence : "",
      record11Goal : "",
      record11Evidence : "",
      record12Goal : "",
      record12Evidence : "",
      record13Goal : "",
      record13Evidence : "",
      record14Goal : "",
      record14Evidence : "",

      existsFlg: false,

      viewfileList: [],
      fileList: new Set(),
      selectData: [],

      commentList: [],
      commentBody: "",
      uploadFileList: new Set(),
    };
  },
  // 初期表示時データ読み込み
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var data = {
        funcName: "poLoadPoStudyStoryRegist",
        storyCd : this.storyCd
      }
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((doc) => {
        this.record01Goal     = doc.data.record01Goal    ;
        this.record01Evidence = doc.data.record01Evidence;
        this.record02Goal     = doc.data.record02Goal    ;
        this.record02Evidence = doc.data.record02Evidence;
        this.record03Goal     = doc.data.record03Goal    ;
        this.record03Evidence = doc.data.record03Evidence;
        this.record04Goal     = doc.data.record04Goal    ;
        this.record04Evidence = doc.data.record04Evidence;
        this.record05Goal     = doc.data.record05Goal    ;
        this.record05Evidence = doc.data.record05Evidence;
        this.record06Goal     = doc.data.record06Goal    ;
        this.record06Evidence = doc.data.record06Evidence;
        this.record07Goal     = doc.data.record07Goal    ;
        this.record07Evidence = doc.data.record07Evidence;
        this.record08Goal     = doc.data.record08Goal    ;
        this.record08Evidence = doc.data.record08Evidence;
        this.record09Goal     = doc.data.record09Goal    ;
        this.record09Evidence = doc.data.record09Evidence;
        this.record10Goal     = doc.data.record10Goal    ;
        this.record10Evidence = doc.data.record10Evidence;
        this.record11Goal     = doc.data.record11Goal    ;
        this.record11Evidence = doc.data.record11Evidence;
        this.record12Goal     = doc.data.record12Goal    ;
        this.record12Evidence = doc.data.record12Evidence;
        this.record13Goal     = doc.data.record13Goal    ;
        this.record13Evidence = doc.data.record13Evidence;
        this.record14Goal     = doc.data.record14Goal    ;
        this.record14Evidence = doc.data.record14Evidence;
        this.viewfileList = doc.data.fileList;
        // 登録済フラグ
        this.existsFlg = doc.data.existsFlg;
      });

      // コメント情報取得
      if(this.existsFlg) {
        await this.loadCommentArea();
        this.commentBody = "";
      }

      // ローディング非表示
      this.$root.loading = false;
    },
    // 学習のまとめ登録
    async poSavePoStudyStoryRegist(submissionFlg) {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "poSavePoStudyStoryRegist",
        storyCd: this.storyCd,
        record01Goal     : this.record01Goal    ,
        record01Evidence : this.record01Evidence,
        record02Goal     : this.record02Goal    ,
        record02Evidence : this.record02Evidence,
        record03Goal     : this.record03Goal    ,
        record03Evidence : this.record03Evidence,
        record04Goal     : this.record04Goal    ,
        record04Evidence : this.record04Evidence,
        record05Goal     : this.record05Goal    ,
        record05Evidence : this.record05Evidence,
        record06Goal     : this.record06Goal    ,
        record06Evidence : this.record06Evidence,
        record07Goal     : this.record07Goal    ,
        record07Evidence : this.record07Evidence,
        record08Goal     : this.record08Goal    ,
        record08Evidence : this.record08Evidence,
        record09Goal     : this.record09Goal    ,
        record09Evidence : this.record09Evidence,
        record10Goal     : this.record10Goal    ,
        record10Evidence : this.record10Evidence,
        record11Goal     : this.record11Goal    ,
        record11Evidence : this.record11Evidence,
        record12Goal     : this.record12Goal    ,
        record12Evidence : this.record12Evidence,
        record13Goal     : this.record13Goal    ,
        record13Evidence : this.record13Evidence,
        record14Goal     : this.record14Goal    ,
        record14Evidence : this.record14Evidence,

        submissionFlg: submissionFlg,
        delFileList : this.selectData,
        fileList : Array.from(this.fileList),
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        if (res.data == "success"){
          alert("更新しました。");
        }  
        else {
          alert("データ更新に失敗しました。");
        }
      });
      this.$router.push({
        name: "PoStudyHistory",
      });
    },
    // ファイルダウンロード
    async onClickDwonload(item) {
      saveAsCommon(item.url, item.fileName);
    },
    // コメントの取得
    async loadCommentArea() {
      this.commentList = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var data = {
        funcName: "poLoadPoStudyStoryRegistComments",
        storyCd : this.storyCd
      }
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((doc) => {
        this.commentList = doc.data ? doc.data : [];
      });
    },
    // コメントの保管
    async poSavePoStudyStoryRegistComment() {
      if (!this.commentBody) {
        alert("コメント本文が入力されていません。");
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";

      var param = {
        funcName: "poSavePoStudyStoryRegistComment",
        storyCd: this.storyCd,
        comment: this.commentBody,
      };

      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      await this.loadCommentArea();
      this.commentBody = "";
      this.$refs.obs2.reset()
    },
    // 
    async onClickDeleteItem(item) {
      var result = confirm("削除してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "poDeletePoStudyStoryRegistComment",
        storyCd: this.storyCd,
        commentId: item.commentId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      await this.loadCommentArea();
    },
    autoLink(text) {
      const autolinker = new Autolinker();
      return autolinker.link(text);
    },
  },  
};
</script>