import { saveAs } from 'file-saver';

const saveAsCommon = async (url, filename) => {
    if(filename && filename.endsWith(".pdf")){
        window.open(url, '_blank')
    } else {
        saveAs(url, filename);
    }
}

export { saveAsCommon }