import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    viewConditionTabSelect: "contact",
    // 出欠確認(ソート順)
    sortCondition_AttendanceConfirm: "1",
    // 早退確認(ソート順)
    sortCondition_LeavingConfirm: "1",
    
    searchConditionSyukketuGroup: [],
    searchConditionGroupName: "",
    searchConditionCreateUser: "",
    searchConditionUnread: false,
    searchConditionFavorite: false,
    parPageTeacherTopPageContact: 30,
    parPageTeacherTopPageSurvey: 30,
    parPageTeacherTopPageTalkRoom: 30,
    parPageStudentTopPageContact: 30,
    parPageStudentTopPageSurvey: 30,
    parPageStudentTopPageHomework: 30,
    parPageGuardianTopPageContact: 30,
    parPageGuardianTopPageSurvey: 30,
    parPageGroupSelect: 30,
    parPageGroupList: 30,
    parPageUserList: 30,
    parPageContactList: 30,
    parPageSurveyList: 30,
    selectBarCodeSyukketuKbn: "chikoku",
  },
  mutations: {
    setViewConditionTabSelect: function(state, value) {state.viewConditionTabSelect = value},
    // 出欠確認(ソート順)
    setSortCondition_AttendanceConfirm: function(state, value) {state.sortCondition_AttendanceConfirm = value},
    // 早退確認(ソート順)
    setSortCondition_LeavingConfirm: function(state, value) {state.sortCondition_LeavingConfirm = value},

    setSearchConditionSyukketuGroup: function(state, value) {state.searchConditionSyukketuGroup = value},
    setSearchConditionGroupName: function(state, value) {state.searchConditionGroupName = value},
    setSearchConditionCreateUser: function(state, value) {state.searchConditionCreateUser = value},
    setSearchConditionUnread: function(state, value) {state.searchConditionUnread = value},
    setSearchConditionFavorite: function(state, value) {state.searchConditionFavorite = value},
    setParPageTeacherTopPageContact: function(state, value) {state.parPageTeacherTopPageContact = value},
    setParPageTeacherTopPageSurvey: function(state, value) {state.parPageTeacherTopPageSurvey = value},
    setParPageTeacherTopPageTalkRoom: function(state, value) {state.parPageTeacherTopPageTalkRoom = value},
    setParPageStudentTopPageContact: function(state, value) {state.parPageStudentTopPageContact = value},
    setParPageStudentTopPageSurvey: function(state, value) {state.parPageStudentTopPageSurvey = value},
    setParPageStudentTopPageHomework: function(state, value) {state.parPageStudentTopPageHomework = value},
    setParPageGuardianTopPageContact: function(state, value) {state.parPageGuardianTopPageContact = value},
    setParPageGuardianTopPageSurvey: function(state, value) {state.parPageGuardianTopPageSurvey = value},
    setParPageGroupSelect: function(state, value) {state.parPageGroupSelect = value},
    setParPageGroupList: function(state, value) {state.parPageGroupList = value},
    setParPageUserList: function(state, value) {state.parPageUserList = value},
    setParPageContactList: function(state, value) {state.parPageContactList = value},
    setParPageSurveyList: function(state, value) {state.parPageSurveyList = value},
    setSelectBarCodeSyukketuKbn: function(state, value) {state.selectBarCodeSyukketuKbn = value},
  },
  actions: {
    setViewConditionTabSelect: function(context, value) {context.commit('setViewConditionTabSelect', value)},
    // 出欠確認(ソート順)
    setSortCondition_AttendanceConfirm: function(context, value) {context.commit('setSortCondition_AttendanceConfirm', value)},
    // 早退確認(ソート順)
    setSortCondition_LeavingConfirm: function(context, value) {context.commit('setSortCondition_LeavingConfirm', value)},

    setSearchConditionSyukketuGroup: function(context, value) {context.commit('setSearchConditionSyukketuGroup', value)},
    setSearchConditionGroupName: function(context, value) {context.commit('setSearchConditionGroupName', value)},
    setSearchConditionCreateUser: function(context, value) {context.commit('setSearchConditionCreateUser', value)},
    setSearchConditionUnread: function(context, value) {context.commit('setSearchConditionUnread', value)},
    setSearchConditionFavorite: function(context, value) {context.commit('setSearchConditionFavorite', value)},
    setParPageTeacherTopPageContact: function(context, value) {context.commit('setParPageTeacherTopPageContact', value)},
    setParPageTeacherTopPageSurvey: function(context, value) {context.commit('setParPageTeacherTopPageSurvey', value)},
    setParPageTeacherTopPageTalkRoom: function(context, value) {context.commit('setParPageTeacherTopPageTalkRoom', value)},
    setParPageStudentTopPageContact: function(context, value) {context.commit('setParPageStudentTopPageContact', value)},
    setParPageStudentTopPageSurvey: function(context, value) {context.commit('setParPageStudentTopPageSurvey', value)},
    setParPageStudentTopPageHomework: function(context, value) {context.commit('setParPageStudentTopPageHomework', value)},
    setParPageGuardianTopPageContact: function(context, value) {context.commit('setParPageGuardianTopPageContact', value)},
    setParPageGuardianTopPageSurvey: function(context, value) {context.commit('setParPageGuardianTopPageSurvey', value)},
    setParPageGroupSelect: function(context, value) {context.commit('setParPageGroupSelect', value)},
    setParPageGroupList: function(context, value) {context.commit('setParPageGroupList', value)},
    setParPageUserList: function(context, value) {context.commit('setParPageUserList', value)},
    setParPageContactList: function(context, value) {context.commit('setParPageContactList', value)},
    setParPageSurveyList: function(context, value) {context.commit('setParPageSurveyList', value)},
    setSelectBarCodeSyukketuKbn: function(context, value) {context.commit('setSelectBarCodeSyukketuKbn', value)},
  },
  plugins: [createPersistedState({})] 
});
