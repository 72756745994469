<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          活動記録タイムライン(所属学年学級分)
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="yellow" dark v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <span>直近7日以内に更新 または コメントが追加されたものが表示されます</span>
          </v-tooltip>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="activityRecordList"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          :loading="isListLoding" loading-text="読込中"
          dense
        >
          <!-- 更新日時/提出状況 -->
          <template #[`item.submissionFlg`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.updateDate }}</v-col></v-row>
            <v-row dense v-if="item.submissionFlg"><v-col cols="12" justify="center" style="white-space : nowrap;" class="red--text">{{ item.submissionStates }}</v-col></v-row>
            <v-row dense v-if="!item.submissionFlg"><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.submissionStates }}</v-col></v-row>
          </template>

          <!-- 生徒情報 -->
          <template #[`item.userNm`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.kateiNm }}/{{ item.buNm }}/{{ item.kaNm }}/{{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.userNm }}({{ item.userNmKana }})</v-col></v-row>
          </template>

          <!-- コメントユーザー/コメント日時 -->
          <template #[`item.lastPostDate`]="{ item }">
            <v-row dense v-if="!item.lastPostSelfFlg"><v-col cols="12" justify="center" style="white-space : nowrap;" class="red--text">{{ item.lastPostUserNm }}</v-col></v-row>
            <v-row dense v-if="item.lastPostSelfFlg"><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.lastPostUserNm }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.lastPostDate }}</v-col></v-row>
          </template>

          <!-- 課題登録日時/課題グループ -->
          <template #[`item.postDate`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.postDate }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.groupName }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.createUserNm }}</v-col></v-row>
          </template>

          <!-- 課題タイトル/期限/課題カテゴリ -->
          <template #[`item.disp1`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.titleHW }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.limitDate }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.categoryNmHW }}</v-col></v-row>
          </template>

          <!-- 課題詳細ボタン -->
          <template #[`item.action1`]="{ item }">
            <v-btn 
              color="accent"
              class="black--text"
              @click="openDetailHWDialog(item)"
              v-if="item.repFlg == false"
              >
              課題詳細
            </v-btn>
          </template>

          <!-- 活動記録詳細ボタン -->
          <template #[`item.action2`]="{ item }">
            <v-btn 
              color="accent"
              class="black--text"
              @click="openDetailDialog(item)"
              >
              活動記録詳細
            </v-btn>
          </template>
      </v-data-table>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- 課題詳細モーダル -->
    <v-dialog
      v-model="detailHWDialog"
      scrollable
      fullscreen
      persistent
    >
      <v-card>
        <v-card-text class="text-pre-wrap">
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-toolbar
                dark
                color="primary"
              >
                <v-toolbar-title>課題詳細</v-toolbar-title>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    タイトル
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  {{ dialogTitleHW }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    提出期限
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  {{ dialogLimitDateHW }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    本文・設問
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogBodyHW)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="!dialogUseCategoryFlg" dense>
            <v-col cols="12" justify="center">          
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    カテゴリ
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  {{ dialogCategoryNmHW }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="d-flex ml-2 pb-0 pt-0">
              <v-list dense>
                <v-subheader>添付ファイル</v-subheader>
                <v-list-item-group  v-if="dialogFileListHW.length" color="primary">
                  <v-list-item v-for="item in dialogFileListHW" :key="item.attachmentId">
                    <a @click="onClickDwonload(item)" class="text-decoration-none">{{ item.fileName }}</a>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group  v-if="!dialogFileListHW.length" color="primary">
                  <v-list-item>ファイルはありません</v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-btn fixed fab bottom left color="grey" @click="detailHWDialog = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
    <!-- 活動記録詳細モーダル -->
    <v-dialog
      v-model="detailDialog"
      scrollable
      fullscreen
      persistent
    >
      <v-card>
        <v-card-text class="text-pre-wrap">
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-toolbar dark color="primary">
                <v-toolbar-title>活動記録</v-toolbar-title>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row v-if="dialogEditNameFlg" dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    タイトル
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  {{ dialogTitle }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    本文・設問
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogBody)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="dialogUseCategoryFlg" dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    カテゴリ
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  {{ dialogCategoryNm }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="d-flex ml-2 pb-0 pt-0">
              <v-list dense>
                <v-subheader>添付ファイル</v-subheader>
                <v-list-item-group  v-if="dialogFileList.length" color="primary">
                  <v-list-item v-for="item in dialogFileList" :key="item.attachmentId">
                    <a @click="onClickDwonload(item)" class="text-decoration-none">{{ item.fileName }}</a>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group  v-if="!dialogFileList.length" color="primary">
                  <v-list-item>ファイルはありません</v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
          <!-- レビューコメント -->
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-toolbar dark color="primary">
                <v-toolbar-title>コメント</v-toolbar-title>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card v-if="!dialogReviewList.length">
                <v-card-text class="d-flex justify-center flex-column">
                  <label color="primary">コメントはありません</label>
                </v-card-text>
              </v-card>
              <v-card v-if="dialogReviewList.length">
                <div v-for="item in dialogReviewList" :key="item.commentId">
                  <v-divider></v-divider>
                  <v-row dense class="lime lighten-4">
                    <v-col cols="11">
                      <div class="text-caption">
                        <div>{{ item.userNm }}が</div>
                        <span>{{ item.postDate }}に更新</span>
                      </div>
                    </v-col>
                    <v-col cols="1">
                      <v-card-actions class="justify-right pl-0 pr-0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="red" icon outlined x-small v-bind="attrs" v-on="on" @click="deleteReviewComment(item)"><v-icon>mdi-delete</v-icon></v-btn>
                          </template>
                          <span>コメントを削除する</span>
                        </v-tooltip>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                  <v-row no-gutters><v-divider></v-divider></v-row>
                  <v-row dense>
                    <v-card-text class="d-flex justify-center flex-column pt-0">
                      <v-row dense>
                        <v-col cols="12">
                          <p style="white-space:pre-wrap;" v-html="autoLink(item.comment)"></p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card>
            <ValidationObserver ref="obs" v-slot="{ invalid }">
              <ValidationProvider name="コメント本文" rules="max:3000" v-slot="{ errors }">
                <v-row dense justify="end">
                  <v-col cols="12" class="d-flex text-right">
                    <v-btn color="accent" class="ml-auto mt-0 black--text" @click="saveReviewComments" :disabled="invalid">送信</v-btn>
                  </v-col>
                </v-row>
                <v-row dense class="pt-0 mt-0">
                  <v-col cols="12" class="pb-0">
                    <v-textarea
                      v-model="dialogComment"
                      ref="commentArea"
                      label="コメント本文"
                      placeholder=""
                      outlined
                      dense
                      :error-messages="errors[0]"
                      :counter="3000"
                      class="text-body-2 pt-0 mb-0"
                      hide-details="auto"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </ValidationProvider>
            </ValidationObserver>
          </v-card>
          <v-row style="height: 50px;">
            <v-col cols="12" justify="center">
            </v-col>
          </v-row>
        </v-card-text>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fixed fab left v-bind="attrs" v-on="on" color="grey" @click="closeDetailDialog" style="bottom: -10px">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>閉じる</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fixed fab right v-bind="attrs" v-on="on" color="grey" @click="scrollToBottomDialog" style="bottom: -10px">
              <v-icon color="white">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <span>ページの最後を表示する</span>
        </v-tooltip>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { saveAsCommon } from '../../../common';
import Autolinker from 'autolinker';
export default {
  // 初期処理宣言
  beforeRouteEnter(to, from, next){next(vm => {vm.setup()})},
  data() {
    return {
      headers: [
        { text: "更新日時/提出状況", value: "submissionFlg", width: "200" },
        { text: "生徒情報", value: "userNm", width: "80" },
        { text: "課題名/提出期限", value: "disp1"},
        { text: "最終コメント", value: "lastPostDate", width: "200" },
        { text: "課題登録日時/対象グループ名/作成者", value: "postDate"},
        { text: "課題詳細", value: "action1", width: "80" },
        { text: "活動記録詳細", value: "action2", width: "80" },
      ],
      activityRecordList: [],
      isListLoding: false,

      // 課題詳細ダイアログ表示用
      detailHWDialog: false,
      dialogHomeworkId: "",
      dialogTitleHW: "",
      dialogLimitDateHW: "",
      dialogBodyHW: "",
      dialogCategoryNmHW: "",
      dialogUseCategoryFlg: false,
      dialogFileListHW: [],

      // 活動記録詳細ダイアログ表示用
      detailDialog: false,
      dialogStudentId: "",
      dialogReportId: "",
      dialogEditNameFlg: false,
      dialogTitle: "",
      dialogBody: "",
      dialogCategoryNm: "",
      dialogFileList: [],
      dialogReviewList: [],
      dialogComment: "",
      isReviewListLoding: false,
    };
  },
  methods: {
    async setup() {
      await this.reload();
    },
    // リロードボタン
    async reload() {
      this.activityRecordList = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;

      var param = {
        funcName: "poLoadActivityRecordTimeline",
      }
      await schoolAidHomeLogined(param).then((ret) => {

        // 活動記録一覧
        var buff = [];
        ret.data.forEach((recode) => {

          var row = {
            // 課題情報
            updateDate: recode.updateDate,
            homeworkId: recode.homeworkId,
            
            postDate: recode.postDate,
            createUserNm: recode.createUserNm,
            groupName: recode.groupName,
            titleHW: recode.titleHW,
            limitDate: recode.limitDate,
            bodyHW: recode.bodyHW,
            categoryNmHW: recode.categoryNmHW,
            useCategoryFlg: recode.useCategoryFlg,
            editNameFlg: recode.editNameFlg,
            repFlg: recode.repFlg,

            // 活動記録
            reportId: recode.reportId,
            // 生徒情報
            studentId: recode.studentId,
            kateiNm: recode.kateiNm,
            buNm: recode.buNm,
            kaNm: recode.kaNm,
            gakunen: recode.gakunen,
            gakkyu: recode.gakkyu,
            syussekiNo: recode.syussekiNo,
            userNm: recode.userNm,
            userNmKana: recode.userNmKana,

            title: recode.title,
            body: recode.body,
            categoryNm: recode.categoryNm,
            // 提出状況
            submissionFlg: recode.submissionFlg,
            submissionDate: recode.submissionDate,
            submissionStates: "",
            // 最終コメント
            lastPostDate: recode.lastPostDate,
            lastPostUserNm: recode.lastPostUserNm,
            lastPostSelfFlg: recode.lastPostSelfFlg,
          };

          // 提出状況
          if(recode.submissionFlg){
            row.submissionStates = "提出済";
          } else {
            row.submissionStates = "未提出";
          }
          
          buff.push(row);
        });

        this.activityRecordList = buff;
        this.isListLoding = false;
      });
    },
    // 課題詳細モーダルを開く
    async openDetailHWDialog(item){
      this.dialogHomeworkId = item.homeworkId;
      this.dialogTitleHW = item.titleHW;
      this.dialogLimitDateHW = item.limitDate;
      this.dialogBodyHW = item.bodyHW;
      this.dialogCategoryNmHW = item.categoryNmHW;
      this.dialogUseCategoryFlg = item.useCategoryFlg;
      await this.loadFilelistHW();
      this.detailHWDialog = true;
    },
    // 課題のファイルリストを取得
    async loadFilelistHW() {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isReviewListLoding = true;

      var param = {
        funcName: "poLoadFilelistHW",
        homeworkId: this.dialogHomeworkId,
      }
      await schoolAidHomeLogined(param).then((ret) => {        
        this.dialogFileListHW = ret.data.fileList;
      });
    },
    // 活動記録モーダルを開く
    async openDetailDialog(item) {
      this.dialogStudentId = item.studentId;
      this.dialogReportId = item.reportId;
      this.dialogEditNameFlg = item.editNameFlg;
      this.dialogTitle = item.title;
      this.dialogBody = item.body;
      this.dialogCategoryNm = item.categoryNm;
      this.dialogUseCategoryFlg = item.useCategoryFlg;
      await this.loadFilelistAndReviews();
      this.detailDialog = true;
    },

    // 活動記録のファイルリストとコメントを取得
    async loadFilelistAndReviews() {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isReviewListLoding = true;

      var param = {
        funcName: "poLoadARFilelistAndReviews",
        uid: this.dialogStudentId,
        reportId: this.dialogReportId,
      }
      await schoolAidHomeLogined(param).then((ret) => {        
        this.dialogFileList = ret.data.fileList;
        this.dialogReviewList = ret.data.reviewList;
      });
    },
    // レビューコメント登録
    async saveReviewComments() {
      if (!this.dialogComment) {
        alert("コメント本文が入力されていません。");
        return;
      }

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isReviewListLoding = true;

      var param = {
        funcName: "poSaveReviewComment",
        uid: this.dialogStudentId,
        reportId: this.dialogReportId,
        comment: this.dialogComment,
      }
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data === "success"){
          this.dialogComment = "";
        }
      });
      // コメントを再読み込み
      await this.loadFilelistAndReviews();
      this.reload();
    },
    // コメント論理削除
    async deleteReviewComment(item) {
      var result = confirm("削除してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "poDeleteReviewComment",
        uid: this.dialogStudentId,
        reportId: this.dialogReportId,
        commentId: item.commentId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      await this.loadFilelistAndReviews();
      this.reload();
    },
    // 活動記録モーダルを閉じる
    async closeDetailDialog() {

      this.detailDialog = false;
    },
    // 添付ファイルダウンロード
    async onClickDwonload(item) {
      saveAsCommon(item.url, item.fileName);
    },
    // 下部スクロール
    scrollToBottomDialog() {
      this.$refs.commentArea.focus();
    },
    autoLink(text) {
      const autolinker = new Autolinker();
      return autolinker.link(text);
    },
  },
};
</script>