<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          所属者一覧({{ groupName }})
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <!-- 検索用のテキスト入力 -->
        <v-text-field
          v-model="search"
          label="氏名ｶﾅ検索"
          placeholder="氏名ｶﾅ検索"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
      </v-col>
    </v-row>
    <v-row dense>
      <v-tabs class="mx-2 mt-2"
        v-model="tabSelect"
        fixed-tabs
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows>
        <v-tab :disabled="!viewTab1" href="#students">
          生徒
        </v-tab>
        <v-tab :disabled="!viewTab2" href="#parents">
          保護者
        </v-tab>
        <v-tab :disabled="!viewTab3" href="#teachers">
          教職員
        </v-tab>

        <v-tab-item value="students">
          <v-col cols="12">
            <v-data-table
              :headers="studentHed"
              :items="students"
              :items-per-page="-1"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              :loading="isListLoding"
              dense
              :search="search"
              :custom-filter="filter"
              loading-text="読込中">
            </v-data-table>
          </v-col>
        </v-tab-item>
        <v-tab-item value="parents">
          <v-col cols="12">
            <v-data-table
              :headers="parentsHed"
              :items="parents"
              :items-per-page="-1"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              :loading="isListLoding"
              dense
              :search="search"
              :custom-filter="filter"
              loading-text="読込中">
              <template #[`item.students`]="{ item }">
                <v-row dense v-for="student in item.studentsInfo" :key="student.loginId" >
                  <v-col cols="12" justify="center">
                    {{ student.userNm }}/{{ student.kateiNm }}/{{ student.buNm }}/{{ student.kaNm }}/{{ student.gakunen }}/{{ student.gakkyu }}/{{ student.syussekiNo }}
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-tab-item>
        <v-tab-item value="teachers">
          <v-col cols="12">
            <v-data-table
              :headers="teacherHed"
              :items="teachers"
              :items-per-page="-1"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              :loading="isListLoding"
              dense
              :search="search"
              :custom-filter="filter"
              loading-text="読込中">
            </v-data-table>
          </v-col>
        </v-tab-item>
      </v-tabs>
    </v-row>
    <v-row class="justify-start" dense>
      <v-col>
        <v-btn @click="onClicktoBack" color="grey" class="black--text" width="352">戻る</v-btn>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['groupId','groupName','beforeName'],
  data() {
    return {
      search: "",
      selectedUserKbn: [],
      userKbns: [
        { code: "0", name: "生徒" },
        { code: "1", name: "保護者" },
        { code: "8", name: "教員" },
      ],
      studentHed: [
        { text: "氏名", value: "userNm", align: "start"},
        { text: "氏名(ｶﾅ)", value: "userNmKana", align: "start"},
        { text: "課程", value: "kateiNm" },
        { text: "部", value: "buNm" },
        { text: "科", value: "kaNm" },
        { text: "学年", value: "gakunen" },
        { text: "学級", value: "gakkyu" },
        { text: "出席番号", value: "syussekiNo" },
      ],
      students: [],
      parentsHed: [
        { text: "生徒(氏名/課程/部/科/学年/学級/出席番号)", value: "students" },
        { text: "保護者氏名", value: "userNm", align: "start"},
        { text: "保護者氏名(ｶﾅ)", value: "userNmKana", align: "start"},
      ],
      parents: [],
      teacherHed: [
        { text: "氏名", value: "userNm", align: "start"},
        { text: "氏名(ｶﾅ)", value: "userNmKana", align: "start"},
      ],
      teachers: [],
      isListLoding: false,
      viewTab1 : false,
      viewTab2 : false,
      viewTab3 : false,
      tabSelect : "",
    };
  },
  // 初期表示時データ読み込み
  async created() {
    this.reload();
  },
  methods: {
    // 氏名カナ検索
    filter(value, search, item) {
      if(!item.userNmKana)return false;
      return item.userNmKana.toString().includes(search)
    },
    async reload(){
      this.viewTab1 = true;
      this.viewTab2 = true;
      this.viewTab3 = true;
      this.students = [];
      this.parents = [];
      this.teachers = [];
      this.tabSelect = "";

      const data = {
        funcName: "loadGroupMemberList",
        groupId: this.groupId,
      };
      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((ret) => {
        console.log(ret);
        this.viewTab1 = false;
        this.viewTab2 = false;
        this.viewTab3 = false;
        this.students = ret.data.students;
        this.parents = ret.data.parents;
        this.teachers = ret.data.teachers;
        if(this.teachers.length > 0){
          this.viewTab3 = true;
          this.tabSelect = "teachers";
        }
        if(this.parents.length > 0){
          this.viewTab2 = true;
          this.tabSelect = "parents";
        }
        if(this.students.length > 0){
          this.viewTab1 = true;
          this.tabSelect = "students";
        }
        this.isListLoding = false;
      });
    },
    // 戻るボタン
    onClicktoBack(){
      this.$router.push({
        name: this.beforeName
      });
    },
  },
};
</script>