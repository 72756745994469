import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";
import ja from 'vuetify/es5/locale/ja'
import StudentIcon from '../components/Icon/StudentIcon.vue'
import ParentIcon from '../components/Icon/ParentIcon.vue'
import TeacherIcon from '../components/Icon/TeacherIcon.vue'
import CetRequiredIcon from '../components/Icon/CetRequiredIcon.vue'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#4caf50",
        secondary: "#5cbbf6",
        accent: "#FF9800",
        error: "#ff3b3b",
        warning: "#ffc107",
        info: "#ff5722",
        success: "#795548",
      },
    },
  },
  icons: {
    iconfont: "mdi", // 追加
    values: {
      student: {component: StudentIcon},
      parent: {component: ParentIcon},
      teacher: {component: TeacherIcon},
      cet_required: {component: CetRequiredIcon},
    }
  },
  lang: {
    locales: { ja },
    current: 'ja'
  },
});
