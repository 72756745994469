<template>
  <v-container fluid>
    <!-- タイトルブロック -->
    <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 mt-2 mb-2">個別連絡</v-card-title>
        <v-card-text class="d-flex justify-left pa-0 pl-4 mt-0" v-if="!this.inhouseFlg">
          宛先：{{ studentNm }}<div v-if="targetParentFlg">の保護者様</div>
        </v-card-text>
        <v-card-text v-if="assignUserNms.length" class="d-flex justify-left pa-0 pl-4 mt-0">
          教員：{{ assignUser }}
        </v-card-text>  
    </v-card>
    <!-- メッセージブロック -->
    <v-card
      class="d-flex justify-center flex-column mx-auto my-6 flat"
      width="1200"
      color="#fff"
    >
      <v-card-title class="d-flex justify-center pa-0 mt-2 mb-2">メッセージ</v-card-title>
      <v-card
          v-if="!messageList.length"
          class="d-flex justify-center flex-column mx-auto my-0 flat"
          width="1200"
          color="#fff"
        >
        <v-card-text class="d-flex justify-center flex-column">
          <label color="primary">メッセージはありません</label>
        </v-card-text>
      </v-card>
      <v-card v-if="messageList.length">
        <div v-for="item in messageList" :key="item.messageId">
          <v-divider></v-divider>
          <v-row dense class="lime lighten-4">
            <v-col cols="8">
              <div class="text-caption">
                <div>{{ item.userNm }}が</div>
                <span>{{ item.postDate }}に更新</span>
                <span v-if="item.newFlg" class="text-caption font-weight-bold red--text">
                  NEW
                </span>
              </div>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom v-if="item.fileList.length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="red" icon small v-bind="attrs" v-on="on" @click="openFileDownloadDialog(item)"><v-icon>mdi-paperclip</v-icon></v-btn>
                </template>
                <span>添付ファイルをダウンロードします</span>
              </v-tooltip>
            </v-col>
            <v-col cols="2" style="white-space : nowrap;">
              <div v-if="item.readUserNms" class="text-caption font-weight-bold text-decoration-underline">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">既読状況</span>
                  </template>
                  <span>{{ item.readUserNms }}</span>
                </v-tooltip>
              </div>
            </v-col>
            <v-col cols="1">
              <v-card-actions class="justify-center pl-0 pr-0" v-if="item.editFlg && lockFlg == false">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red" icon outlined x-small v-bind="attrs" v-on="on" @click="onClickDeleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
                  </template>
                  <span>メッセージを削除する</span>
                </v-tooltip>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row no-gutters><v-divider></v-divider></v-row>
          <v-row dense>
            <v-card-text class="d-flex justify-center flex-column pt-0">
              <v-row dense>
                <v-col cols="12">
                  <p style="white-space:pre-wrap;" v-html="autoLink(item.message)"></p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-row>
        </div>
      </v-card>
      <v-divider></v-divider>
      <v-card
        class="d-flex justify-center flex-column mx-auto pa-2 flat"
        width="1200"
        color="#fff"
        v-if="lockFlg == false"
      >
        <ValidationObserver ref="obs" v-slot="{ invalid }">
          <ValidationProvider name="メッセージ本文" rules="max:3000" v-slot="{ errors }">
            <v-row dense justify="end">
              <v-col cols="12" class="d-flex text-right">
                <v-btn color="accent" class="ml-auto mt-0 black--text" @click="sendTalkRoomMessage" :disabled="invalid">送信</v-btn>
              </v-col>
            </v-row>
            <v-row dense class="pt-0 mt-0">
              <v-col cols="12" class="pb-0">
                <v-textarea
                  v-model="messageBody"
                  label="メッセージ本文"
                  placeholder=""
                  outlined
                  dense
                  :error-messages="errors[0]"
                  :counter="3000"
                  class="text-body-2 pt-0 mb-0"
                  hide-details="auto"
                >
                  <template v-slot:prepend>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon outlined x-small v-bind="attrs" v-on="on" @click="openModal" class="ml-auto"><v-icon color="black">mdi-paperclip</v-icon></v-btn>
                      </template>
                      <span>ファイルを添付する</span>
                    </v-tooltip>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
            <v-row dense justify="end">
              <v-col cols="12" class="d-flex text-right">
                <div class="text-caption">
                  <div v-if="uploadFileList.size == 0">添付ファイルなし</div>
                  <div v-if="uploadFileList.size > 0"><p class="font-weight-bold red--text">添付ファイルあり</p></div>
                </div>
              </v-col>
              <dashboard-modal
                :uppy="uppy"
                :open="viewModal"
                :props="{
                  theme: 'light',
                  disableThumbnailGenerator:true,
                  showRemoveButtonAfterComplete:true,
                  proudlyDisplayPoweredByUppy:false,
                  onRequestCloseModal: closeModal
                }" />
            </v-row>
            <v-row dense justify="end">
              <v-col cols="12" class="d-flex text-right">
                <div class="text-caption">
                  ※添付ファイルは(容量：50MB、個数：10)が上限となっております
                </div>
              </v-col>
            </v-row>
          </ValidationProvider>
        </ValidationObserver>
      </v-card>
    </v-card>

    <div id="return-to-bottom"></div>
    
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom left color="secondary" @click="loadTalkRoomDetails" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom right color="grey" style="bottom: 50px" v-scroll-to="{ element: '#return-to-bottom', duration: 1000 }">
          <v-icon color="white">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <span>ページの最後を表示する</span>
    </v-tooltip>

    <!-- ファイルダウンロードモーダル -->
    <v-dialog
      v-model="fileDownloadDialog"
      max-width="800"
    >
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="800"
          color="#fff"
        >
          <v-card-subtitle class="d-flex justify-center pa-0 mt-6" white-space="pre">
            <p>ファイルダウンロード</p>
          </v-card-subtitle>
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item v-for="item in dialogFileList" @click="onClickDwonload(item)" :key="item.attachmentId"  >
                <a class="text-decoration-none">{{ item.fileName }}</a>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { DashboardModal } from '@uppy/vue'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import ja_JP from '../../plugins/puppy_lang'
import FirebaseCloudStorage from "../../plugins/FirebaseCloudStorage";
import { getStorage } from "firebase/storage";
import Autolinker from 'autolinker';
import { scroller } from 'vue-scrollto/src/scrollTo'

import { saveAsCommon } from '../../common';
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['roomId'],
  // ファイルアップロード実装 START-------------------------
  components: {
    DashboardModal
  },
  computed: {
    uppy() {
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var uppy = new Uppy()
      .use(FirebaseCloudStorage, { storage, storagePath });
      return uppy;
    },
  },
  mounted() {
    this.uppy.on('upload', () => {
      this.$root.loading = true;
    });
    this.uppy.on('upload-success', (file) => {
      this.uploadFileList.add(file.meta.refId);
    });
    this.uppy.on('complete', () => {
      this.$root.loading = false;
    });
    this.uppy.on('file-removed', (file) => {
      console.log("file-removed");
      this.uploadFileList.delete(file.meta.refId);
    });
    // 50MB制限+10ファイル制限
    this.uppy.setOptions({
      debug: true,
      locale: ja_JP,
      autoProceed: true,
      restrictions: {
        maxFileSize: (50 * 1024 * 1024),
        maxNumberOfFiles: 10,
      },
    });
  },
  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },
  // ファイルアップロード実装 END-------------------------
  data() {
    return {
      studentNm: "",
      targetParentFlg: false,
      inhouseFlg: false,
      lockFlg: false,
      assignUserNms : [],
      assignUser: "",
      messageList: [],
      messageBody: "",
      uploadFileList: new Set(),
      viewModal: false,
      fileDownloadDialog: false,
      dialogFileList: [],
    };
  },
  watch: {
    messageId: async function () {
      await this.load();
    }
  },
  // 初期表示時データ読み込み
  async created() {
    await this.load();
  },
  methods: {
    // ヘッダ情報の取得
    async load() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var data = {
        funcName: "loadTalkRoom",
        roomId : this.roomId
      }
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((doc) => {
        this.studentNm = doc.data.studentNm;
        this.targetParentFlg = doc.data.targetParentFlg;
        this.inhouseFlg = doc.data.inhouseFlg;
        this.lockFlg = doc.data.lockFlg;
        this.assignUserNms = doc.data.assignUserNms;
        this.assignUser = this.assignUserNms.join(",");
      });

      // メッセージ情報取得
      await this.loadTalkRoomDetails();
      this.messageBody = "";
      this.uppy.cancelAll();

      // ローディング非表示
      this.$root.loading = false;
    },
    // メッセージ明細の取得
    async loadTalkRoomDetails() {
      this.messageList = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var data = {
        funcName: "loadTalkRoomDetails",
        roomId : this.roomId
      }
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((doc) => {
        this.messageList = doc.data ? doc.data : [];
      });
      const scrollTo = scroller()
      scrollTo("#return-to-bottom");
    },

    // メッセージの追加
    async sendTalkRoomMessage() {
      if (!this.messageBody) {
        alert("メッセージ本文が入力されていません。");
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";

      var param = {
        funcName: "sendTalkRoomMessage",
        roomId: this.roomId,
        message: this.messageBody,
        fileList : Array.from(this.uploadFileList),
      };

      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((doc) => {
        if(doc.data){
          alert(doc.data);
        }
      });
      // ローディング終了
      this.$root.loading = false;
      await this.loadTalkRoomDetails();
      this.messageBody = "";
      this.uppy.cancelAll();
    },
    // メッセージの論理削除
    async onClickDeleteItem(item) {
      var result = confirm("削除してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "deleteTalkRoomMessage",
        roomId: this.roomId,
        messageId: item.messageId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((doc) => {
        if(doc.data){
          alert(doc.data);
        } else {
          alert("端末にてメッセージが削除されました。以降に読み込んだユーザー側では表示されません。");
        }
      });
      // ローディング終了
      this.$root.loading = false;
      await this.loadTalkRoomDetails();
    },

    // メッセージの添付ファイルダウンロード
    async onClickDwonload(item) {
      saveAsCommon(item.url, item.fileName);
    },

    // ファイルダウンロードダイアログを開く
    openFileDownloadDialog(item) {
      this.dialogFileList = item.fileList;
      this.fileDownloadDialog = true;
    },

    // ファイルアップロード用モーダル
    openModal() { this.viewModal = true },
    closeModal() { this.viewModal = false },
    
    // メッセージ内のURLをリンク化
    autoLink(text) {
      const autolinker = new Autolinker();
      return autolinker.link(text);
    },
  },  
};
</script>