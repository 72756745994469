<template>
  <div class="text-center">
    <!-- 画像サムネイル表示 -->
    <v-card
      :height="imgHeight"
      :width="imgWidth"
    >
      <v-img
        :max-height="imgHeight"
        :max-width="imgWidth"
        contain
        style="cursor:zoom-in"
        @click="view"
        :src="imageUrl">

        <div class="d-flex justify-end">
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs" v-on="on"
                fab
                depressed
                x-small
                color="transparent"
                style="cursor:zoom-in"
                @click="view"
              >
                <v-icon>mdi-magnify-plus-outline</v-icon>
              </v-btn>
            </template>
            <span>拡大</span>
          </v-tooltip>
        </div>
      </v-img>
    </v-card>
    <v-dialog
      v-model="viewDialog"
      scrollable
    >
      <v-img
        :src="imageUrl"
        style="cursor:pointer"
        contain
        @click="close"
      >
        <div class="d-flex justify-end">
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs" v-on="on"
                color="green"
                class="mt-2 mr-2"
                fab
                dark
                small
                @click="onClickDwonload"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>ダウンロード</span>
          </v-tooltip>
        </div>
      </v-img>

    </v-dialog>
  </div>
</template>
  
<script lang="ts">
import { saveAsCommon } from '../../../common';
export default {
  // 親画面との値連動設定
  props: ["imageUrl", "height", "width"],
  // 当コンポーネントの変数
  data() {
    return {
      imgHeight: 150,
      imgWidth: 250,
      viewDialog: false,
    }
  },
  async created() {
    // 初期表示時
    if(this.height)this.imgHeight = this.height;
    if(this.width)this.imgWidth = this.width;
  },
  methods: {
    // 原寸画像ダイアログを表示
    view(){
      if(this.imageUrl) {
        this.viewDialog = true;
      }
    },
    // 原寸画像ダイアログを閉じる
    close(){
      this.viewDialog = false;
    },
    // 画像のダウンロード
    async onClickDwonload() {
      saveAsCommon(this.imageUrl, "参考画像");
    },
  }
}
</script>