<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          {{ titleHW }}の活動記録
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" sm="2" md="2" lg="2" xl="2">
        <v-btn color="grey" class="mx-auto black--text" width="90%" @click="toPoHomeworkList">戻る</v-btn>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4" justify="center">
      </v-col>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
        <v-switch 
          v-model="viewSubmission"
          dense
          class="mt-1"
          label="提出のみ"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="6" sm="2" md="2" lg="2" xl="2">
        <v-btn color="green" class="mx-auto black--text" :disabled="isExportingFlg" :loading="isExportingFlg" @click="exportExcelActivityRecordForHomework()" width="90%">ファイル出力<v-icon right dark>mdi-file-excel</v-icon></v-btn>
      </v-col>
      <v-col cols="6" sm="2" md="2" lg="2" xl="2">
        <v-btn color="accent" class="mx-auto black--text" width="90%" @click="openDetailHWDialog()">課題詳細</v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :headers="selectedHeaders"
          :items="activityRecordList"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          :loading="isListLoding" loading-text="読込中"
          dense
        >
          <template #[`item.userNm`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.userNm }}({{ item.userNmKana }})</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.kateiNm }}/{{ item.buNm }}/{{ item.kaNm }}/{{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}</v-col></v-row>
          </template>
          <template #[`item.disp`]="{ item }">
            <v-row dense v-if="editNameFlg"><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.title }}</v-col></v-row>
            <v-row dense v-if="useCategoryFlg"><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.categoryNm }}</v-col></v-row>
          </template>
          <template #[`item.submissionFlg`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.submissionStates }}</v-col></v-row>
          </template>
          <template #[`item.submissionDateYYYYMMDD`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.submissionDate }}</v-col></v-row>
          </template>
          <template #[`item.lastPostDateYYYYMMDD`]="{ item }">
            <v-row dense v-if="item.uid == item.lastPostId"><v-col cols="12" justify="center" style="white-space : nowrap;" class="red--text">生徒</v-col></v-row>
            <v-row dense v-if="item.uid != item.lastPostId"><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.lastPostUserNm }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.lastPostDate }}</v-col></v-row>
          </template>
          <!-- 活動記録詳細ボタン -->
          <template #[`item.action`]="{ item }">
            <v-btn 
              color="accent"
              class="black--text"
              @click="openDetailDialog(item)"
              >
              詳細
            </v-btn>
          </template>
      </v-data-table>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- 課題詳細モーダル -->
    <v-dialog
      v-model="detailHWDialog"
      scrollable
      persistent
    >
      <v-card>
        <v-card-text class="text-pre-wrap">
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-toolbar
                dark
                color="primary"
              >
                <v-btn
                  icon
                  dark
                  @click="detailHWDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>課題詳細</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    タイトル
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  {{ dialogHWTitle }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    提出期限
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  {{ dialogHWLimitDate }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    本文・設問
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogHWBody)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="!useCategoryFlg" dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    カテゴリ
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  {{ dialogHWCategoryNm }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="d-flex ml-2 pb-0 pt-0">
              <v-list dense>
                <v-subheader>添付ファイル</v-subheader>
                <v-list-item-group  v-if="dialogHWFileList.length" color="primary">
                  <v-list-item v-for="item in dialogHWFileList" :key="item.attachmentId">
                    <a @click="onClickDwonload(item)" class="text-decoration-none">{{ item.fileName }}</a>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group  v-if="!dialogHWFileList.length" color="primary">
                  <v-list-item>ファイルはありません</v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- 活動記録詳細モーダル -->
    <v-dialog
      v-model="detailDialog"
      scrollable
      fullscreen
      persistent
    >
      <v-card>
        <v-card-text class="text-pre-wrap">
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-toolbar dark color="primary">
                <v-toolbar-title>{{ dialogUserNm }}の活動記録</v-toolbar-title>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row v-if="editNameFlg" dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    タイトル
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  {{ dialogTitle }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    本文・設問
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogBody)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="useCategoryFlg" dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    カテゴリ
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  {{ dialogCategoryNm }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="d-flex ml-2 pb-0 pt-0">
              <v-list dense>
                <v-subheader>添付ファイル</v-subheader>
                <v-list-item-group  v-if="dialogFileList.length" color="primary">
                  <v-list-item v-for="item in dialogFileList" :key="item.attachmentId">
                    <a @click="onClickDwonload(item)" class="text-decoration-none">{{ item.fileName }}</a>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group  v-if="!dialogFileList.length" color="primary">
                  <v-list-item>ファイルはありません</v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
          <!-- レビューコメント -->
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-toolbar dark color="primary">
                <v-toolbar-title>コメント</v-toolbar-title>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card v-if="!dialogReviewList.length">
                <v-card-text class="d-flex justify-center flex-column">
                  <label color="primary">コメントはありません</label>
                </v-card-text>
              </v-card>
              <v-card v-if="dialogReviewList.length">
                <div v-for="item in dialogReviewList" :key="item.commentId">
                  <v-divider></v-divider>
                  <v-row dense class="lime lighten-4">
                    <v-col cols="11">
                      <div class="text-caption">
                        <div>{{ item.userNm }}が</div>
                        <span>{{ item.postDate }}に更新</span>
                      </div>
                    </v-col>
                    <v-col cols="1">
                      <v-card-actions class="justify-right pl-0 pr-0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="red" icon outlined x-small v-bind="attrs" v-on="on" @click="deleteReviewComment(item)"><v-icon>mdi-delete</v-icon></v-btn>
                          </template>
                          <span>コメントを削除する</span>
                        </v-tooltip>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                  <v-row no-gutters><v-divider></v-divider></v-row>
                  <v-row dense>
                    <v-card-text class="d-flex justify-center flex-column pt-0">
                      <v-row dense>
                        <v-col cols="12">
                          <p style="white-space:pre-wrap;" v-html="autoLink(item.comment)"></p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card>
            <ValidationObserver ref="obs" v-slot="{ invalid }">
              <ValidationProvider name="コメント本文" rules="max:3000" v-slot="{ errors }">
                <v-row dense justify="end">
                  <v-col cols="12" class="d-flex text-right">
                    <v-btn color="accent" class="ml-auto mt-0 black--text" @click="saveReviewComments" :disabled="invalid">送信</v-btn>
                  </v-col>
                </v-row>
                <v-row dense class="pt-0 mt-0">
                  <v-col cols="12" class="pb-0">
                    <v-textarea
                      v-model="dialogComment"
                      ref="commentArea"
                      label="コメント本文"
                      placeholder=""
                      outlined
                      dense
                      :error-messages="errors[0]"
                      :counter="3000"
                      class="text-body-2 pt-0 mb-0"
                      hide-details="auto"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </ValidationProvider>
            </ValidationObserver>
          </v-card>
          <v-row style="height: 50px;">
            <v-col cols="12" justify="center">
            </v-col>
          </v-row>
        </v-card-text>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fixed fab left v-bind="attrs" v-on="on" color="grey" @click="closeDetailDialog" style="bottom: -10px">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>閉じる</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fixed fab right v-bind="attrs" v-on="on" color="grey" @click="scrollToBottomDialog" style="bottom: -10px">
              <v-icon color="white">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <span>ページの最後を表示する</span>
        </v-tooltip>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { saveAsCommon } from '../../../common';
import Autolinker from 'autolinker';
export default {
  props: ['homeworkId', 'backPageName'],
  // 初期処理宣言
  beforeRouteEnter(to, from, next){next(vm => {vm.setup()})},
  data() {
    return {
      viewSubmission: false,
      titleHW: "",
      limitDate: "",
      bodyHW: "",
      categoryNmHW: "",
      fileListHW: [],
      groupId: "",
      groupName: "",
      editNameFlg: false,
      useCategoryFlg: false,
      selectedHeaders: [],
      headers0: [
        { text: "生徒氏名(ｶﾅ)", value: "userNm", align: "start"},
        { text: "活動記録名/カテゴリー", value: "disp"},
        { text: "提出状況", value: "submissionFlg", width: "80", filter: this.noSubmissionFilter },
        { text: "提出日時", value: "submissionDateYYYYMMDD", width: "200" },
        { text: "コメント", value: "lastPostDateYYYYMMDD", width: "200" },
        { text: "詳細", value: "action", width: "80" },
      ],
      headers1: [
        { text: "生徒氏名(ｶﾅ)", value: "userNm", align: "start"},
        { text: "活動記録名", value: "disp"},
        { text: "提出状況", value: "submissionFlg", width: "80", filter: this.noSubmissionFilter },
        { text: "提出日時", value: "submissionDateYYYYMMDD", width: "200" },
        { text: "コメント", value: "lastPostDateYYYYMMDD", width: "200" },
        { text: "詳細", value: "action", width: "80" },
      ],
      headers2: [
        { text: "生徒氏名(ｶﾅ)", value: "userNm", align: "start"},
        { text: "カテゴリー", value: "disp"},
        { text: "提出状況", value: "submissionFlg", width: "80", filter: this.noSubmissionFilter },
        { text: "提出日時", value: "submissionDateYYYYMMDD", width: "200" },
        { text: "コメント", value: "lastPostDateYYYYMMDD", width: "200" },
        { text: "詳細", value: "action", width: "80" },
      ],
      headers3: [
        { text: "生徒氏名(ｶﾅ)", value: "userNm", align: "start"},
        { text: "提出状況", value: "submissionFlg", width: "80", filter: this.noSubmissionFilter },
        { text: "提出日時", value: "submissionDateYYYYMMDD", width: "200" },
        { text: "コメント", value: "lastPostDateYYYYMMDD", width: "200" },
        { text: "詳細", value: "action", width: "80" },
      ],
      activityRecordList: [],
      isListLoding: false,
      isExportingFlg: false,
      // 課題詳細ダイアログ表示用
      detailHWDialog: false,
      dialogHWTitle: "",
      dialogHWLimitDate: "",
      dialogHWBody: "",
      dialogHWCategoryNm: this.categoryNmHW,
      dialogHWFileList: [],
      // 活動記録詳細ダイアログ表示用
      detailDialog: false,
      dialogUserId: "",
      dialogReportId: "",
      dialogUserNm: "",
      dialogTitle: "",
      dialogBody: "",
      dialogCategoryNm: "",
      dialogFileList: [],
      dialogReviewList: [],
      dialogComment: "",
      isReviewListLoding: false,
    };
  },
  methods: {
    async setup() {
      await this.reload();
    },
    // 提出フィルタ
    noSubmissionFilter(value) {
      if (!this.viewSubmission) {
        return true;
      }
      return value;
    },
    // 課題詳細モーダルを開く
    openDetailHWDialog(){
      this.dialogHWTitle = this.titleHW;
      this.dialogHWLimitDate = this.limitDate;
      this.dialogHWBody = this.bodyHW;
      this.dialogHWCategoryNm = this.categoryNmHW;
      this.dialogHWFileList = this.fileListHW;
      this.detailHWDialog = true;
    },

    // 活動記録モーダルを開く
    async openDetailDialog(item) {
      this.dialogUserId = item.uid;
      this.dialogReportId = item.reportId;
      this.dialogUserNm = item.userNm;
      this.dialogTitle = item.title;
      this.dialogBody = item.body;
      this.dialogCategoryNm = item.categoryNm;
      await this.loadFilelistAndReviews();
      this.detailDialog = true;
    },

    // 活動記録のファイルリストとコメントを取得
    async loadFilelistAndReviews() {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isReviewListLoding = true;

      var param = {
        funcName: "poLoadARFilelistAndReviews",
        uid: this.dialogUserId,
        reportId: this.dialogReportId,
      }
      await schoolAidHomeLogined(param).then((ret) => {        
        this.dialogFileList = ret.data.fileList;
        this.dialogReviewList = ret.data.reviewList;
      });
    },
    // 活動記録のレビューコメントを保存
    async saveReviewComments() {
      if (!this.dialogComment) {
        alert("コメント本文が入力されていません。");
        return;
      }

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isReviewListLoding = true;

      var param = {
        funcName: "poSaveReviewComment",
        uid: this.dialogUserId,
        reportId: this.dialogReportId,
        comment: this.dialogComment,
      }
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data === "success"){
          this.dialogComment = "";
        }
      });
      // コメントを再読み込み
      await this.loadFilelistAndReviews();
      this.reload();
    },
    // 論理削除
    async deleteReviewComment(item) {
      var result = confirm("削除してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "poDeleteReviewComment",
        uid: this.dialogUserId,
        reportId: this.dialogReportId,
        commentId: item.commentId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      await this.loadFilelistAndReviews();
      this.reload();
    },
    // 活動記録モーダルを閉じる
    async closeDetailDialog() {
      this.detailDialog = false;
    },
    // 添付ファイルダウンロード
    async onClickDwonload(item) {
      saveAsCommon(item.url, item.fileName);
    },
    // リロードボタン
    async reload() {
      this.activityRecordList = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;

      var param = {
        funcName: "poLoadActivityRecordListForHomework",
        homeworkId: this.homeworkId,
      }
      await schoolAidHomeLogined(param).then((ret) => {
        this.titleHW = ret.data.titleHW;
        this.limitDate = ret.data.limitDate;
        this.bodyHW = ret.data.bodyHW;
        this.categoryNmHW = ret.data.categoryNmHW;
        this.fileListHW = ret.data.fileListHW;
        
        this.groupId = ret.data.groupId;
        this.groupName = ret.data.groupName;
        this.editNameFlg = ret.data.editNameFlg;
        this.useCategoryFlg = ret.data.useCategoryFlg;
        // 活動記録一覧
        var buff = [];
        ret.data.arList.forEach((recode) => {

          var row = {
            uid: recode.uid,
            reportId: recode.reportId,
            kateiNm: recode.kateiNm,
            buNm: recode.buNm,
            kaNm: recode.kaNm,
            gakunen: recode.gakunen,
            gakkyu: recode.gakkyu,
            syussekiNo: recode.syussekiNo,
            userNm: recode.userNm,
            userNmKana: recode.userNmKana,
            title: recode.title,
            body: recode.body,
            categoryNm: recode.categoryNm,
            unreadFlg: recode.unreadFlg,
            submissionFlg: recode.submissionFlg,
            submissionDate: recode.submissionDate,
            submissionDateYYYYMMDD: recode.submissionDateYYYYMMDD,
            submissionStates: "",
            lastPostId: recode.lastPostId,
            lastPostDate: recode.lastPostDate,
            lastPostDateYYYYMMDD: recode.lastPostDateYYYYMMDD,
            lastPostUserNm: recode.lastPostUserNm,
            checkFlg: recode.checkFlg,
          };

          // 提出状況
          if(recode.unreadFlg){
            row.submissionStates = "未読";
            row.submissionDate = "-";
            row.title = "-";
            row.body = "-";
            row.categoryNm = "-";
          } else {
            if(recode.submissionFlg){
              row.submissionStates = "提出済";
            } else {
              row.submissionStates = "既読";
              row.submissionDate = "-";
              row.title = "-";
              row.body = "-";
              row.categoryNm = "-";
            }
          }

          buff.push(row);
        });
        
        // 明細の列切り替え
        if(this.editNameFlg && this.useCategoryFlg){
          this.selectedHeaders = this.headers0;
        } else if(this.editNameFlg && !this.useCategoryFlg){
          this.selectedHeaders = this.headers1;
        } else if(!this.editNameFlg && this.useCategoryFlg){
          this.selectedHeaders = this.headers2;
        } else {
          this.selectedHeaders = this.headers3;
        }

        this.activityRecordList = buff;
        this.isListLoding = false;
      });
    },
    // エクセル出力処理
    async exportExcelActivityRecordForHomework() {
      var result = confirm("出力してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.isExportingFlg = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      
      var param = {
        funcName: "exportExcelActivityRecordForHomework",
        homeworkId: this.homeworkId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data.message){
          alert(ret.data.message);
        } else {
          saveAsCommon(ret.data.downloadUrl, ret.data.fileName);
          alert("ダウンロードを開始します。\nダウンロードフォルダをご確認ください。\n※ファイルを確認出来ない場合はポップアップがブロックされていないか\nご確認ください。");
        }
      });
      // ローディング終了
      this.isExportingFlg = false;
    },
    // 戻るボタン
    toPoHomeworkList() {
      this.$router.push({
        name: this.backPageName,
        params: {
          groupId: this.groupId,
          groupName: this.groupName,
        }
    })},
    // 下部スクロール
    scrollToBottomDialog() {
      this.$refs.commentArea.focus();
    },
    autoLink(text) {
      const autolinker = new Autolinker();
      return autolinker.link(text);
    },
  },
};
</script>