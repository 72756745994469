<template>
  <v-container fluid>
    <v-row dense>
        <v-col cols="12" justify="center">
            <v-card class="mx-auto text-center" color="blue-grey" dark>
            通用門受付
            </v-card>
        </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <!-- 連絡ブロック -->
        <v-card
          class="d-flex justify-center flex-column mx-auto my-6 flat"
          width="1200"
          color="#fff"
        >
          <v-card-title class="d-flex justify-center">
            <v-btn-toggle
              v-model="syukketuKbn"
              @change="changeSyukketuKbn"
              mandatory
            >
              <v-btn large active-class="red--text" value="chikoku">
                <v-icon x-large left>
                  mdi-location-enter
                </v-icon>
                <span>遅刻</span>
              </v-btn>
              <v-btn large active-class="blue--text" value="sotai">
                <span>早退</span>

                <v-icon x-large right>
                  mdi-location-exit
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-card-title>
          <v-card-text class="d-flex justify-center">
            <span class="orange--text">読込用ボックス</span>をクリックした状態でUSBバーコードスキャナにて読込してください。
          </v-card-text>
          <v-card-text class="d-flex justify-center">
            <v-row dense>
              <v-col cols="12" sm="3" md="3" lg="3" xl="3" justify="center">
                <v-text-field
                  v-model="inputCode"
                  ref="box"
                  label="読込用ボックス(学籍番号)"
                  placeholder=""
                  outlined
                  dense
                  :disabled="isReading"
                  background-color="orange lighten-3"
                  @keydown.enter="changeInputCode"
                  append-icon="mdi-barcode-scan"
                  hide-details="auto"
                  >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" lg="3" xl="3" justify="center">
                <v-text-field
                  v-model="cacheCode"
                  label="前回読込コード"
                  outlined
                  dense
                  disabled
                  append-icon="mdi-lock-outline"
                  hide-details="auto"
                  >
                </v-text-field>
              </v-col>
              <v-col cols="3" sm="1" md="1" lg="1" xl="1" justify="center">
                <v-btn
                  depressed
                  :disabled="!isSuccess"
                  color="green"
                  width="90%"
                >
                  成功
                </v-btn>
              </v-col>
              <v-col cols="3" sm="1" md="1" lg="1" xl="1" justify="center">
                <v-btn
                  depressed
                  :disabled="!isError"
                  color="red"
                  width="90%"
                >
                  エラー
                </v-btn>
              </v-col>
              <v-col cols="6" sm="4" md="4" lg="4" xl="4" justify="center">
                <v-text-field
                  v-model="studentName"
                  label="読込生徒名"
                  outlined
                  dense
                  disabled
                  append-icon="mdi-account"
                  hide-details="auto"
                  >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="history"
          :items-per-page="-1"
          :footer-props="{'disable-items-per-page': true}"
          dense
          disable-sort          
          :loading="isListLoding" loading-text="読込中"
        >
          <template #[`header.syukketuKbn`]="{}"> 
            <v-row dense><v-col cols="12" justify="center">
              課程/部/科
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              学年/学級/出席番号
            </v-col></v-row>
          </template>
          <template #[`item.syukketuKbn`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">
              <span v-if="!item.errorFlg">{{ item.kateiNm }}/{{ item.buNm }}/{{ item.kaNm }}</span>
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              <span v-if="!item.errorFlg">{{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}</span>
            </v-col></v-row>
          </template>
          <template #[`item.errorFlg`]="{ item }">
            <span v-if="item.errorFlg" class="red--text">処理失敗</span>
            <span v-else class="green--text">処理成功</span>
          </template>
          <template #[`item.message`]="{ item }">
            <span v-if="item.errorFlg" class="red--text">{{ item.message }}</span>
            <span v-else>{{ item.message }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <v-snackbar
      v-model="viewResultBar"
      timeout="-1"
      top
    >
      処理中
    </v-snackbar>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  data() {
    return {
      headers: [
        { text: "読込時刻", value: "postDate", width: "15%" },
        { text: "読込コード", value: "barcode", width: "10%" },
        { text: "生徒氏名(カナ)", value: "studentName", width: "20%" },
        { text: "課程/部/科 学年/学級/出席番号", value: "syukketuKbn", width: "20%", filter: this.syukketuKbnFilter },
        { text: "ステータス", value: "errorFlg", width: "10%" },
        { text: "処理結果", value: "message", width: "25%" },
      ],
      history: [],
      isListLoding: false,

      syukketuKbn: "chikoku",
      // 読込用ボックス
      inputCode : "",
      isReading: false,
      // 前回読込コード
      cacheCode : "",
      viewResultBar: false,
      // 成功判定表示用
      isSuccess : false,
      isError : false,
      // 読込成功の生徒名
      studentName : "",
    };
  },

  // 初期表示時データ読み込み
  created() {
    this.syukketuKbn = this.$store.state.selectBarCodeSyukketuKbn;
    this.$nextTick(() => this.$refs.box.focus());
    this.reload();
  },
  watch: {
    syukketuKbn: function(newVal) {this.$store.dispatch('setSelectBarCodeSyukketuKbn', newVal)},
  },
  methods: {
    // 画面初期表示時
    async reload() {
      // 読取状況の取得
      this.isListLoding = true;
      this.history = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined({funcName: "loadBarcodeCheckIn"}).then((ret) => {
        this.history = ret.data;
      });
      this.isListLoding = false;
    },
    // 出欠区分の変更
    async changeSyukketuKbn() {
      this.inputCode = "";
      this.cacheCode = "";
      this.studentName = "";
      this.isSuccess = false;
      this.isError = false;
    },
    // 出欠区分フィルタ
    syukketuKbnFilter(value) {
      return this.syukketuKbn === value;
    },
    // バーコード読込時
    async changeInputCode() {
      // 読取ロックの設定
      this.isReading = true;
      this.viewResultBar = true;

      // 全角入力を半角に変換
      var barcode = this.inputCode;
      const fullnums = '０１２３４５６７８９';
      const toHalfnums = text => text.replace(/[０-９]/g, m=>fullnums.indexOf(m));
      this.inputCode = toHalfnums(barcode);

      // サーバー処理
      var param = {
        funcName: "checkInBarCode",
        syukketuKbn: this.syukketuKbn,
        barcode: this.inputCode,
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
  
        // 成功 or エラー表示切替
        if(!ret.data.errorFlg) {
          this.isSuccess = true;
          this.isError = false;
        } else {
          this.isSuccess = false;
          this.isError = true;
        }

        // 読取値の退避
        this.cacheCode = this.inputCode;
        // 生徒名の設定
        this.studentName = ret.data.studentName;
      });

      // 読み取り値の初期化
      this.inputCode = "";
      // 読取ロックの解除
      this.isReading = false;
      this.viewResultBar = false;
      this.$nextTick(() => this.$refs.box.focus());
      
      // 処理後に再表示
      this.reload();
    },
  }
};
</script>