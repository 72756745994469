<template>
  <v-container fluid>
    <v-alert
      v-if="!mailAddressAuthFlg"
      dense
      border="left"
      type="info"
      dismissible
    >
      右上の設定をクリックして、メールアドレスの登録をお願いいたします。
    </v-alert>

    <v-alert
      v-if="requestArrivalStatusFlg"
      dense
      border="left"
      type="info"
    >
      <v-row
        align="center"
        no-gutters
      >
        <v-col class="grow">
          自宅へ到着したら帰宅連絡のボタンを押してください。
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="shrink">
          <v-btn
            color="white"
            class="black--text"
            @click="onChangeArrivalStatus()"
          >
            帰宅連絡
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-row dense>
      <v-col cols="12">
        <v-tabs fixed-tabs v-model="tab">
          <v-tab href="#contact">
            <v-btn color="red" small icon outlined v-if="unreadContactCount > 0 && unreadContactCount <= 99">{{ unreadContactCount }}</v-btn>
            <v-btn color="red" small icon outlined v-if="unreadContactCount > 99">99+</v-btn>
            学校からの連絡
          </v-tab>
          <v-tab href="#survey">
            <v-btn color="red" small icon outlined v-if="unreadSurveysCount > 0 && unreadSurveysCount <= 99">{{ unreadSurveysCount }}</v-btn>
            <v-btn color="red" small icon outlined v-if="unreadSurveysCount > 99">99+</v-btn>
            アンケート
          </v-tab>
          <!--<v-tab href="#homework">
            <v-btn color="red" small icon outlined v-if="unreadHomeworksCount > 0 && unreadHomeworksCount <= 99">{{ unreadHomeworksCount }}</v-btn>
            <v-btn color="red" small icon outlined v-if="unreadHomeworksCount > 99">99+</v-btn>
            課題
          </v-tab>-->
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item value="contact">
            <v-row dense class="pt-2 pb-2 blue-grey lighten-5 d-sm-none">
              <v-col class="d-flex justify-end mr-2">
                <v-btn color="green" @click="filterDialog1 = true" small>
                  <v-icon right dark v-if="isFilterFlg1">mdi-filter</v-icon>
                  <v-icon right dark v-if="!isFilterFlg1">mdi-filter-outline</v-icon>
                  フィルタ
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense class="mt-1 pt-2 pb-2 blue-grey lighten-5 hidden-xs-only">
              <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                <!-- 検索用のテキスト入力 -->
                <v-text-field
                  v-model="titleFilterValue1"
                  label="ワード検索"
                  placeholder="ワード検索"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                <!-- 検索用のテキスト入力 -->
                <v-autocomplete
                  v-model="groupFilterValue1"
                  label="グループ指定"
                  placeholder="グループ指定"
                  :items="groupNames1"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="6" md="2" lg="2" xl="2">
                <v-switch 
                  v-model="viewUnreadOnly1"
                  @click="changeFilter1(true)"
                  dense
                  class="mt-1"
                  label="未読のみ"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6" sm="6" md="2" lg="2" xl="2">
                <v-switch 
                  v-model="viewFavoriteOnly1"
                  @click="changeFilter1(false)"
                  dense
                  class="mt-1"
                  label="お気に入りのみ"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="contacts"
              :items-per-page.sync="parPageContact"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              dense
              disable-sort
              @click:row="onClickContactDetails"
              :item-class="rowStyleContact"
              :loading="isContactsLoding" loading-text="読込中"
              mobile-breakpoint="0"
              ref="contact"
              item-key="contactId"
            >

              <!-- タイトルとモバイル用まとめ -->
              <template #[`item.title`]="{ item }">
                <v-row no-gutters class="hidden-xs-only">
                  <v-col cols="12" justify="center">
                    {{ item.title }}
                  </v-col>
                </v-row>
                <!-- モバイル用まとめ -->
                <v-row dense class="d-sm-none">
                  <v-col cols="12" justify="center">
                    <!-- １行目(グループ名＋投稿者) -->
                    <v-row no-gutters>
                      <v-col cols="6" class="text-caption">
                        {{ item.groupName }}
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end text-caption">
                        {{ item.postUser }}
                      </v-col>
                    </v-row>
                    <!-- ２行目(連絡タイトル) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="text-subtitle-1">
                        {{ item.title }}
                      </v-col>
                    </v-row>
                    <!-- ３行目(公開開始日＋既読状況) -->
                    <v-row no-gutters>
                      <v-col cols="9" class="text-caption">
                        公開:{{ item.openDate }}
                      </v-col>
                      <v-col cols="3" class="d-flex justify-end text-caption">
                        {{ item.unreadState }}
                      </v-col>
                    </v-row>
                    <!-- ４行目(対象ユーザー区分＋コメント＋お気に入り) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="d-flex justify-end text-caption">
                        <v-icon v-if="item.studentFlg">$student</v-icon>
                        <v-icon v-if="item.parentFlg">$parent</v-icon>
                        <div v-if="item.targetSelectFlg">【限定】</div>
                        <v-icon class="ml-2" v-if="item.commentFlg">mdi-comment-text-outline</v-icon>
                        <v-icon v-if="item.unreadCommentCount > 0" x-small color="red">mdi-new-box</v-icon>
                        <v-icon v-if="item.favoriteFlg" color="orange">mdi-star</v-icon>
                        <v-icon v-if="!item.favoriteFlg">mdi-star-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>

              <!-- 投稿日と投稿者 -->
              <template #[`item.openDateAndPostUser`]="{ item }">
                <v-row no-gutters><v-col cols="12" justify="center">{{ item.openDate }}</v-col></v-row>
                <v-row no-gutters><v-col cols="12" justify="center">{{ item.postUser }}</v-col></v-row>
              </template>
              <template #[`item.target`]="{ item }">
                <v-icon x-large v-if="item.studentFlg">$student</v-icon>
                <v-icon x-large v-if="item.parentFlg">$parent</v-icon>
                <div v-if="item.targetSelectFlg">【限定】</div>
              </template>
              <template #[`item.commentFlg`]="{ item }">
                <v-icon class="ml-2" v-if="item.commentFlg">mdi-comment-text-outline</v-icon>
                <v-icon v-if="item.unreadCommentCount > 0" large color="red">mdi-new-box</v-icon>
              </template>
              <!-- お気に入り -->
              <template #[`item.favoriteFlg`]="{ item }">
                <v-btn icon v-if="item.favoriteFlg" :disable="true"><v-icon color="orange">mdi-star</v-icon></v-btn>
                <v-btn icon v-if="!item.favoriteFlg" :disable="true"><v-icon>mdi-star-outline</v-icon></v-btn>
              </template>
              <!-- 既読/未読 -->
              <template #[`item.unreadFlg`]="{ item }">
                {{ item.unreadState }}
              </template>
              <!-- 本文 -->
              <template #expanded-item="{ item }">
                <td colspan="7" @click="onClickContactDetails(item)" elevation="0" style="height:20px;background-color: #eee;">
                  <v-row no-gutters>
                    <v-col cols="12" class="text-truncate text-caption" style="width:200px">
                      <v-icon small>mdi-arrow-right-bottom</v-icon>
                      {{ item.body }}
                    </v-col>
                  </v-row>
                </td>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="survey">
            <v-row dense class="pt-2 pb-2 blue-grey lighten-5 d-sm-none">
              <v-col class="d-flex justify-end mr-2">
                <v-btn color="green" @click="filterDialog2 = true" small>
                  <v-icon right dark v-if="isFilterFlg2">mdi-filter</v-icon>
                  <v-icon right dark v-if="!isFilterFlg2">mdi-filter-outline</v-icon>
                  フィルタ
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense class="mt-1 pt-2 pb-2 blue-grey lighten-5 hidden-xs-only">
              <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                <!-- 検索用のテキスト入力 -->
                <v-text-field
                  v-model="titleFilterValue2"
                  label="ワード検索"
                  placeholder="ワード検索"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                <!-- 検索用のテキスト入力 -->
                <v-autocomplete
                  v-model="groupFilterValue2"
                  label="グループ指定"
                  placeholder="グループ指定"
                  :items="groupNames2"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="6" md="2" lg="2" xl="2">
                <v-switch 
                  v-model="viewUnreadOnly2"
                  @click="changeFilter2(true)"
                  dense
                  class="mt-1"
                  label="未読のみ"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6" sm="6" md="2" lg="2" xl="2">
                <v-switch 
                  v-model="viewFavoriteOnly2"
                  @click="changeFilter2(false)"
                  dense
                  class="mt-1"
                  label="お気に入りのみ"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-data-table
              :headers="surveyHeaders"
              :items="surveyList"
              :items-per-page.sync="parPageSurvey"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              dense
              disable-sort
              @click:row="onClickSurveyDetails"
              :item-class="rowStyleSurvey"
              :loading="isSurveysLoding" loading-text="読込中"
              mobile-breakpoint="0"
              ref="survey"
              item-key="surveyId"
            >
              <!-- タイトルと締切日とモバイル用まとめ -->
              <template #[`item.title`]="{ item }">
                <v-row no-gutters class="hidden-xs-only">
                  <v-col cols="12" justify="center">
                    {{ item.title }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="hidden-xs-only">
                  <v-col cols="12" justify="center" class="red--text" v-if="item.limitOverFlg">
                    {{ item.limitDate }}
                  </v-col>
                  <v-col cols="12" justify="center" v-if="!item.limitOverFlg">
                    {{ item.limitDate }}
                  </v-col>
                </v-row>

                <!-- モバイル用まとめ -->
                <v-row dense class="d-sm-none">
                  <v-col cols="12" justify="center">
                    <!-- １行目(グループ名＋投稿者) -->
                    <v-row no-gutters>
                      <v-col cols="8" class="text-caption">
                        {{ item.groupName }}
                      </v-col>
                      <v-col cols="4" class="d-flex justify-end text-caption">
                        {{ item.postUser }}
                      </v-col>
                    </v-row>
                    <!-- ２行目(アンケートタイトル) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="text-subtitle-1">
                        {{ item.title }}
                      </v-col>
                    </v-row>
                    <!-- ３行目(公開開始日＋既読状況＋入力状況) -->
                    <v-row no-gutters>
                      <v-col cols="8" class="text-caption">
                        公開:{{ item.openDate }}
                      </v-col>
                      <v-col cols="4" class="d-flex justify-end text-caption">
                        {{ item.unreadState }}：
                        {{ item.inputState }}
                      </v-col>
                    </v-row>
                    <!-- ４行目(回答期限＋対象ユーザー区分＋お気に入り) -->
                    <v-row no-gutters>
                      <v-col cols="8" class="text-caption">
                        期限:<span v-if="item.limitOverFlg" class="red--text">{{ item.limitDate }}</span>
                        <span v-if="!item.limitOverFlg">{{ item.limitDate }}</span>
                      </v-col>
                      <v-col cols="4" class="d-flex justify-end text-caption">
                        <v-icon v-if="item.studentFlg">$student</v-icon>
                        <v-icon v-if="item.parentFlg">$parent</v-icon>
                        <div v-if="item.targetSelectFlg">【限定】</div>
                        <v-icon v-if="item.favoriteFlg" color="orange">mdi-star</v-icon>
                        <v-icon v-if="!item.favoriteFlg">mdi-star-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>

              <!-- 投稿日と投稿者 -->
              <template #[`item.openDateAndPostUser`]="{ item }">
                <v-row no-gutters><v-col cols="12" justify="center">{{ item.openDate }}</v-col></v-row>
                <v-row no-gutters><v-col cols="12" justify="center">{{ item.postUser }}</v-col></v-row>
              </template>
              <!-- 対象ユーザー区分 -->
              <template #[`item.target`]="{ item }">
                <v-icon x-large v-if="item.studentFlg">$student</v-icon>
                <v-icon x-large v-if="item.parentFlg">$parent</v-icon>
                <div v-if="item.targetSelectFlg">【限定】</div>
              </template>
              <!-- お気に入り -->
              <template #[`item.favoriteFlg`]="{ item }">
                <v-btn icon v-if="item.favoriteFlg" :disable="true"><v-icon color="orange">mdi-star</v-icon></v-btn>
                <v-btn icon v-if="!item.favoriteFlg" :disable="true"><v-icon>mdi-star-outline</v-icon></v-btn>
              </template>
              <!-- 入力状況 -->
              <template #[`item.unreadFlg`]="{ item }">
                {{ item.inputState }}
              </template>
              <!-- 本文 -->
              <template #expanded-item="{ item }">
                <td colspan="7" @click="onClickSurveyDetails(item)" elevation="0" style="height:20px;background-color: #eee;">
                  <v-row no-gutters>
                    <v-col cols="12" class="text-truncate text-caption" style="width:200px">
                      <v-icon small>mdi-arrow-right-bottom</v-icon>
                      {{ item.body }}
                    </v-col>
                  </v-row>
                </td>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item value="homework">
            <v-row dense class="pt-2 pb-2 blue-grey lighten-5 d-sm-none">
              <v-col cols="6" class="d-flex justify-start px-2">
                <v-btn color="accent" class="black--text" small @click="toPoActivityRecordRegist()"><v-icon left dark>mdi-pen-plus</v-icon>活動記録作成</v-btn>
              </v-col>  
              <v-col cols="6" class="d-flex justify-end px-2">
                <v-btn color="green" @click="filterDialog3 = true" small>
                  <v-icon right dark v-if="isFilterFlg3">mdi-filter</v-icon>
                  <v-icon right dark v-if="!isFilterFlg3">mdi-filter-outline</v-icon>
                  フィルタ
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense class="mt-1 pt-2 pb-2 blue-grey lighten-5 hidden-xs-only">
              <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                <!-- 検索用のテキスト入力 -->
                <v-text-field
                  v-model="titleFilterValue3"
                  label="タイトル検索"
                  placeholder="タイトル検索"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                <!-- 検索用のテキスト入力 -->
                <v-autocomplete
                  v-model="groupFilterValue3"
                  label="グループ指定"
                  placeholder="グループ指定"
                  :items="groupNames3"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="2" lg="2" xl="2">
                <v-switch 
                  v-model="viewUnreadOnly3"
                  @click="changeFilter3(true)"
                  dense
                  class="mt-1"
                  label="未読のみ"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="12" sm="4" md="2" lg="2" xl="2">
                <v-switch 
                  v-model="viewFavoriteOnly3"
                  @click="changeFilter3(false)"
                  dense
                  class="mt-1"
                  label="お気に入りのみ"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="12" sm="4" md="2" lg="2" xl="2">
                <v-btn color="accent" class="mx-auto black--text" @click="toPoActivityRecordRegist()" width="90%"><v-icon left dark>mdi-pen-plus</v-icon>活動記録作成</v-btn>
              </v-col>
            </v-row>
            <v-data-table
              :headers="homeworkHeaders"
              :items="homeworkList"
              :items-per-page.sync="parPageHomework"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              dense
              disable-sort
              @click:row="onClickPoHomeworkDetails"
              :item-class="rowStyleHomework"
              :loading="isHomeworkLoding" loading-text="読込中"
              mobile-breakpoint="0"
              ref="homework"
            >

              <!-- 課題名と期限とモバイル用まとめ -->
              <template #[`item.title`]="{ item }">
                <v-row no-gutters class="hidden-xs-only">
                  <v-col cols="12" justify="center">
                    <span>{{ item.title }}</span><span v-if="item.editNameFlg">：{{ item.titleAR }}</span>
                  </v-col>
                </v-row>
                <v-row no-gutters class="hidden-xs-only">
                  <v-col cols="12" justify="center" class="red--text" v-if="item.limitOverFlg">
                    {{ item.limitDate }}
                  </v-col>
                  <v-col cols="12" justify="center" v-if="!item.limitOverFlg">
                    {{ item.limitDate }}
                  </v-col>
                </v-row>

                <!-- モバイル用まとめ -->
                <v-row dense class="d-sm-none">
                  <v-col cols="12" justify="center">
                    <!-- １行目(グループ名＋投稿者) -->
                    <v-row no-gutters>
                      <v-col cols="8" class="text-caption">
                        {{ item.groupName }}
                      </v-col>
                      <v-col cols="4" class="d-flex justify-end text-caption">
                        {{ item.postUser }}
                      </v-col>
                    </v-row>
                    <!-- ２行目(アンケートタイトル) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="text-subtitle-1">
                        <span>{{ item.title }}</span><span v-if="item.editNameFlg">：{{ item.titleAR }}</span>
                      </v-col>
                    </v-row>
                    <!-- ３行目(公開開始日＋既読状況＋入力状況) -->
                    <v-row no-gutters>
                      <v-col cols="8" class="text-caption">
                        公開:{{ item.openDate }}
                      </v-col>
                      <v-col cols="4" class="d-flex justify-end text-caption">
                        {{ item.submissionState }}
                      </v-col>
                    </v-row>
                    <!-- ４行目(回答期限＋お気に入り) -->
                    <v-row no-gutters>
                      <v-col cols="8" class="text-caption">
                        期限:<span v-if="item.limitOverFlg" class="red--text">{{ item.limitDate }}</span>
                        <span v-if="!item.limitOverFlg">{{ item.limitDate }}</span>
                      </v-col>
                      <v-col cols="4" class="d-flex justify-end text-caption">
                        <v-icon v-if="item.favoriteFlg" color="orange">mdi-star</v-icon>
                        <v-icon v-if="!item.favoriteFlg">mdi-star-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>

              <!-- 登録者とグループ名 -->
              <template #[`item.groupName`]="{ item }">
                <v-row no-gutters><v-col cols="12" justify="center">{{ item.postUser }}</v-col></v-row>
                <v-row no-gutters><v-col cols="12" justify="center">{{ item.groupName }}</v-col></v-row>
              </template>
              <!-- お気に入り -->
              <template #[`item.favoriteFlg`]="{ item }">
                <v-btn icon v-if="item.favoriteFlg" :disable="true"><v-icon color="orange">mdi-star</v-icon></v-btn>
                <v-btn icon v-if="!item.favoriteFlg" :disable="true"><v-icon>mdi-star-outline</v-icon></v-btn>
              </template>
              <!-- 提出状況 -->
              <template #[`item.unreadFlg`]="{ item }">
                {{ item.submissionState }}
              </template>
              <template #[`item.comment`]="{ item }">
                <v-btn icon v-if="item.checkFlg">
                  <v-icon color="red">mdi-exclamation-thick</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- 連絡フィルタモーダル -->
    <v-dialog
      v-model="filterDialog1"
      persistent
      max-width="600"
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="600"
        color="#fff"
      >
        <v-card-title class="d-flex pt-3 justify-center" white-space="pre">
          <v-row dense>
            <v-col dense cols="4" justify="center">
              <v-btn color="grey" class="black--text" @click="clearFilter1" width="90%">
                クリア
              </v-btn>
            </v-col>
            <v-col dense cols="4" justify="center">
              <p>フィルタ</p>
            </v-col>
            <v-col dense cols="4" justify="center">
              <v-btn color="accent" class="black--text" @click="doFilter1" width="90%">
                適用
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-text-field
                v-model="titleFilterValue1"
                label="ワード検索"
                placeholder="ワード検索"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-autocomplete
                v-model="groupFilterValue1"
                label="グループ指定"
                placeholder="グループ指定"
                :items="groupNames1"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewUnreadOnly1"
                @click="changeFilter1(true)"
                dense
                class="mt-1"
                label="未読のみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewFavoriteOnly1"
                @click="changeFilter1(false)"
                dense
                class="mt-1"
                label="お気に入りのみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- アンケートフィルタモーダル -->
    <v-dialog
      v-model="filterDialog2"
      persistent
      max-width="600"
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="600"
        color="#fff"
      >
        <v-card-title class="d-flex pt-3 justify-center" white-space="pre">
          <v-row dense>
            <v-col dense cols="4" justify="center">
              <v-btn color="grey" class="black--text" @click="clearFilter2" width="90%">
                クリア
              </v-btn>
            </v-col>
            <v-col dense cols="4" justify="center">
              <p>フィルタ</p>
            </v-col>
            <v-col dense cols="4" justify="center">
              <v-btn color="accent" class="black--text" @click="doFilter2" width="90%">
                適用
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-text-field
                v-model="titleFilterValue2"
                label="ワード検索"
                placeholder="ワード検索"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-autocomplete
                v-model="groupFilterValue2"
                label="グループ指定"
                placeholder="グループ指定"
                :items="groupNames2"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewUnreadOnly2"
                @click="changeFilter2(true)"
                dense
                class="mt-1"
                label="未読のみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewFavoriteOnly2"
                @click="changeFilter2(false)"
                dense
                class="mt-1"
                label="お気に入りのみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- 課題フィルタモーダル -->
    <v-dialog
      v-model="filterDialog3"
      persistent
      max-width="600"
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="600"
        color="#fff"
      >
        <v-card-title class="d-flex pt-3 justify-center" white-space="pre">
          <v-row dense>
            <v-col dense cols="4" justify="center">
              <v-btn color="grey" class="black--text" @click="clearFilter3" width="90%">
                クリア
              </v-btn>
            </v-col>
            <v-col dense cols="4" justify="center">
              <p>フィルタ</p>
            </v-col>
            <v-col dense cols="4" justify="center">
              <v-btn color="accent" class="black--text" @click="doFilter3" width="90%">
                適用
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-text-field
                v-model="titleFilterValue3"
                label="タイトル検索"
                placeholder="タイトル検索"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-autocomplete
                v-model="groupFilterValue3"
                label="グループ指定"
                placeholder="グループ指定"
                :items="groupNames3"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewUnreadOnly3"
                @click="changeFilter3(true)"
                dense
                class="mt-1"
                label="未読のみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewFavoriteOnly3"
                @click="changeFilter3(false)"
                dense
                class="mt-1"
                label="お気に入りのみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import Enumerable from "linq";
export default {
  data() {
    return {
      tab: 'contact',
      // 連絡
      filterDialog1: false,
      isFilterFlg1: false,
      titleFilterValue1: "",
      groupFilterValue1: "",
      viewUnreadOnly1: false,
      viewFavoriteOnly1: false,
      groupNames1: [],
      // アンケート
      filterDialog2: false,
      isFilterFlg2: false,
      titleFilterValue2: "",
      groupFilterValue2: "",
      viewUnreadOnly2: false,
      viewFavoriteOnly2: false,
      groupNames2 : [],
      // 課題
      filterDialog3: false,
      isFilterFlg3: false,
      titleFilterValue3: "",
      groupFilterValue3: "",
      viewUnreadOnly3: false,
      viewFavoriteOnly3: false,
      groupNames3 : [],

      requestArrivalStatusFlg: false,
      groupNames : [],
      headers: [
        { text: "タイトル", value: "title", class: "hidden-xs-only" },
        { text: "グループ名", value: "groupName", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.groupNameFilter1 },
        { text: "対象", value: "target", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "更新日時/更新者", value: "openDateAndPostUser", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "コメント", value: "commentFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "お気に入り", value: "favoriteFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.favoriteFilter1 },
        { text: "既読/未読", value: "unreadFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.unreadFilter1 },
        { value: "contactId", class: "d-none", cellClass: "d-none" },
        { value: "body", class: "d-none", cellClass: "d-none" },
        { value: "data-table-expand", class: "d-none", cellClass: "d-none" },
      ],
      contacts: [],
      surveyHeaders : [
        { text: "タイトル/回答期限", value: "title", class: "hidden-xs-only" },  
        { text: "グループ名", value: "groupName", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.groupNameFilter2 },
        { text: "対象", value: "target", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "更新日時/更新者", value: "openDateAndPostUser", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "お気に入り", value: "favoriteFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.favoriteFilter2 },
        { text: "既読/未読", value: "unreadState", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "入力状況", value: "unreadFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.unreadFilter2 },
        { value: "surveyId", class: "d-none", cellClass: "d-none" },
        { value: "body", class: "d-none", cellClass: "d-none" },
        { value: "data-table-expand", class: "d-none", cellClass: "d-none" },
      ],
      surveyList : [],
      homeworkHeaders : [
        { text: "タイトル/期限", value: "title", class: "hidden-xs-only", filter: this.titleFilter3 },  
        { text: "登録者/グループ名", value: "groupName", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.groupNameFilter3 },
        { text: "お気に入り", value: "favoriteFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.favoriteFilter3 },
        { text: "提出状況" ,value:"unreadFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.unreadFilter3},
        { text: "コメント", value: "comment", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
      ],
      homeworkList : [],
      isContactsLoding: false,
      isSurveysLoding: false,
      isHomeworkLoding: false,
      mailAddressAuthFlg: true,
      parPageContact: 30,
      parPageSurvey: 30,
      parPageHomework: 30,
      unreadContactCount: 0,
      unreadSurveysCount: 0,
      unreadHomeworksCount: 0,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    this.tab = this.$store.state.viewConditionTabSelect;
    this.groupFilterValue = this.$store.state.searchConditionGroupName;
    this.viewUnreadOnly = this.$store.state.searchConditionUnread;
    this.viewFavoriteOnly = this.$store.state.searchConditionFavorite;
    this.parPageContact = this.$store.state.parPageStudentTopPageContact;
    this.parPageSurvey = this.$store.state.parPageStudentTopPageSurvey;
    this.parPageHomework = this.$store.state.parPageStudentTopPageHomework;
    await this.reload();
  },
  watch: {
    tab: function(newVal) {this.$store.dispatch('setViewConditionTabSelect', newVal)},
    groupFilterValue: function(newVal) {this.$store.dispatch('setSearchConditionGroupName', newVal)},
    viewUnreadOnly: function(newVal) {this.$store.dispatch('setSearchConditionUnread', newVal)},
    viewFavoriteOnly: function(newVal) {this.$store.dispatch('setSearchConditionFavorite', newVal)},
    parPageContact: function(newVal) {this.$store.dispatch('setParPageStudentTopPageContact', newVal)},
    parPageSurvey: function(newVal) {this.$store.dispatch('setParPageStudentTopPageSurvey', newVal)},
    parPageHomework: function(newVal) {this.$store.dispatch('setParPageStudentTopPageHomework', newVal)},
  },
  methods: {
    // タイトルフィルタ
    titleFilter(value) {
      if (!this.titleFilterValue) {
        return true;
      }
      return value.toString().includes(this.titleFilterValue);
    },
    // グループ名フィルタ
    groupNameFilter(value) {
      if (!this.groupFilterValue) {
        return true;
      }
      return value.toString().includes(this.groupFilterValue);
    },
    // 未読フィルタ
    unreadFilter(value) {
      if (!this.viewUnreadOnly) {
        return true;
      }
      return value;
    },
    // お気に入りフィルタ
    favoriteFilter(value) {
      if (!this.viewFavoriteOnly) {
        return true;
      }
      return value;
    },
    changeFilter(flg){
      if(this.viewUnreadOnly && this.viewFavoriteOnly){
        if(flg){
          this.viewFavoriteOnly = false;
        } else {
          this.viewUnreadOnly = false;
        }
      }
    },
    // 連絡詳細
    onClickContactDetails(item) {
      // フィルタされた配列の取得
      let items = this.$refs.contact.$children[0].filteredItems;
      // 連絡IDのみ抽出
      var contactListBuff = Enumerable.from(items).select(x => { return x.messageId }).toArray();
      var contactListIndex = contactListBuff.indexOf(item.messageId);

      this.$router.push({
        name: "ContactDetails",
        params: {
          messageId: item.messageId,
          contactListBuff: contactListBuff,
          contactListIndex: contactListIndex,
        }
      });
    },
    // アンケート回答
    onClickSurveyDetails(item) {
      // フィルタされた配列の取得
      let items = this.$refs.survey.$children[0].filteredItems;
      // 調査IDのみ抽出
      var surveyListBuff = Enumerable.from(items).select(x => { return x.responseId }).toArray();
      var surveyListIndex = surveyListBuff.indexOf(item.responseId);

      this.$router.push({
        name: "SurveyDetails",
        params: {
          responseId : item.responseId,
          surveyListBuff: surveyListBuff,
          surveyListIndex: surveyListIndex,
        } 
      });
    },
    // 課題詳細
    onClickPoHomeworkDetails(item) {
      // フィルタされた配列の取得
      let items = this.$refs.homework.$children[0].filteredItems;
      // 連絡IDのみ抽出
      var homeworkListBuff = Enumerable.from(items).select(x => { return x.reportId }).toArray();
      var homeworkListIndex = homeworkListBuff.indexOf(item.reportId);

      this.$router.push({
        name: "PoHomeworkDetails",
        params: {
          reportId: item.reportId,
          homeworkListBuff: homeworkListBuff,
          homeworkListIndex: homeworkListIndex,
        }
      });
    },
    // ページ再読込
    async reload() {
      var unreadContactCount = 0;
      var unreadSurveysCount = 0;
      var unreadHomeworksCount = 0;
      // 学校からの連絡取得
      this.contacts = [];
      this.surveyList = [];
      this.homeworkList = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isContactsLoding = true;
      this.isSurveysLoding = true;
      this.isHomeworkLoding = true;
      await schoolAidHomeLogined({funcName:"getArrivalStatusForStudent"}).then((ret) => {
        // 帰宅連絡が必要かどうか取得して判定する。
        if(ret.data != ""){
          this.requestArrivalStatusFlg = ret.data;
        }
      });
      await schoolAidHomeLogined({funcName:"loadTopPage"}).then((contacts) => {
        var ret = [];
        var temp_groupNames = new Set();
        contacts.data.list.forEach((contact) => {
          temp_groupNames.add(contact.groupName);
          var row = {
            contactId: contact.contactId,
            openDate: contact.openDate,
            postUser: contact.postUser,
            groupName: contact.groupName,
            studentFlg: contact.studentFlg,
            parentFlg: contact.parentFlg,
            teacherFlg: contact.teacherFlg,
            title: contact.title,
            body: contact.body,
            messageId: contact.messageId,
            commentFlg: contact.commentFlg,
            unreadFlg: (contact.unreadFlg)?contact.unreadFlg:(contact.unreadCommentCount>0),
            unreadCommentCount: contact.unreadCommentCount,
            favoriteFlg: contact.favoriteFlg,
            targetSelectFlg: contact.targetSelectFlg,
          };
          if(row.unreadFlg){
            row.unreadState = "未読";
            unreadContactCount++;
          } else {
            row.unreadState = "既読";
          }
          ret.push(row);
        });
        this.unreadContactCount = unreadContactCount;
        this.contacts = ret;
        this.mailAddressAuthFlg = contacts.data.mailAddressAuthFlg;
        this.groupNames1 = Array.from(temp_groupNames);
        this.isContactsLoding = false;
      });
      await schoolAidHomeLogined({funcName:"loadTopPageSurvey"}).then((snapshot) => {
        var ret = [];
        var temp_groupNames = new Set();
        snapshot.data.forEach((survey) => {
          temp_groupNames.add(survey.groupName);
          var row = {
            surveyId: survey.surveyId,
            openDate: survey.openDate,
            limitDate: survey.limitDate,
            limitOverFlg: survey.limitOverFlg,
            postUser: survey.postUser,
            groupName: survey.groupName,
            studentFlg: survey.studentFlg,
            parentFlg: survey.parentFlg,
            teacherFlg: survey.teacherFlg,
            title: survey.title,
            body: survey.body,
            responseId: survey.responseId,
            unreadFlg: survey.unreadFlg,
            inputFlg: survey.inputFlg,
            favoriteFlg: survey.favoriteFlg,
            targetSelectFlg: survey.targetSelectFlg,
          };
          if(survey.unreadFlg){
            row.unreadState = "未読";
            unreadSurveysCount++;
          } else {
            row.unreadState = "既読";
          }
          if(survey.inputFlg){
            row.inputState = "入力済";
          } else {
            row.inputState = "未入力";
          }
          ret.push(row);
        });
        this.unreadSurveysCount = unreadSurveysCount;
        this.surveyList = ret;
        this.groupNames2 = Array.from(temp_groupNames);
        this.isSurveysLoding = false;
      });
      await schoolAidHomeLogined({funcName:"loadTopPageHomework"}).then((snapshot) => {
        var ret = [];
        var temp_groupNames = new Set();
        snapshot.data.forEach((report) => {
          temp_groupNames.add(report.groupName);
          var row = {
            homeworkId: report.homeworkId,
            title: report.title,
            titleAR: report.titleAR,
            limitDate: report.limitDate,
            limitOverFlg: report.limitOverFlg,
            groupName: report.groupName,
            openDate: report.openDate,
            postUser: report.postUser,
            reportId: report.reportId,
            unreadFlg: report.unreadFlg,
            submissionFlg: report.submissionFlg,
            editNameFlg: report.editNameFlg,
            favoriteFlg: report.favoriteFlg,
            targetSelectFlg: report.targetSelectFlg,
            checkFlg: report.checkFlg,
          };
          // 提出状況の表示
          if(row.unreadFlg){
            row.submissionState = "未読";
            unreadHomeworksCount++;
          } else {
            if(row.submissionFlg){
              row.submissionState = "提出済";
            } else {
              row.submissionState = "既読：未提出";
            }
            // 新規コメントの確認表示
            if(row.checkFlg){
              unreadHomeworksCount++;
            }
          }

          ret.push(row);
        });
        this.unreadHomeworksCount = unreadHomeworksCount;
        this.homeworkList = ret;
        this.groupNames3 = Array.from(temp_groupNames);
        this.isHomeworkLoding = false;
      });
    },
    async onChangeArrivalStatus()  {
      var result = confirm("帰宅状況を学校へ連絡しますか？");
      if (!result) {
        return;
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "changeArrivalStatusForStudent",
      };
      await schoolAidHomeLogined(data);
      this.requestArrivalStatusFlg = false;
    },
    rowStyleContact: function (item) {
      return item.unreadFlg == true ? 'not-yet-style' : 'do-style'
    },
    rowStyleSurvey: function (item) {
      return item.inputFlg == true ? 'do-style' : 'not-yet-style'
    },
    rowStyleHomework: function (item) {
      return item.submissionFlg == true ? 'do-style' : 'not-yet-style'
    },
    // 活動記録登録画面へ遷移
    async toPoActivityRecordRegist() {
      // 所属学級の代表課題のIDを取得
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var homeworkId = "";
      var param = {
        funcName: "getClassRepHomeworkId",
      }
      await schoolAidHomeLogined(param).then((ret) => {        
        homeworkId = ret.data;
      });
      
      // 画面遷移
      this.$router.push({
        name: "PoActivityRecordRegist",
        params: {
          homeworkId: homeworkId,
        }
      });
    },
    // 検索(連絡用)=================================================================================
    // タイトルフィルタ
    titleFilter1(value, search, item) {
      if(!search)return true;
      return (item.title.indexOf(search) >= 0 || item.body.indexOf(search) >= 0);
    },
    // グループ名フィルタ
    groupNameFilter1(value) {
      if (!this.groupFilterValue1) {
        return true;
      }
      return value.toString() == this.groupFilterValue1;
    },
    // 未読フィルタ
    unreadFilter1(value) {
      if (!this.viewUnreadOnly1) {
        return true;
      }
      return value;
    },
    // お気に入りフィルタ
    favoriteFilter1(value) {
      if (!this.viewFavoriteOnly1) {
        return true;
      }
      return value;
    },
    changeFilter1(flg){
      if(this.viewUnreadOnly1 && this.viewFavoriteOnly1){
        if(flg){
          this.viewFavoriteOnly1 = false;
        } else {
          this.viewUnreadOnly1 = false;
        }
      }
    },
    // 検索(アンケート用)=================================================================================
    // タイトルフィルタ
    titleFilter2(value, search, item) {
      if(!search)return true;
      return (item.title.indexOf(search) >= 0 || item.body.indexOf(search) >= 0);
    },
    // グループ名フィルタ
    groupNameFilter2(value) {
      if (!this.groupFilterValue2) {
        return true;
      }
      return value.toString() == this.groupFilterValue2;
    },
    // 未読フィルタ
    unreadFilter2(value) {
      if (!this.viewUnreadOnly2) {
        return true;
      }
      return value;
    },
    // お気に入りフィルタ
    favoriteFilter2(value) {
      if (!this.viewFavoriteOnly2) {
        return true;
      }
      return value;
    },
    changeFilter2(flg){
      if(this.viewUnreadOnly2 && this.viewFavoriteOnly2){
        if(flg){
          this.viewFavoriteOnly2 = false;
        } else {
          this.viewUnreadOnly2 = false;
        }
      }
    },
    // 検索(課題)=================================================================================
    // タイトルフィルタ
    titleFilter3(value) {
      if (!this.titleFilterValue3) {
        return true;
      }
      return value.toString().includes(this.titleFilterValue3);
    },
    // 投稿者名フィルタ
    postUserFilter3(value) {
      if (!this.postUsersFilterValue3) {
        return true;
      }
      return value.toString().includes(this.postUsersFilterValue3);
    },
    // グループ名フィルタ
    groupNameFilter3(value) {
      if (!this.groupFilterValue3) {
        return true;
      }
      return value.toString() == this.groupFilterValue3;
    },
    // 未読フィルタ
    unreadFilter3(value) {
      if (!this.viewUnreadOnly3) {
        return true;
      }
      return value;
    },
    // お気に入りフィルタ
    favoriteFilter3(value) {
      if (!this.viewFavoriteOnly3) {
        return true;
      }
      return value;
    },
    changeFilter3(flg){
      if(this.viewUnreadOnly3 && this.viewFavoriteOnly3){
        if(flg){
          this.viewFavoriteOnly3 = false;
        } else {
          this.viewUnreadOnly3 = false;
        }
      }
    },

    // 検索モーダル用 =================================================================================
    doFilter1() {
      this.isFilterFlg1 = true;
      this.filterDialog1 = false;
    },
    clearFilter1(){
      this.titleFilterValue1 = "";
      this.groupFilterValue1 = "";
      this.viewUnreadOnly1 = false;
      this.viewFavoriteOnly1 = false;

      this.isFilterFlg1 = false;
      this.filterDialog1 = false;
    },
    doFilter2() {
      this.isFilterFlg2 = true;
      this.filterDialog2 = false;
    },
    clearFilter2(){
      this.titleFilterValue2 = "";
      this.groupFilterValue2 = "";
      this.viewUnreadOnly2 = false;
      this.viewFavoriteOnly2 = false;

      this.isFilterFlg2 = false;
      this.filterDialog2 = false;
    },
    doFilter3() {
      this.isFilterFlg3 = true;
      this.filterDialog3 = false;
    },
    clearFilter3(){
      this.titleFilterValue3 = "";
      this.groupFilterValue3 = "";
      this.viewUnreadOnly3 = false;
      this.viewFavoriteOnly3 = false;

      this.isFilterFlg3 = false;
      this.filterDialog3 = false;
    },
  },  
};
</script>

<style scoped>
.do-style {
  background-color: rgb(182, 182, 182)
}
.not-yet-style {
  background-color: rgb(255, 255, 255)
}
.v-data-table >>> .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>