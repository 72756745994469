<template>
  <v-container fluid>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title>
          <v-row dense>
            <v-col cols="1">
              <v-btn color="grey" class="mx-auto black--text" @click="canselPoHomeworkRegist">戻る</v-btn>
            </v-col>
            <v-col cols="10">
              <div class="d-flex justify-center">
                課題登録
              </div>
            </v-col>
            <v-col cols="1">
              
            </v-col>
          </v-row>
        </v-card-title>
        <v-row dense>
          <v-col cols="12" justify="center">
            <div class="mx-10 mt-0">
              <div>対象グループ</div>
              <v-layout wrap>
                {{ selectGroupName }}
              </v-layout>
            </div>
          </v-col>
        </v-row>
        <ValidationProvider name="課題名" rules="required|max:50" v-slot="{ errors }">
          <div class="mx-10 mt-4">
            <v-text-field
            v-model="title"
            prepend-icon="$cet_required"
            label="課題名"
            placeholder=""
            :error-messages="errors[0]"
            outlined
            dense
            :counter="50"
            hide-details="auto"
            ></v-text-field>
          </div>
        </ValidationProvider>

        <ValidationProvider name="説明" rules="required|max:3000" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="body"
              prepend-icon="$cet_required"
              label="説明"
              placeholder=""
              :error-messages="errors[0]"
              outlined
              dense
              :counter="3000"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>

        <ValidationProvider name="配信開始日" rules="required" v-slot="{ errors }">
          <div class="mx-10 mt-0"> 
            <v-text-field
              v-model="openDate"
              prepend-icon="$cet_required"
              label="配信開始日"
              placeholder=""
              type="datetime-local"
              :error-messages="errors[0]"
              outlined
              dense
              hide-details="auto"
              >
              <template v-slot:append-outer>
                <v-btn color="green" class="mr-auto mt-0 black--text" @click="setFastOpen">３分後に設定</v-btn>
              </template>
            </v-text-field>
          </div>
        </ValidationProvider>

        <ValidationProvider name="提出期日" rules="required|datehourAfter:@配信開始日|datehourBefore:@配信終了日" v-slot="{ errors }">
          <div class="mx-10 mt-2"> 
            <v-text-field
              v-model="limitDate"
              prepend-icon="$cet_required"
              label="提出期日"
              placeholder=""
              type="datetime-local"
              :error-messages="errors[0]"
              outlined
              dense
              hide-details="auto"
              ></v-text-field>
          </div>
        </ValidationProvider>

        <ValidationProvider name="配信終了日" rules="required|datehourAfter:@提出期日" v-slot="{ errors }">
          <div class="mx-10 mt-2"> 
            <v-text-field
              v-model="closeDate"
              prepend-icon="$cet_required"
              label="配信終了日"
              placeholder=""
              type="datetime-local"
              :error-messages="errors[0]"
              outlined
              dense
              hide-details="auto"
              ></v-text-field>
          </div>
        </ValidationProvider>
        <div class="mx-10 mt-0">
          <v-row>
            <v-col cols="auto">
              <v-card-actions>
                <v-checkbox class="mt-0" v-model="mailSendFlg" label="公開開始にあわせてメールを配信する" hide-details="auto" />
              </v-card-actions>
            </v-col>
          </v-row>
        </div>
        <v-card-text justify="center">
          <dashboard :uppy="uppy" :props="{
              theme: 'light',
              inline: true,
              width: 3000,
              height: 350,
              disableThumbnailGenerator:true,
              showRemoveButtonAfterComplete:true,
              proudlyDisplayPoweredByUppy:false,
            }" />
            ※添付ファイルは(容量：300MB、個数：10)が上限となっております
        </v-card-text>
        <div class="mx-10 mt-0">
          <v-row dense>
            <v-col cols="12">
              <v-switch class="ml-3 mt-1 mb-2" label="活動記録名を生徒が指定する" v-model="editNameFlg" hide-details="auto"></v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch class="ml-3 mt-1 mb-2" label="活動記録テンプレートを生徒が指定する" v-model="useTemplateFlg" :disabled="true" hide-details="auto"></v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch class="ml-3 mt-1 mb-2" label="活動記録カテゴリーを生徒が指定する" v-model="useCategoryFlg" :disabled="true" hide-details="auto"></v-switch>
            </v-col>
          </v-row>
        </div>
        <v-card-actions>
          <v-btn color="grey" class="mr-auto mt-0 black--text" @click="canselPoHomeworkRegist"  width="45%">戻る</v-btn>
          <v-btn color="accent" class="ml-auto mt-0 black--text" @click="savePoHomeworkRegist" :disabled="invalid" width="45%">登録</v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { Dashboard } from '@uppy/vue'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import ja_JP from '../../../plugins/puppy_lang'
import FirebaseCloudStorage from "../../../plugins/FirebaseCloudStorage";
import { getStorage } from "firebase/storage";
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  // 初期処理宣言
  beforeRouteEnter(to, from, next){next(vm => {vm.setup()})},
  props: [
    'groupId',
    'groupName',
    'paramTitle',
    'paramBody',
    'paramOpenDate',
    'paramLimitDate',
    'paramCloseDate',
    'paramStudentIds',
  ],
  // ファイルアップロード実装 START-------------------------
  components: {
    Dashboard
  },
  computed: {
    uppy() {
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var uppy = new Uppy()
      .use(FirebaseCloudStorage, { storage, storagePath });
      return uppy;
    },
  },
  mounted() {
    this.uppy.on('upload', () => {
      this.$root.loading = true;
    });
    this.uppy.on('upload-success', (file) => {
      // 配列にファイルを追加
      this.fileList.add(file.meta.refId);
    });
    this.uppy.on('complete', () => {
      this.$root.loading = false;
    });
    this.uppy.on('file-removed', (file) => {
      // 配列からファイルを削除
      this.fileList.delete(file.meta.refId);
    });
    // 50MB制限+10ファイル制限
    this.uppy.setOptions({
      debug: true,
      locale: ja_JP,
      autoProceed: true,
      restrictions: {
        maxFileSize: (300 * 1024 * 1024),
        maxNumberOfFiles: 10,
      },
    });
  },
  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },
  // ファイルアップロード実装 END-------------------------
  data() {
    return {
      selectGroupId: "",
      selectGroupName: "",
      title: "",
      body: "",
      openDate: "" ,
      limitDate : "",
      closeDate : "",
      fileList: new Set(),
      editNameFlg: false,
      useTemplateFlg: false,
      useCategoryFlg: false,
      mailSendFlg: true,
      studentIds: [],
      isTargetSelect: false,
    };
  },
  methods: {
    // 初期処理
    async setup() {
      this.defaultDateSet();
      this.selectGroupId = this.groupId;
      this.selectGroupName = this.groupName;
      if(this.paramTitle)this.title = this.paramTitle;
      if(this.paramBody)this.body = this.paramBody;
      if(this.paramOpenDate)this.openDate = this.paramOpenDate;
      if(this.paramLimitDate)this.limitDate = this.paramLimitDate;
      if(this.paramCloseDate)this.closeDate = this.paramCloseDate;
      if(this.paramStudentIds){
        this.studentIds = this.paramStudentIds;
        this.isTargetSelect = true;
      }
    },
    // 課題登録
    async savePoHomeworkRegist() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "poSaveHomeworkRegist",
        groupId : this.selectGroupId,
        groupName : this.selectGroupName,
        title : this.title,
        body : this.body,
        openDate : this.openDate,
        limitDate : this.limitDate,
        closeDate : this.closeDate,
        fileList : Array.from(this.fileList),
        editNameFlg: this.editNameFlg,
        useTemplateFlg: this.useTemplateFlg,
        useCategoryFlg: this.useCategoryFlg,
        mailSendFlg: this.mailSendFlg,
        studentIds : this.studentIds,
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        if (res.data == "success"){
          alert("登録しました。");
          this.$router.push({
            name: "PoHomeworkListForGroup",
            params: {
              groupId: this.selectGroupId,
              groupName: this.selectGroupName,
            }
          });
        } else {
          alert(res.data);
        }
      });
    },
    // 戻るボタン
    canselPoHomeworkRegist(){
      this.$router.push({
        name: "PoGroupSelect",
      });
    },
    // 日付初期設定
    defaultDateSet() {
      var dt = new Date() ;
      const dtFrom = new Date(dt.setDate(dt.getDate() + 1));
      this.openDate = this.formatDate(dtFrom)  + "T07:00";
      
      dt = new Date() ;
      const dtTo =new Date( dt.setMonth(dt.getMonth()+1)) ;
      this.limitDate = this.formatDate(dtTo) + "T23:59";
      this.closeDate = this.formatDate(dtTo) + "T23:59";
      console.log("1月後",this.closeDate);
    },
    setFastOpen() {
      var dt = new Date() ;
      dt.setMinutes(dt.getMinutes() + 3);
      this.openDate = format(dt, "yyyy-MM-dd'T'HH:mm", { locale: ja });
    },
    // 日付書式設定
    formatDate(dt){
      var y = dt.getFullYear();
      var m = ('00' + (dt.getMonth()+1)).slice(-2);
      var d = ('00' + dt.getDate()).slice(-2);
      return (y + '-' + m + '-' + d);
    },
  },
};
</script>