<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          傷病の記録・出力
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="8" justify="center">
        <v-card class="mx-auto text-center" color="white">
          <ValidationObserver ref="obs" v-slot="{ invalid }">
          <v-row dense>
            <v-col cols="4" justify="center">
              <ValidationProvider name="開始日" rules="required" v-slot="{ errors }">
                <v-text-field
                    v-model="fromDate"
                    label="開始日"
                    placeholder=""
                    :error-messages="errors[0]"
                    type="date"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="4" justify="center">
              <ValidationProvider name="終了日" rules="required" v-slot="{ errors }">
                <v-text-field
                    v-model="toDate"
                    label="終了日"
                    placeholder=""
                    :error-messages="errors[0]"
                    type="date"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="2" justify="center">
              <v-btn color="accent" class="ml-auto mt-0 black--text" @click="onSearch" :disabled="invalid" width="90%">検索</v-btn>
            </v-col>
            <v-col cols="2" justify="center">
              <v-btn color="green" class="ml-auto mt-0 black--text" @click="onExport" :disabled="invalid || isExporting" :loading="isExporting" width="90%">Excel出力<v-icon right dark>mdi-file-excel</v-icon></v-btn>
            </v-col>
          </v-row>
          </ValidationObserver>
        </v-card> 
      </v-col>
      <v-col cols="2" justify="center">
        <div align="left">
          <v-btn color="accent" class="black--text" @click="toHealthRoomReception" width="90%">バーコード受付</v-btn>
        </div>
      </v-col>
      <v-col cols="2" justify="center">
        <div align="left">
          <v-btn color="accent" class="black--text" @click="toHealthRoomReceptionRegist" width="90%">生徒を検索して受付</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="recodes"
          :items-per-page="-1"
          hide-default-footer
          ref="table"
          dense
          disable-sort
          group-by="processingDate"
          :loading="isListLoding" loading-text="読込中"
        >
          <template #[`group.header`]="{ group, headers, toggle, isOpen}">
            <td @click="toggle" :colspan="headers.length">
              <v-tooltip right v-if="isOpen">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </template>
                <span>明細行をたたむ</span>
              </v-tooltip>
              <v-tooltip right v-if="!isOpen">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>明細行を展開する</span>
              </v-tooltip>
              {{ group }}
            </td>
          </template>
          <template #[`item.KBKGCS`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">
              {{ item.kateiNm }}/{{ item.buNm }}/{{ item.kaNm }}
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              {{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}
            </v-col></v-row>
          </template>
          <template #[`item.userNmKana`]="{ item }">
            {{ item.userNm }}({{ item.userNmKana }})
          </template>

          <!-- 退室ボタン -->
          <template #[`item.checkOut`]="{ item }">
            <v-btn large v-if="!item.checkOutFlg" class="blue--text" @click="checkOut(item)">
              <span>退室</span>

              <v-icon x-large right>
                mdi-location-exit
              </v-icon>
            </v-btn>
          </template>
          <!-- 傷病入力 -->
          <template #[`item.edit`]="{ item }">
            <v-tooltip bottom v-if="!isOpen">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon outlined v-bind="attrs" v-on="on" @click="openDetailDialog(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>傷病情報を記録する</span>
            </v-tooltip>
          </template>
          <!-- 入力状況 -->
          <template #[`item.inputFlg`]="{ item }">
            <v-tooltip bottom v-if="item.inputFlg">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="ml-2" >mdi-check-outline</v-icon>
              </template>
              <span>記録済</span>
            </v-tooltip>
          </template>

          <!-- 削除 -->
          <template #[`item.delete`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon outlined v-bind="attrs" v-on="on" @click="onClickDeleteItem(item)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>来室情報を削除する</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- 活動記録詳細モーダル -->
    <v-dialog
      v-model="detailDialog"
      scrollable
      persistent
    >
      <v-card>
        <v-card-text class="text-pre-wrap">
          <ValidationObserver ref="obs" v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-toolbar
                  dark
                  color="primary"
                >
                  <v-btn
                    icon
                    dark
                    @click="closeDetailDialog()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>{{ dialogUserNm }}の傷病記録</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" justify="center">
                <!-- 入室時刻 -->
                <v-menu
                  ref="menuRef"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="dialogCheckInTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider name="入室時刻" rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model="dialogCheckInTime"
                    label="入室時刻"
                    placeholder="入室時刻"
                    :error-messages="errors[0]"
                    readonly
                    outlined
                    dense
                    clearable
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-time-picker
                  v-if="menu1"
                  v-model="dialogCheckInTime"
                  :allowed-hours="allowedHours"
                  :allowed-minutes="allowedStep"
                  full-width
                  @click:minute="$refs.menuRef.save(dialogCheckInTime)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" justify="center">
                <!-- 退室時刻 -->
                <v-menu
                  ref="menuRef2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="dialogCheckOutTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dialogCheckOutTime"
                    label="退室時刻"
                    placeholder="退室時刻"
                    readonly
                    outlined
                    dense
                    clearable
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="dialogCheckOutTime"
                  :allowed-hours="allowedHours"
                  :allowed-minutes="allowedStep"
                  full-width
                  @click:minute="$refs.menuRef2.save(dialogCheckOutTime)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" justify="center">
                <ValidationProvider name="主訴傷病名" rules="max:250" v-slot="{ errors }">
                  <v-text-field
                    v-model="dialogSyoubyouNm"
                    label="主訴傷病名"
                    placeholder=""
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :counter="250"
                    hide-details="auto"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" justify="center">
                <ValidationProvider name="原因・場所" rules="max:250" v-slot="{ errors }">
                  <v-text-field
                    v-model="dialogGeninnBasyo"
                    label="原因・場所"
                    placeholder=""
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :counter="250"
                    hide-details="auto"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" justify="center">
                <ValidationProvider name="処置" rules="max:250" v-slot="{ errors }">
                  <v-text-field
                    v-model="dialogSyoti"
                    label="処置"
                    placeholder=""
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :counter="250"
                    hide-details="auto"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" justify="center">
                <ValidationProvider name="備考" rules="max:250" v-slot="{ errors }">
                  <v-text-field
                    v-model="dialogBiko"
                    label="備考"
                    placeholder=""
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :counter="250"
                    hide-details="auto"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-btn color="accent" class="ml-auto mt-0 black--text" @click="saveHealthRoomRecodeEdit" :disabled="invalid" width="100%">更新</v-btn>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';
import Enumerable from "linq";
import { saveAsCommon } from '../../common';
export default {
  beforeRouteEnter(to, from, next){next(vm => {vm.setup()})},
  data() {
    return {
      fromDate: "",
      toDate: "",
      headers: [
        { text: "受付日時", value: "processingDate" },
        { text: "課程/部/科/学年/学級/出席番号", value: "KBKGCS" },
        { text: "生徒氏名(ｶﾅ)", value: "userNmKana" },
        { text: "入退室状況", value: "status" },
        { text: "入室時刻", value: "checkInTime" },
        { text: "退室時刻", value: "checkOutTime" },
        { text: "退室", value: "checkOut" },
        { text: "傷病入力", value: "edit" },
        { text: "入力状況", value: "inputFlg" },
        { text: "削除", value: "delete" },
      ],
      recodes: [],
      isListLoding: false,
      isExporting: false,

      // 傷病記録ダイアログ表示用
      detailDialog: false,
      dialogUserId: "",
      dialogProcessingDate: "",
      dialogUserNm: "",
      // 傷病入力項目
      dialogCheckInTime: "",
      dialogCheckOutTime: "",
      dialogSyoubyouNm: "",
      dialogGeninnBasyo: "",
      dialogSyoti: "",
      dialogBiko: "",

      menu1: false,
      menu2: false,
    };
  },
  methods: {
    // 初期処理
    async setup() {
      // 日付の初期設定
      this.defaultDateSet();
      // 検索
      await this.onSearch();
    },
    // 検索条件日付の初期設定１
    defaultDateSet() {
      this.fromDate = this.formatDate(new Date());
      this.toDate = this.formatDate(new Date());
    },
    // 検索条件日付の初期設定２
    formatDate(dt){
      var y = dt.getFullYear();
      var m = ('00' + (dt.getMonth()+1)).slice(-2);
      var d = ('00' + dt.getDate()).slice(-2);
      return (y + '-' + m + '-' + d);
    },
    // 検索処理
    async onSearch() {

      const fromDate = parse(this.fromDate, 'yyyy-MM-dd', new Date());
      const toDate = parse(this.toDate, 'yyyy-MM-dd', new Date());
      // 日付の前後チェック
      if(fromDate.getTime() > toDate.getTime()){
        alert('開始日は終了日より未来日で指定してください');
        return;
      }

      const data = {
        funcName: "searchHealthRoomRecodeEdit",
        fromDate: this.fromDate,
        toDate: this.toDate,
      };
      this.recodes = [];

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((ret) => {
        var buff = [];
        ret.data.forEach((recode) => {
          buff.push(recode);
        });
        this.recodes = buff;
        this.isListLoding = false;
      });
    },

    // Excel出力処理
    async onExport() {

      const fromDate = parse(this.fromDate, 'yyyy-MM-dd', new Date());
      const toDate = parse(this.toDate, 'yyyy-MM-dd', new Date());
      // 日付の前後チェック
      if(fromDate.getTime() > toDate.getTime()){
        alert('開始日は終了日より未来日で指定してください');
        return;
      }

      this.isExporting = true;

      const data = {
        funcName: "exportHealthRoomRecodeEdit",
        fromDate: this.fromDate,
        toDate: this.toDate,
      };

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((ret) => {
        if(ret.data.message){
          alert(ret.data.message);
        } else {
          saveAsCommon(ret.data.downloadUrl, ret.data.fileName);
        }
      });
      this.isExporting = false;
    },
    // 退室処理
    async checkOut(item) {
      
      var processingDate = parse(item.processingDateStr, 'yyyyMMdd', new Date());
      var processingDateStr = format(processingDate, 'yyyy-MM-dd', { locale: ja });
      
      const data = {
        funcName: "checkOutHealthRoomReceptionRegist",
        userId: item.userId,
        receptionDate: processingDateStr,
      };

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((ret) => {
        item.status = "退室済";
        item.checkOutTime = ret.data;
        item.checkOutFlg = true;
      });
    },
    // 傷病記録モーダルを開く
    async openDetailDialog(item) {
      // 更新キー
      this.dialogUserId = item.userId;
      this.dialogProcessingDate = item.processingDateStr;
      this.dialogUserNm = item.userNm;
      // 最新情報取得
      this.dialogCheckInTime = "";
      this.dialogCheckOutTime = "";
      this.dialogSyoubyouNm = "";
      this.dialogGeninnBasyo = "";
      this.dialogSyoti = "";
      this.dialogBiko = "";

      const data = {
        funcName: "loadHealthRoomRecodeEdit",
        userId: this.dialogUserId,
        processingDate: this.dialogProcessingDate,
      };

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((ret) => {
        this.dialogCheckInTime = ret.data.checkInTime;
        this.dialogCheckOutTime = ret.data.checkOutTime;
        this.dialogSyoubyouNm = ret.data.syoubyouNm;
        this.dialogGeninnBasyo = ret.data.geninnBasyo;
        this.dialogSyoti = ret.data.syoti;
        this.dialogBiko = ret.data.biko;
      });

      this.detailDialog = true;
    },
    // 傷病記録の更新
    async saveHealthRoomRecodeEdit(){

      if(this.dialogCheckOutTime && this.dialogCheckOutTime < this.dialogCheckInTime){
        alert("退室時刻は入室時刻の後に設定してください。");
        return;
      }

      const data = {
        funcName: "saveHealthRoomRecodeEdit",
        userId: this.dialogUserId,
        processingDate: this.dialogProcessingDate,
        checkInTime: this.dialogCheckInTime,
        checkOutTime: this.dialogCheckOutTime,
        syoubyouNm: this.dialogSyoubyouNm,
        geninnBasyo: this.dialogGeninnBasyo,
        syoti: this.dialogSyoti,
        biko: this.dialogBiko,
      };

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((ret) => {
        for(let row of this.recodes){
          if(row.processingDateStr == this.dialogProcessingDate && row.userId == this.dialogUserId){
            row.inputFlg = true;
            row.checkInTime = ret.data.checkInTime;
            row.checkOutTime = ret.data.checkOutTime;
            row.checkOutFlg = ret.data.checkOutFlg;
          }
        }
        this.detailDialog = false;
      });
    },
    // 傷病記録モーダルを閉じる
    closeDetailDialog() {
      this.detailDialog = false;
    },
    async onClickDeleteItem(item) {
      var result = confirm("受付情報を削除してよろしいですか？");
      if (!result) {
        return;
      }

      const data = {
        funcName: "deleteHealthRoomRecodeEdit",
        userId: item.userId,
        processingDate: item.processingDateStr,
      };
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((ret) => {
        if(ret.data == "success"){
          // 明細から削除
          this.recodes = Enumerable.from(this.recodes).where(x => { return !(x.processingDateStr == item.processingDateStr && x.userId == item.userId) }).toArray();
        }
      });
    },
    // 保健室受付(バーコード)画面へ
    toHealthRoomReception() {
      this.$router.push({
        name: "HealthRoomReception",
      });
    },
    // 保健室受付(検索)画面へ
    toHealthRoomReceptionRegist() {
      this.$router.push({
        name: "HealthRoomReceptionRegist",
      });
    },
  },
};
</script>

<style>
.calender-text-box-wrapper{
}
.calender-text-box {
    display: none;
}
.vdp-datepicker__calendar {
    width: 100% !important;
}
</style>