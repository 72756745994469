<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          出欠履歴確認(過去30日分)
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="syukketuList"
          :items-per-page="-1"
          dense
          disable-sort
          :loading="isSyukketuLoding" loading-text="読込中"
        >
          <!-- 日付 -->
          <template #[`item.syukketuDate`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">{{ item.syukketuDate }}</v-col></v-row>
          </template>
          <!-- 出欠区分 -->
          <template #[`item.syukketuKbnName`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">{{ item.syukketuKbnName }}</v-col></v-row>
          </template>
          <!-- 備考 -->
          <template #[`item.biko`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">{{ item.biko }}</v-col></v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['id'],
  data: () => ({
    isViewSotai: false,
      headers: [
        { text: "日付", value: "syukketuDate" },
        { text: "出欠区分", value: "syukketuKbnName"},
        { text: "備考", value: "biko" },
      ],
      syukketuList: [],
      isSyukketuLoding: false,
  }),
  // 初期表示時データ読み込み
  async created() {
    console.log(this.$refs);
    // ローディング開始
    this.$root.loading = true;
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");

    const data = {
        funcName: "loadAttendancePastView",
        studentId: this.id
    };

    this.isSyukketuLoding = true;
    await schoolAidHomeLogined(data).then((res) => {
        console.log(res);
        res.data.forEach((syukketu) => {
          this.syukketuList.push(syukketu);
        });
        
        this.isSyukketuLoding = false;
      }).catch((e) =>{
        console.log(e);
        this.$root.loading = false;
    });

    // ローディング終了
    this.$root.loading = false;
  },
};
</script>
