import { render, staticRenderFns } from "./PoHomeworkListForSelf.vue?vue&type=template&id=edff401e"
import script from "./PoHomeworkListForSelf.vue?vue&type=script&lang=js"
export * from "./PoHomeworkListForSelf.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports