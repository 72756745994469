<template>
  <v-container fluid>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
         <v-card-title class="d-flex justify-center pa-0 mt-6"
           >グループ登録</v-card-title
         >
         <ValidationProvider name="グループ名" rules="required|max:50" v-slot="{ errors }">
         <div class="mx-10" >
             <v-text-field
              v-model="groupName"
              label="グループ名"
              placeholder=""
              prepend-icon="$cet_required"
              :error-messages="errors[0]"
              outlined
              dense
              :counter="50"
              hide-details="auto"
              ></v-text-field>
          </div>
          </ValidationProvider>

          <v-card-actions>
          <v-btn color="grey" class="mx-auto black--text" @click="onClickGroupList" width="49%" 
            >戻る</v-btn
          >     
          <v-btn color="accent" class="mx-auto black--text" @click="groupsave" :disabled="invalid"  width="49%"
            >登録</v-btn
          >
        </v-card-actions>  
    </v-card>
   </ValidationObserver>
  
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  data() {
    return {
      groupName : "",
    };
  },

  methods: {
    async groupsave() {
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1"; 
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "saveGroupRegist",
        groupName: this.groupName,
      };
      
      await schoolAidHomeLogined(data).then((res) => {
        this.$root.loading = false;
        if (res.data == "success") {
          console.log(res.data);
          alert("登録しました。");
          console.log("list画面へ")
          this.$router.push({
            name: "GroupList",
          }); 
        } else {          
          alert("同一グループ名は既に存在します");
        }
      });        
    },

    onClickGroupList() {
      this.$router.push({
        name: "GroupList",
    })},
    
  },
};
</script>