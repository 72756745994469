<template>
  <v-container fluid>
    <!-- ヘッダー -->
    <v-row dense>
      <v-col cols="6" sm="2" md="1" lg="1" xl="1">
        <div align="left">
          <v-btn color="grey" small class="black--text" @click="toBack">前の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="2" md="1" lg="1" xl="1">
        <div align="right">
          <v-btn color="grey" small class="black--text" @click="toForward">次の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="8" md="10" lg="10" xl="10">
        <v-card class="mx-auto text-center black--text" outlined>
          <v-row dense>
            <v-col cols="8" sm="8" md="10" lg="10" xl="10">
              <div align="center">
                {{ yyyymmddDisp }}の早退確認
              </div>
            </v-col>
            <v-col cols="4" sm="4" md="2" lg="2" xl="2">
              <div align="right">
                <v-btn color="grey" small class="black--text" @click="calendar">日付選択</v-btn>

              </div>
            </v-col>
          </v-row>
          <Datepicker
            ref="picker"
            :monday-first="true"
            wrapper-class="calender-text-box-wrapper"
            input-class="calender-text-box"
            placeholder=""
            :language="language"
            @selected=dateSelected />
        </v-card>
      </v-col>
    </v-row>
    
    <!-- 検索条件 and 並び替え条件(モバイル用) -->
    <v-row dense class="d-sm-none">
      <v-col class="d-flex justify-end mr-1">
        <v-btn color="green" @click="filterDialog1 = true" small>
          <v-icon right dark v-if="isFilterFlg1">mdi-filter</v-icon>
          <v-icon right dark v-if="!isFilterFlg1">mdi-filter-outline</v-icon>
          フィルタ
        </v-btn>
      </v-col>
    </v-row>
    
    <!-- 検索条件 and 並び替え条件(PC用) -->
    <v-row dense class="hidden-xs-only">
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <!-- グループフィルタ -->
        <v-select
          v-model="groupFilterValue"
          label="グループ指定"
          :items="groupList"
          item-text="groupName"
          item-value="filterItem"
          outlined
          dense
          clearable
          multiple
          chips
          deletable-chips
          hide-details="auto"
          background-color="white"
        ></v-select>
      </v-col>
      <v-col cols="0" sm="3" md="3" lg="3" xl="3">
      </v-col>

      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-select
          label="並び順"
          :items="sortKbnList"
          item-text="text"
          item-value="value"
          v-model="sortKbn"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-resize="updHeight">
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="members"
          :items-per-page="-1"
          :footer-props="{'disable-items-per-page': true}"
          dense
          disable-sort
          :height="tableHeight"
          :fixed-header="true"
          :loading="isListLoding" loading-text="読込中"
          mobile-breakpoint="0"
        >
          <!-- 最終更新日時 -->
          <template v-slot:top>
            <v-row dense>
              <v-col cols="12" class="d-flex text-caption justify-end">
                <div>最終更新時間：{{ lastReloadTime }}(15分毎自動更新)</div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </template>
          
          <!-- ラベル：生徒氏名＋保護者連絡日時＋登録者名 -->
          <template #[`header.name`]="{}"> 
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                生徒氏名
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                保護者連絡日時
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                登録者名
              </v-col>
            </v-row>
          </template>

          <!-- ラベル：KBK学年学級 -->
          <template #[`header.filterItem`]="{}"> 
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                課程/部/科
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                学年/学級/出席番号
              </v-col>
            </v-row>
          </template>

          <!-- 生徒氏名と保護者連絡日時とモバイル用まとめセル -->
          <template #[`item.name`]="{ item }">
            <v-row no-gutters class="hidden-xs-only">
              <v-col cols="12" justify="center">
                <v-tooltip right v-if="item.mailAddressAuthFlg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ml-2" v-bind="attrs" v-on="on">mdi-email-outline</v-icon>
                  </template>
                  <span>メールアドレス設定済</span>
                </v-tooltip>
                <v-tooltip right v-if="!item.mailAddressAuthFlg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ml-2" v-bind="attrs" v-on="on">mdi-phone</v-icon>
                  </template>
                  <span>メールアドレス未設定</span>
                </v-tooltip>
                {{ item.name }}
              </v-col>
            </v-row>
            <v-row no-gutters class="hidden-xs-only">
              <v-col cols="12" justify="center">
                {{ item.parentUpdDate }}
              </v-col>
            </v-row>
            <v-row no-gutters class="hidden-xs-only">
              <v-col cols="12" justify="center">
                <span v-if="item.createUserFlg" class="text-caption red--text">
                  {{ item.createUserNm }}
                </span>
                <span v-if="!item.createUserFlg" class="text-caption black--text">
                  {{ item.createUserNm }}
                </span>
              </v-col>
            </v-row>

            <!-- モバイル用まとめ -->
            <v-row dense class="d-sm-none">
              <v-col cols="12" justify="center">
                <!-- １行目（生徒氏名＋KBK学年学級出席番号） -->
                <v-row no-gutters>
                  <v-col cols="5" class="d-flex text-subtitle-1">
                    {{ item.name }}
                  </v-col>
                  <v-col cols="7" class="d-flex justify-end text-caption">
                    {{ item.buNm }}/{{ item.kaNm }}/{{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}
                  </v-col>
                </v-row>
                <!-- ２行目（早退事由） -->
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex text-caption">
                    {{ item.leavingReason }}
                  </v-col>
                </v-row>
                <!-- ３行目（登録者） -->
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex justify-end text-caption">
                    登録者：
                    <span v-if="item.createUserFlg" class="text-caption red--text">
                      {{ item.createUserNm }}
                    </span>
                    <span v-if="!item.createUserFlg" class="text-caption black--text">
                      {{ item.createUserNm }}
                    </span>
                  </v-col>
                </v-row>
                <!-- ４行目（下校開始予定時刻＋最終更新者） -->
                <v-row no-gutters>
                  <v-col cols="4" class="d-flex text-caption">
                    予定時刻：{{ item.leavingTime }}
                  </v-col>
                  <v-col cols="8" class="d-flex justify-end text-caption">
                    最終更新者：
                    <span v-if="item.updateUserKbn == '1'" class="red--text">{{ item.updateUserName }}</span>
                    <span v-else>{{ item.updateUserName }}</span>
                  </v-col>
                </v-row>
                <!-- ５行目（保護者への連絡状況＋保護者確認状況） -->
                <v-row no-gutters>
                  <v-col cols="8" class="d-flex text-caption">
                    保護者連絡：{{ item.comStatusName }}
                  </v-col>
                  <v-col cols="4" class="d-flex justify-end text-caption">
                    保護者確認：{{ item.unreadFlg }}
                  </v-col>
                </v-row>
                <!-- ６行目（ボタン類） -->
                <v-row no-gutters>
                  <v-col cols="3" class="d-flex text-caption">
                    <v-btn color="accent" small class="black--text" @click="onClickEditItem(item)" :disabled="item.pastLockFlg">返信</v-btn>
                  </v-col>
                  <v-col cols="3" class="d-flex text-caption">
                    <v-btn color="accent" small class="black--text text-pre-wrap" style="height: auto;" @click="onClickGatePassageItem(item)" :disabled="(isTodayFlg)?item.gatePassageFlg:false">{{ btnGateText }}</v-btn>
                  </v-col>
                  <v-col cols="2" class="d-flex text-caption">
                    {{ item.gatePassageTime }}
                  </v-col>
                  <v-col cols="3" class="d-flex text-caption">
                    <v-btn color="accent" small class="black--text" @click="onClickApprovalItem(item)" :disabled="item.pastLockFlg">確定</v-btn>
                  </v-col>
                  <v-col cols="1" class="d-flex text-caption">
                    <v-icon class="ml-2" v-if="item.approvalFlg">mdi-check-outline</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
          
          <!-- KBK学年学級 -->
          <template #[`item.filterItem`]="{ item }">
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                {{ item.kateiNm }}/{{ item.buNm }}/{{ item.kaNm }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                {{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}
              </v-col>
            </v-row>
          </template>

          <!-- 早退関連情報 -->
          <template #[`item.sotaiInfo1`]="{ item }">
            <v-row no-gutters>
              <v-col cols="6" justify="center">
                早退事由：{{ item.leavingReason }}
              </v-col>
              <v-col cols="6" justify="center">
                保護者確認：{{ item.unreadFlg }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" justify="center">
                下校開始予定時刻：{{ item.leavingTime }}
              </v-col>
              <v-col cols="6" justify="center">
                最終更新者：
                <span v-if="item.updateUserKbn == '1'" class="red--text">{{ item.updateUserName }}</span>
                <span v-else>{{ item.updateUserName }}</span>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" justify="center">
                保護者への連絡：{{ item.comStatusName }}
              </v-col>
              <v-col cols="6" justify="center">
                <div class="red--text text-caption" v-if="item.cancelFlg == true">取り消し済</div>
              </v-col>
            </v-row>
          </template>

          <!-- 返信ボタン -->
          <template #[`item.action1`]="{ item }">
            <v-btn color="accent" class="black--text" @click="onClickEditItem(item)" :disabled="item.pastLockFlg">返信</v-btn>
          </template>

          <!-- 事務室確認ボタン＋通用門通過時間 -->
          <template #[`item.action2`]="{ item }">
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                <v-btn color="accent" class="black--text text-pre-wrap" style="height: auto;" @click="onClickGatePassageItem(item)" :disabled="(isTodayFlg)?item.gatePassageFlg:flase">{{ btnGateText }}</v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                {{ item.gatePassageTime }}
              </v-col>
            </v-row>
          </template>
          
          <!-- 確定ボタン＋確定状況 -->
          <template #[`item.action3`]="{ item }">
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                <v-btn color="accent" class="black--text" @click="onClickApprovalItem(item)" :disabled="item.pastLockFlg">確定</v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                <v-icon class="ml-2" v-if="item.approvalFlg">mdi-check-outline</v-icon>
              </v-col>
            </v-row>
          </template>

          <!-- 帰宅状況 -->
          <template #[`item.arrivalStatusFlg`]="{ item }">
            <v-icon class="ml-2" v-if="item.arrivalStatusFlg">mdi-check-outline</v-icon>
            <span v-if="item.arrivalStatusFlg">{{ item.arrivalTime }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- 早退確定モーダル -->
    <v-dialog
      v-model="leavingApprovalDialog"
      max-width="500"
      persistent
    >
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="500"
          color="#fff"
        >
          <v-card-title class="d-flex justify-center pa-0 mt-6" white-space="pre">
            <p>早退確定({{ approvalStudentNm }})</p>
          </v-card-title>

          <v-card-text class="d-flex justify-center flex-column">
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider
                  name="確定出欠"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                      label="確定出欠"
                      item-text="name"
                      item-value="code"
                      v-model="selectedMain"
                      prepend-icon="$cet_required"
                      :items="selectMain"
                      :error-messages="errors[0]"
                      outlined
                      @change="changeMain"
                      hide-details="auto"
                    ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider
                  name="確定理由"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                      label="確定理由"
                      item-text="name"
                      item-value="code"
                      prepend-icon="$cet_required"
                      v-model="approvalKbn"
                      :items="selectSubFilter"
                      :error-messages="errors[0]"
                      outlined
                      @change="changeSub"
                      hide-details="auto"
                    ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider v-if="approvalKbn.endsWith('99')" name="その他理由" rules="required|max:500" v-slot="{ errors }">
                  <v-text-field
                    v-model="approvalText"
                    label="その他理由"
                    placeholder="その他理由"
                    prepend-icon="$cet_required"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :counter="500"
                    v-if="approvalKbn.endsWith('99')"
                    hide-details="auto"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="grey" class="black--text" @click="leavingApprovalDialog = false" width="49%">
              閉じる
            </v-btn>
            <v-btn color="accent" class="black--text" @click="sotaiApprovalSave()" :disabled="invalid" width="49%">
              確定
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>

    <!-- フィルタモーダル -->
    <v-dialog
      v-model="filterDialog1"
      persistent
      max-width="600"
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="600"
        color="#fff"
      >
        <v-card-title class="d-flex pt-3 justify-center" white-space="pre">
          <v-row dense>
            <v-col dense cols="4" justify="center">
              <v-btn color="grey" class="black--text" @click="clearFilter1" width="90%">
                クリア
              </v-btn>
            </v-col>
            <v-col dense cols="4" justify="center">
              <p>フィルタ</p>
            </v-col>
            <v-col dense cols="4" justify="center">
              <v-btn color="accent" class="black--text" @click="doFilter1" width="90%">
                適用
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col cols="12">
              <!-- グループフィルタ -->
              <v-select
                v-model="groupFilterValue"
                label="グループ指定"
                :items="groupList"
                item-text="groupName"
                item-value="filterItem"
                outlined
                dense
                clearable
                multiple
                chips
                deletable-chips
                hide-details="auto"
                background-color="white"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-select
                label="並び順"
                :items="sortKbnList"
                item-text="text"
                item-value="value"
                v-model="sortKbn"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';
import Enumerable from "linq";
export default {
  props: ['param_yyyymmdd'],
  watch: {
    yyyymmdd: async function(newVal, oldVal) {
      // 本日判定
      var today = format(new Date(), 'yyyy-MM-dd', { locale: ja });
      console.log(today, newVal, oldVal)
      this.isTodayFlg = (today == newVal)?true:false;
      await this.reload();
    },
    // ソート順変更時のソート
    sortKbn: async function(newVal, oldVal) {
      console.log(newVal, oldVal);
      if(newVal == "1") {
        this.members = Enumerable.from(this.membersBase).orderBy((x) => { return x.orderNoKBKGCS }).toArray();
      } else if(newVal == "2") {
        this.members = Enumerable.from(this.membersBase).orderByDescending((x) => { return x.parentUpdDateTime }).toArray();
      } else if(newVal == "3") {
        this.members = Enumerable.from(this.membersBase).orderByDescending((x) => { return x.orderNoKBKGCS }).toArray();
      } else if(newVal == "4") {
        this.members = Enumerable.from(this.membersBase).orderBy((x) => { return x.parentUpdDateTime }).toArray();
      }
      this.$store.dispatch('setSortCondition_LeavingConfirm', newVal);
    },
    groupFilterValue: function(newVal) {this.$store.dispatch('setSearchConditionSyukketuGroup', newVal)},
  },
  data() {
    return {
      // フィルタ用
      filterDialog1: false,
      isFilterFlg1: false,

      yyyymmdd: "",
      yyyymmddDisp: "",
      isTodayFlg: false,
      headers: [
        { text: "生徒氏名 連絡日時", value: "name", class: "hidden-xs-only", width: "13%" },
        { text: "課程/部/科 学年/学級/出席番号", value: "filterItem", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "13%", filter: this.groupFilter },
        { text: "早退情報", value: "sotaiInfo1", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "", value: "action1", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "5%" },
        { text: "通用門通過", value: "action2", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "8%" },
        { text: "早退確定", value: "action3", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "8%" },
        { text: "生徒帰宅連絡", value: "arrivalStatusFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "8%" },
      ],
      membersBase: [],
      members: [],
      // ソート用区分
      sortKbn: "1",
      sortKbnList: [
        {value:"1", text:"学年/学級/出席番号(昇順)"},
        {value:"2", text:"保護者連絡日時(降順)"},
        {value:"3", text:"学年/学級/出席番号(降順)"},
        {value:"4", text:"保護者連絡日時(昇順)"},
      ],
      isListLoding: false,
      language:{
        language: 'Japanese',
        months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthsAbbr: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        days: ['日', '月', '火', '水', '木', '金', '土'],
        yearSuffix: '年',
        ymd: true,
      },
      groupFilterValue: [],
      groupList: [],
      btnGateText: "事務室\n確認",

      // 早退確定用
      approvalStudentId: "",
      approvalStudentNm: "",
      leavingApprovalDialog: false,
      selectMain: [],
      selectedMain: "", //初期値
      selectSub: [],
      selectSubFilter: [],
      approvalKbn: "", //初期値
      approvalText: "", //初期値

      tableHeight: 0,  // v-data-table 高さ

      intervalid1:"",
      lastReloadTime:"",
    };
  },
  // 初期表示時データ読み込み
  async created() {
    var date = parse(this.param_yyyymmdd, 'yyyy-MM-dd', new Date());
    this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
    this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    
    this.sortKbn = this.$store.state.sortCondition_LeavingConfirm;

    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    schoolAidHomeLogined({funcName: "getSyukketuGroup"}).then((ret) => {
      this.groupList = ret.data;
      this.groupFilterValue = this.$store.state.searchConditionSyukketuGroup;
    });
    
    await this.reload();
    
    // 定期更新の設定
    this.intervalid1 = setInterval(() => {
      this.reload();
    }, 900000);
  },
  beforeDestroy () {
    // 定期更新の設定破棄
    clearInterval(this.intervalid1)
  },
  methods: {
    // グループフィルタ
    groupFilter(value) {
      if (this.groupFilterValue.length == 0) {
        return true;
      }
      return this.groupFilterValue.includes(value.toString());
    },
    // データピッカー開閉
    calendar() {
        if(!this.$refs.picker.isOpen) {
            this.$refs.picker.$el.querySelector("input").focus();
            this.$refs.picker.showCalendar();
        } else {
            this.$refs.picker.close();
        }
    },
    // データピッカー日付選択
    dateSelected(selectedDate) {
        this.yyyymmdd = format(selectedDate, 'yyyy-MM-dd', { locale: ja });
        this.yyyymmddDisp = format(selectedDate, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 前の日へボタン
    toBack() {
      var date = parse(this.yyyymmdd, 'yyyy-MM-dd', new Date());
      date.setDate(date.getDate() - 1);
      this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 次の日へボタン
    toForward() {
      var date = parse(this.yyyymmdd, 'yyyy-MM-dd', new Date());
      date.setDate(date.getDate() + 1);
      this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 早退登録
    onClickEditItem(item) {
      this.$router.push({
        name: "LeavingRegist",
        params: {
          studentId:item.loginId,
          studentName:item.name,
          parentMailAuthFlg:item.mailAddressAuthFlgParent,
          beforeName:"LeavingConfirm",
          param_yyyymmdd: this.yyyymmdd,
        }
      });
    },
    // 早退確定
    async onClickApprovalItem(item) {
      // ローディング開始
      this.$root.loading = true;
      this.approvalStudentId = item.loginId;
      this.approvalStudentNm = item.name;
      this.leavingApprovalDialog = true;

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      const data = {
        funcName: "loadLeavingApproval",
        yyyymmdd: this.yyyymmdd,
        studentId: this.approvalStudentId
      };
      await schoolAidHomeLogined(data).then((res) => {
        this.$root.loading = false;
        this.leavingReason = res.data.leavingReason;
        this.leavingTime = res.data.leavingTime;

        this.selectMain = [{code: "", name: "", orderNo: "", yobi1: ""}].concat(res.data.selectMain);
        this.selectSub = res.data.selectSub;
        this.selectedMain = res.data.approvalKbn.substring(0, 2);
        if(res.data.approvalKbn != ""){
          this.selectSubFilter = Enumerable.from(this.selectSub).where(i => i.code.substring(0, 2) == this.selectedMain).toArray();
        }

        this.approvalKbn = res.data.approvalKbn;
        this.approvalText = res.data.approvalText;
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
    },
    // 早退設定保存
    async sotaiApprovalSave() {
      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var data = {
        funcName: "saveLeavingApproval",
        yyyymmdd: this.yyyymmdd,
        studentId: this.approvalStudentId,
        approvalKbn: this.approvalKbn,
        approvalText: this.approvalText,
      };
      await schoolAidHomeLogined(data).then((res) => {
        this.$root.loading = false;
        console.log(res.data);
        alert("登録しました");

        // reloadせずにapprovalFlgの画面更新
        this.members.forEach((doc) => {
          if (doc.loginId === this.approvalStudentId) {
            doc.approvalFlg = res.data.approvalFlg;
          }
        });
      }).catch((e) =>{
        this.$root.loading = false;
        console.log(e.message);
        this.error = e.message;
      });

      this.leavingApprovalDialog = false;
    },
    async changeMain() {
      console.log('changeMain');
      this.approvalKbn = "";
      this.approvalText = "";
      this.selectSubFilter = Enumerable.from(this.selectSub).where(i => i.code.substring(0, 2) == this.selectedMain).toArray();
    },
    async changeSub() {
      console.log('changeSub');
      if(!this.approvalKbn.endsWith('99')){
        this.approvalText = "";
      }
    },
    // ページ再読込
    async reload() {
      this.members = [];

      var param = {
        funcName: "loadLeavingConfirm",
        yyyymmdd: this.yyyymmdd
      }
      var comStatusNames = [
        { label: "未連絡", statusKbn: 0 },
        { label: "保護者からの連絡", statusKbn: 1 },
        { label: "連絡取れず", statusKbn: 2 },
        { label: "連絡済(お迎えあり)", statusKbn: 3 },
        { label: "連絡済(お迎えなし)", statusKbn: 4 },
      ];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(param).then((leavings) => {
        var ret = [];
        leavings.data.forEach((leaving) => {
          var comStatusName = comStatusNames.filter(x => x.statusKbn === leaving.comStatusKbn)[0];
          let createUserKbn = leaving.createUserKbn;
          let createUserFlg = false;
          if(createUserKbn == "8"){
            createUserFlg = true;
          }

          var row = {
            mailAddressAuthFlg: leaving.mailAddressAuthFlg,
            name : leaving.name,
            mailAddressAuthFlgParent: leaving.mailAddressAuthFlgParent,
            parentName : leaving.parentName,
            filterItem : leaving.filterItem,
            kateiNm: leaving.kateiNm,
            buNm: leaving.buNm,
            kaNm: leaving.kaNm,
            gakunen: leaving.gakunen,
            gakkyu: leaving.gakkyu,
            syussekiNo: leaving.syussekiNo,
            cancelFlg: leaving.cancelFlg,
            leavingReason: leaving.leavingReason,
            createUserNm: leaving.createUserNm,
            createUserFlg: createUserFlg,

            comStatusKbn: leaving.comStatusKbn,
            comStatusName: comStatusName.label,
            leavingTime: leaving.leavingTime,
            unreadFlg: (leaving.unreadFlg == true) ? "未読" : "既読",
            approvalFlg: (leaving.approvalFlg == true) ? true : false,
            arrivalStatusFlg: leaving.arrivalStatusFlg,
            arrivalTime: (leaving.arrivalStatusFlg == true) ? leaving.arrivalTime : "",
            updateId: leaving.updateId,
            updateUserName: leaving.updateUserName,
            updateUserKbn: leaving.updateUserKbn,

            parentUpdDate: leaving.parentUpdDate,
            parentUpdDateTime: leaving.parentUpdDateTime,
            orderNoKBKGCS: leaving.orderNoKBKGCS,

            loginId: leaving.loginId,
            pastLockFlg: leaving.pastLockFlg,
            gatePassageTime: leaving.gatePassageTime,
            gatePassageFlg: (leaving.gatePassageTime) ? true : false,
          };
          ret.push(row);
        });
        this.membersBase = ret;
        if(this.sortKbn == "1") {
          this.members = Enumerable.from(ret).orderBy((x) => { return x.orderNoKBKGCS }).toArray();
        } else if(this.sortKbn == "2") {
          this.members = Enumerable.from(ret).orderByDescending((x) => { return x.parentUpdDateTime }).toArray();
        } else if(this.sortKbn == "3") {
          this.members = Enumerable.from(ret).orderByDescending((x) => { return x.orderNoKBKGCS }).toArray();
        } else if(this.sortKbn == "4") {
          this.members = Enumerable.from(ret).orderBy((x) => { return x.parentUpdDateTime }).toArray();
        }

        this.isListLoding = false;
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
      this.lastReloadTime = format(new Date(), "HH時mm分ss秒", { locale: ja });
    },
    // 通用門通過時刻確認
    async onClickGatePassageItem(item) {
      var param = {
        funcName: "saveGatePassagTime",
        studentId: item.loginId,
      }

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data){
          item.gatePassageFlg = true;
          item.gatePassageTime = ret.data;
        }
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
    },
    updHeight(){
      this.tableHeight = parseInt(window.innerHeight * 0.6);
    },

    // 検索モーダル用 =================================================================================
    doFilter1() {
      this.isFilterFlg1 = true;
      this.filterDialog1 = false;
    },
    clearFilter1(){
      this.groupFilterValue = "";

      this.isFilterFlg1 = false;
      this.filterDialog1 = false;
    },
  },
};
</script>

<style scoped>
.not-active {
  background-color: rgb(182, 182, 182)
}
.active {
  background-color: rgb(255, 255, 255)
}
</style>