<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          学習のまとめタイムライン(所属学年学級分)
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="yellow" dark v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <span>直近7日以内に保存 または コメントが追加されたものが表示されます</span>
          </v-tooltip>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-card class="mx-auto text-center">
          <!-- 検索用のテキスト入力 -->
          <v-text-field
            v-model="search"
            label="氏名ｶﾅ検索"
            placeholder="氏名ｶﾅ検索"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          ></v-text-field>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-autocomplete
          v-model="gakunenNmFilterValue"
          label="まとめ学年指定"
          placeholder="まとめ学年指定"
          :items="gakunenNmList"
          outlined
          dense
          clearable
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
        <v-switch 
          v-model="viewSubmission"
          dense
          class="mt-1"
          label="提出のみ"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="0" sm="4" md="4" lg="4" xl="4">
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="storyList"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          :loading="isListLoding" loading-text="読込中"
          dense
          disable-sort
        >
          <!-- 更新日時/提出状況 -->
          <template #[`item.submissionFlg`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.updDate }}</v-col></v-row>
            <v-row dense v-if="item.submissionFlg"><v-col cols="12" justify="center" style="white-space : nowrap;" class="red--text">{{ item.submissionStates }}</v-col></v-row>
            <v-row dense v-if="!item.submissionFlg"><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.submissionStates }}</v-col></v-row>
          </template>

          <!-- 生徒情報 -->
          <template #[`item.userNmKana`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.kateiNm }}/{{ item.buNm }}/{{ item.kaNm }}/{{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.userNm }}({{ item.userNmKana }})</v-col></v-row>
          </template>

          <!-- コメント日時/コメントユーザー -->
          <template #[`item.lastCommentDate`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.lastCommentDate }}</v-col></v-row>
            <v-row dense v-if="!item.lastCommentSelfFlg"><v-col cols="12" justify="center" style="white-space : nowrap;" class="red--text">{{ item.lastCommentUserNm }}</v-col></v-row>
            <v-row dense v-if="item.lastCommentSelfFlg"><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.lastCommentUserNm }}</v-col></v-row>
          </template>

          <!-- コメント未読 -->
          <template #[`item.unreadFlg`]="{ item }">
            <v-icon v-if="item.unreadFlg" color="red">mdi-new-box</v-icon>
          </template>

          <!-- 詳細ボタン -->
          <template #[`item.action1`]="{ item }">
            <v-btn 
              color="accent"
              class="black--text"
              @click="openDialog(item)"
              >
              詳細
            </v-btn>
          </template>
      </v-data-table>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- 学習のまとめモーダル -->
    <v-dialog
      v-model="storyDialog"
      scrollable
      fullscreen
      persistent
    >
      <v-card>
        <v-card-text class="text-pre-wrap">
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-toolbar dark color="primary">
                <v-toolbar-title>学習のまとめ：{{ dialogGakunenNm }}</v-toolbar-title>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    教科・科目の学習（目標）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord01Goal)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    教科・科目の学習（やったこと・考えたこと）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord01Evidence)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    探究学習（目標）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord02Goal)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    探究学習（やったこと・考えたこと）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord02Evidence)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    特徴・特技（目標）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord03Goal)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    特徴・特技（やったこと・考えたこと）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord03Evidence)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    部活動（目標）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord04Goal)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    部活動（やったこと・考えたこと）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord04Evidence)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    ボランティア活動（目標）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord05Goal)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    ボランティア活動（やったこと・考えたこと）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord05Evidence)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    留学・海外経験（目標）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord06Goal)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    留学・海外経験（やったこと・考えたこと）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord06Evidence)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    資格・検定（目標）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord07Goal)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    資格・検定（やったこと・考えたこと）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord07Evidence)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    表彰・顕彰（目標）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord08Goal)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    表彰・顕彰（やったこと・考えたこと）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord08Evidence)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    クラス委員（目標）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord09Goal)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    クラス委員（やったこと・考えたこと）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord09Evidence)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    生徒会関連の委員（目標）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord10Goal)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    生徒会関連の委員（やったこと・考えたこと）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord10Evidence)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    行事関連の委員（目標）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord11Goal)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    行事関連の委員（やったこと・考えたこと）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord11Evidence)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    学びの見通し（目標）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord12Goal)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    学びの見通し（やったこと・考えたこと）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord12Evidence)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    キャリアの見通し（目標）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord13Goal)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    キャリアの見通し（やったこと・考えたこと）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord13Evidence)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    その他（目標）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord14Goal)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    その他（やったこと・考えたこと）
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogRecord14Evidence)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" class="d-flex ml-2 pb-0 pt-0">
              <v-list dense>
                <v-subheader>添付ファイル</v-subheader>
                <v-list-item-group  v-if="dialogFileList.length" color="primary">
                  <v-list-item v-for="item in dialogFileList" :key="item.attachmentId">
                    <a @click="onClickDwonload(item)" class="text-decoration-none">{{ item.fileName }}</a>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group  v-if="!dialogFileList.length" color="primary">
                  <v-list-item>ファイルはありません</v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
          <!-- コメント -->
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-toolbar dark color="primary">
                <v-toolbar-title>コメント</v-toolbar-title>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card v-if="!dialogCommentList.length">
                <v-card-text class="d-flex justify-center flex-column">
                  <label color="primary">コメントはありません</label>
                </v-card-text>
              </v-card>
              <v-card v-if="dialogCommentList.length">
                <div v-for="item in dialogCommentList" :key="item.commentId">
                  <v-divider></v-divider>
                  <v-row dense class="lime lighten-4">
                    <v-col cols="11">
                      <div class="text-caption">
                        <div>{{ item.userNm }}が</div>
                        <span>{{ item.postDate }}に更新</span>
                      </div>
                    </v-col>
                    <v-col cols="1">
                      <v-card-actions class="justify-right pl-0 pr-0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="red" icon outlined x-small v-bind="attrs" v-on="on" @click="deleteComment(item)"><v-icon>mdi-delete</v-icon></v-btn>
                          </template>
                          <span>コメントを削除する</span>
                        </v-tooltip>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                  <v-row no-gutters><v-divider></v-divider></v-row>
                  <v-row dense>
                    <v-card-text class="d-flex justify-center flex-column pt-0">
                      <v-row dense>
                        <v-col cols="12">
                          <p style="white-space:pre-wrap;" v-html="autoLink(item.comment)"></p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card>
            <ValidationObserver ref="obs" v-slot="{ invalid }">
              <ValidationProvider name="コメント本文" rules="max:3000" v-slot="{ errors }">
                <v-row dense justify="end">
                  <v-col cols="12" class="d-flex text-right">
                    <v-btn color="accent" class="ml-auto mt-0 black--text" @click="saveComments" :disabled="invalid">送信</v-btn>
                  </v-col>
                </v-row>
                <v-row dense class="pt-0 mt-0">
                  <v-col cols="12" class="pb-0">
                    <v-textarea
                      v-model="dialogComment"
                      ref="commentArea"
                      label="コメント本文"
                      placeholder=""
                      outlined
                      dense
                      :error-messages="errors[0]"
                      :counter="3000"
                      class="text-body-2 pt-0 mb-0"
                      hide-details="auto"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </ValidationProvider>
            </ValidationObserver>
          </v-card>
          <v-row style="height: 50px;">
            <v-col cols="12" justify="center">
            </v-col>
          </v-row>
        </v-card-text>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fixed fab left v-bind="attrs" v-on="on" color="grey" @click="closeDialog" style="bottom: -10px">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>閉じる</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fixed fab right v-bind="attrs" v-on="on" color="grey" @click="scrollToBottomDialog" style="bottom: -10px">
              <v-icon color="white">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <span>ページの最後を表示する</span>
        </v-tooltip>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { saveAsCommon } from '../../../common';
import Autolinker from 'autolinker';
export default {
  // 初期処理宣言
  beforeRouteEnter(to, from, next){next(vm => {vm.setup()})},
  data() {
    return {
      headers: [
        { text: "更新日時/提出状況", value: "submissionFlg", width: "200", filter: this.noSubmissionFilter },
        { text: "まとめ学年", value: "gakunenNm", width: "80", filter: this.gakunenNmFilter },
        { text: "生徒情報", value: "userNmKana", width: "80", filter: this.userNmKanaFilter},
        { text: "最終コメント", value: "lastCommentDate", width: "200" },
        { text: "コメント未読", value: "unreadFlg", width: "80" },
        { text: "詳細", value: "action1", width: "80" },
      ],
      storyList: [],
      isListLoding: false,

      search: "",
      viewSubmission: false,
      gakunenNmFilterValue: "",
      gakunenNmList: [],

      // 学習のまとめダイアログ表示用
      storyDialog: false,
      dialogStudentId: "",
      dialogStoryCd: "",

      dialogGakunenNm: "",

      dialogRecord01Goal : "",
      dialogRecord01Evidence : "",
      dialogRecord02Goal : "",
      dialogRecord02Evidence : "",
      dialogRecord03Goal : "",
      dialogRecord03Evidence : "",
      dialogRecord04Goal : "",
      dialogRecord04Evidence : "",
      dialogRecord05Goal : "",
      dialogRecord05Evidence : "",
      dialogRecord06Goal : "",
      dialogRecord06Evidence : "",
      dialogRecord07Goal : "",
      dialogRecord07Evidence : "",
      dialogRecord08Goal : "",
      dialogRecord08Evidence : "",
      dialogRecord09Goal : "",
      dialogRecord09Evidence : "",
      dialogRecord10Goal : "",
      dialogRecord10Evidence : "",
      dialogRecord11Goal : "",
      dialogRecord11Evidence : "",
      dialogRecord12Goal : "",
      dialogRecord12Evidence : "",
      dialogRecord13Goal : "",
      dialogRecord13Evidence : "",
      dialogRecord14Goal : "",
      dialogRecord14Evidence : "",
      
      dialogFileList: [],
      dialogCommentList: [],

      dialogComment: "",
    };
  },
  methods: {
    async setup() {
      await this.reload();
    },
    // 氏名カナフィルタ
    userNmKanaFilter(value) {
      if (value.includes(this.search)) {
        return true;
      } else {
        return false;
      }
    },
    // まとめ単位フィルタ
    gakunenNmFilter(value) {
      if (!this.gakunenNmFilterValue) {
        return true;
      }
      return value.toString().includes(this.gakunenNmFilterValue);
    },
    // 提出フィルタ
    noSubmissionFilter(value) {
      if (!this.viewSubmission) {
        return true;
      }
      return value;
    },
    // リロードボタン
    async reload() {
      this.storyList = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;

      var param = {
        funcName: "poLoadStoryTimeline",
      }
      await schoolAidHomeLogined(param).then((ret) => {
        var temp_gakunenNms = new Set();
        // 活動記録一覧
        var buff = [];
        ret.data.forEach((recode) => {

          var row = {
            studentId: recode.studentId,
            storyCd: recode.storyCd,

            // 学習のまとめ情報
            updDate: recode.updDate,
            // 提出状況
            submissionFlg: recode.submissionFlg,
            submissionDate: recode.submissionDate,
            submissionStates: "",

            // 生徒情報
            kateiNm: recode.kateiNm,
            buNm: recode.buNm,
            kaNm: recode.kaNm,
            gakunen: recode.gakunen,
            gakkyu: recode.gakkyu,
            syussekiNo: recode.syussekiNo,
            userNm: recode.userNm,
            userNmKana: recode.userNmKana,
            
            // 提出学年
            gakunenNm: recode.gakunenNm,

            record01Goal : recode.record01Goal,
            record01Evidence : recode.record01Evidence,
            record02Goal : recode.record02Goal,
            record02Evidence : recode.record02Evidence,
            record03Goal : recode.record03Goal,
            record03Evidence : recode.record03Evidence,
            record04Goal : recode.record04Goal,
            record04Evidence : recode.record04Evidence,
            record05Goal : recode.record05Goal,
            record05Evidence : recode.record05Evidence,
            record06Goal : recode.record06Goal,
            record06Evidence : recode.record06Evidence,
            record07Goal : recode.record07Goal,
            record07Evidence : recode.record07Evidence,
            record08Goal : recode.record08Goal,
            record08Evidence : recode.record08Evidence,
            record09Goal : recode.record09Goal,
            record09Evidence : recode.record09Evidence,
            record10Goal : recode.record10Goal,
            record10Evidence : recode.record10Evidence,
            record11Goal : recode.record11Goal,
            record11Evidence : recode.record11Evidence,
            record12Goal : recode.record12Goal,
            record12Evidence : recode.record12Evidence,
            record13Goal : recode.record13Goal,
            record13Evidence : recode.record13Evidence,
            record14Goal : recode.record14Goal,
            record14Evidence : recode.record14Evidence,

            // 最終コメント
            lastCommentDate: recode.lastCommentDate,
            lastCommentUserNm: recode.lastCommentUserNm,
            lastCommentSelfFlg: recode.lastCommentSelfFlg,

            // 未読コメント
            unreadFlg: recode.unreadFlg,
          };

          // 提出状況
          if(recode.submissionFlg){
            row.submissionStates = "提出済";
          } else {
            row.submissionStates = "未提出";
          }
          temp_gakunenNms.add(recode.gakunenNm);
          buff.push(row);
        });
        this.gakunenNmList = Array.from(temp_gakunenNms);
        this.storyList = buff;
        this.isListLoding = false;
      });
    },
    // モーダルを開く
    async openDialog(item) {
      // 検索キー
      this.dialogStudentId = item.studentId;
      this.dialogStoryCd = item.storyCd;

      this.dialogGakunenNm = item.gakunenNm;
      
      // 登録値設定
      this.dialogRecord01Goal = item.record01Goal;
      this.dialogRecord01Evidence = item.record01Evidence;
      this.dialogRecord02Goal = item.record02Goal;
      this.dialogRecord02Evidence = item.record02Evidence;
      this.dialogRecord03Goal = item.record03Goal;
      this.dialogRecord03Evidence = item.record03Evidence;
      this.dialogRecord04Goal = item.record04Goal;
      this.dialogRecord04Evidence = item.record04Evidence;
      this.dialogRecord05Goal = item.record05Goal;
      this.dialogRecord05Evidence = item.record05Evidence;
      this.dialogRecord06Goal = item.record06Goal;
      this.dialogRecord06Evidence = item.record06Evidence;
      this.dialogRecord07Goal = item.record07Goal;
      this.dialogRecord07Evidence = item.record07Evidence;
      this.dialogRecord08Goal = item.record08Goal;
      this.dialogRecord08Evidence = item.record08Evidence;
      this.dialogRecord09Goal = item.record09Goal;
      this.dialogRecord09Evidence = item.record09Evidence;
      this.dialogRecord10Goal = item.record10Goal;
      this.dialogRecord10Evidence = item.record10Evidence;
      this.dialogRecord11Goal = item.record11Goal;
      this.dialogRecord11Evidence = item.record11Evidence;
      this.dialogRecord12Goal = item.record12Goal;
      this.dialogRecord12Evidence = item.record12Evidence;
      this.dialogRecord13Goal = item.record13Goal;
      this.dialogRecord13Evidence = item.record13Evidence;
      this.dialogRecord14Goal = item.record14Goal;
      this.dialogRecord14Evidence = item.record14Evidence;

      await this.loadFilelistAndComments();
      
      // コメント未読フラグを折る
      item.unreadFlg = false;
      this.storyDialog = true;
    },
    // モーダルを閉じる
    async closeDialog() {
      this.storyDialog = false;
    },
    // 学習のまとめのファイルリストとコメントを取得
    async loadFilelistAndComments() {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");

      var param = {
        funcName: "poLoadStoryFilelistAndComments",
        studentId: this.dialogStudentId,
        storyCd: this.dialogStoryCd,
      }
      await schoolAidHomeLogined(param).then((ret) => {        
        this.dialogFileList = ret.data.fileList;
        this.dialogCommentList = ret.data.commentList;
      });
    },
    // レビューコメント登録
    async saveComments() {
      if (!this.dialogComment) {
        alert("コメント本文が入力されていません。");
        return;
      }

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");

      var param = {
        funcName: "poSaveStoryComment",
        studentId: this.dialogStudentId,
        storyCd: this.dialogStoryCd,
        comment: this.dialogComment,
      }
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data === "success"){
          this.dialogComment = "";
        }
      });
      // コメントを再読み込み
      await this.loadFilelistAndComments();
      this.reload();
    },
    // コメント論理削除
    async deleteComment(item) {
      var result = confirm("削除してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "poDeleteStoryComment",
        studentId: this.dialogStudentId,
        storyCd: this.dialogStoryCd,
        commentId: item.commentId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      await this.loadFilelistAndComments();
      this.reload();
    },

    // 添付ファイルダウンロード
    async onClickDwonload(item) {
      saveAsCommon(item.url, item.fileName);
    },
    // 下部スクロール
    scrollToBottomDialog() {
      this.$refs.commentArea.focus();
    },
    autoLink(text) {
      const autolinker = new Autolinker();
      return autolinker.link(text);
    },
  },
};
</script>