var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"d-flex justify-center flex-column mx-auto my-6 flat",attrs:{"width":"1200","color":"#fff"}},[_c('v-card-title',{staticClass:"d-flex justify-left"},[_vm._v("課題："+_vm._s(_vm.titleHW))]),_c('v-card-text',{staticClass:"d-flex justify-center flex-column"},[_c('p',{staticStyle:{"white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(_vm.autoLink(_vm.bodyHW))}})])],1),_c('v-card',{staticClass:"d-flex justify-center flex-column mx-auto my-6 flat",attrs:{"width":"1200","color":"#fff"}},[_c('v-card-title',{staticClass:"d-flex justify-left"},[_vm._v("活動記録")]),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [(_vm.editNameFlg)?_c('ValidationProvider',{attrs:{"name":"活動記録名","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"mx-10 mt-0"},[_c('v-text-field',{attrs:{"prepend-icon":"$cet_required","label":"活動記録名","placeholder":"","error-messages":errors[0],"outlined":"","dense":"","counter":50,"hide-details":"auto"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"本文・設問","rules":"required|max:9999"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"mx-10 mt-0"},[_c('v-textarea',{attrs:{"prepend-icon":"$cet_required","label":"本文・設問","placeholder":"","error-messages":errors[0],"auto-grow":"","outlined":"","dense":"","counter":9999,"hide-details":"auto"},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}})],1)]}}],null,true)}),_c('v-card-text',{attrs:{"justify":"center"}},[_c('dashboard',{attrs:{"uppy":_vm.uppy,"props":{
            theme: 'light',
            inline: true,
            width: 3000,
            height: 350,
            disableThumbnailGenerator:true,
            showRemoveButtonAfterComplete:true,
            proudlyDisplayPoweredByUppy:false,
          }}}),_vm._v(" ※添付ファイルは(容量：50MB、個数：10)が上限となっております ")],1),_c('v-card-actions',[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"6","justify":"center"}},[_c('v-btn',{staticClass:"ml-auto mt-0 black--text",attrs:{"color":"accent","disabled":invalid,"width":"90%"},on:{"click":function($event){return _vm.poSavePoActivityRecordRegist(false)}}},[_vm._v("活動記録の保存(未提出)")])],1),_c('v-col',{attrs:{"cols":"6","justify":"center"}},[_c('v-btn',{staticClass:"ml-auto mt-0 black--text",attrs:{"color":"accent","disabled":invalid,"width":"90%"},on:{"click":function($event){return _vm.poSavePoActivityRecordRegist(true)}}},[_vm._v("活動記録の提出")])],1)],1)],1)]}}])})],1),_c('div',{attrs:{"id":"return-to-bottom"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }