<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          所属グループ選択
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="groups"
          :items-per-page.sync="perPage"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          disable-sort
          :loading="isGroupsLoding" loading-text="読込中"
          mobile-breakpoint="0"
          >
          <!-- グループ名とモバイル用まとめ -->
          <template #[`item.groupName`]="{ item }">
            <v-row dense class="hidden-xs-only">
              <v-col cols="12" justify="center">
                {{ item.groupName }}
              </v-col>
            </v-row>

            <!-- モバイル用まとめ -->
            <v-row dense class="d-sm-none">
              <v-col cols="12" justify="center">
                <!-- １行目(グループ名＋お気に入りボタン) -->
                <v-row>
                  <v-col cols="6" class="text-subtitle-1">
                    {{ item.groupName }}
                  </v-col>
                  <v-col cols="6" class="d-flex justify-end text-caption">
                    <v-tooltip top v-if="item.favoriteFlg">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small outlined v-bind="attrs" v-on="on" @click="changeFavoriteGroup(item)"><v-icon color="orange">mdi-star</v-icon></v-btn>
                      </template>
                      <span>お気に入りを解除する</span>
                    </v-tooltip>
                    <v-tooltip top v-if="!item.favoriteFlg">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small outlined v-bind="attrs" v-on="on" @click="changeFavoriteGroup(item)"><v-icon>mdi-star-outline</v-icon></v-btn>
                      </template>
                      <span>お気に入りに追加する</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <!-- ２行目 -->
                <v-row>
                  <v-col cols="4" class="d-flex justify-start text-caption">
                    <v-btn small color="accent" class="black--text" :disabled="!item.canViewFlg" @click="onClicktoUserList(item)">生徒一覧</v-btn>
                  </v-col>
                  <v-col cols="4" class="d-flex justify-center text-caption">
                    <v-btn small color="accent" class="black--text" @click="onClicktoGroupMemberList(item)">所属者一覧</v-btn>
                  </v-col>
                  <v-col cols="4" class="d-flex justify-end text-caption">
                    <v-btn small color="accent" class="black--text" :disabled="!item.canViewFlg" @click="onClicktoCourseSelectView(item)">コース選択</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>

          <!-- お気に入りボタン -->
          <template #[`item.favoriteFlg`]="{ item }">
            <v-tooltip top v-if="item.favoriteFlg">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon outlined v-bind="attrs" v-on="on" @click="changeFavoriteGroup(item)"><v-icon color="orange">mdi-star</v-icon></v-btn>
              </template>
              <span>お気に入りを解除する</span>
            </v-tooltip>
            <v-tooltip top v-if="!item.favoriteFlg">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon outlined v-bind="attrs" v-on="on" @click="changeFavoriteGroup(item)"><v-icon>mdi-star-outline</v-icon></v-btn>
              </template>
              <span>お気に入りに追加する</span>
            </v-tooltip>
          </template>

          <template #[`item.action`]="{ item }">
            <v-row dense>
              <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                <v-btn  color="accent" class="black--text" :disabled="!item.canViewFlg" @click="onClicktoUserList(item)">生徒一覧</v-btn>
              </v-col>
              <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                <v-btn  color="accent" class="black--text" @click="onClicktoGroupMemberList(item)">所属者一覧</v-btn>
              </v-col>
              <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                <v-btn  color="accent" class="black--text" :disabled="!item.canViewFlg" @click="onClicktoCourseSelectView(item)">コース選択状況</v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
</v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
export default {
  data() {
    return {
      headers: [
        { text: "グループ名", value: "groupName", class: "hidden-xs-only", width: "200" },
        { text: "お気に入り", value: "favoriteFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "50" },
        { text: "" , value:"action", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
      ],
      groups: [],
      isGroupsLoding: false,
      perPage: 30,
    };
  },
  watch: {
    perPage: function(newVal) {
      this.$store.dispatch('setParPageGroupSelect', newVal);
    },
  },
  // 初期表示時データ読み込み
  async created() {
    this.perPage = this.$store.state.parPageGroupSelect;  
    await this.reload();
  },
  methods: {
    // ページ再読込
    async reload() {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isGroupsLoding = true;
      schoolAidHomeLogined({funcName:"loadGroupSelect"}).then((groups) => {
        var ret = [];
        groups.data.forEach((group) => {
          var row = {
            groupId : group.groupId,
            favoriteFlg: group.favoriteFlg,
            groupName: group.groupName,
            canViewFlg: group.canViewFlg,
          };
          ret.push(row);
        });
        this.groups = ret;
        this.isGroupsLoding = false;
      });
    },
    async changeFavoriteGroup(item)  {      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "changeFavoriteGroup",
        groupId: item.groupId,
        favoriteFlg: item.favoriteFlg
      };
      await schoolAidHomeLogined(data);
      this.reload();
    },
    // 所属者一覧
    onClicktoGroupMemberList(item) {
      this.$router.push({
        name: "GroupMemberList",
        params: {
          groupName:item.groupName,
          groupId:item.groupId,
          beforeName:"GroupSelect"
        }
      });
    },
    // 生徒一覧グループ選択
    onClicktoUserList(item) {
      console.log(item.groupName, item.groupId);
      var yyyymmdd = format(new Date(), 'yyyy-MM-dd', { locale: ja });
      this.$router.push({
        name: "UserList",
        params: {
          groupName:item.groupName,
          groupId:item.groupId,
          param_yyyymmdd: yyyymmdd,
        }
      });
    },
    // コース選択状況
    onClicktoCourseSelectView(item) {
      this.$router.push({
        name: "CourseSelectView",
        params: {
          groupName:item.groupName,
          groupId:item.groupId,
        }
      });
    },
  },
};
</script>