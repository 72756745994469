<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          保健室受付(検索して受付)
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="left">
        <v-card class="mx-auto text-left" color="white">
          課程・部・科・学年・受付日を指定し、検索した後に登録を行ってください。(※学級は任意指定となります)
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="white">
          <ValidationObserver ref="obs" v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="2" justify="center">
                <ValidationProvider
                  name="課程"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                    label="課程"
                    :items="kateiMst"
                    @change="changeKBK"
                    item-text="name"
                    item-value="code"
                    v-model="selectedKatei"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="1" justify="center">
                <ValidationProvider
                  name="部"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                    label="部"
                    :items="buMst"
                    @change="changeKBK"
                    item-text="name"
                    item-value="code"
                    v-model="selectedBu"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    hide-details="auto"
                    ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="2" justify="center">
                <ValidationProvider
                  name="科"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                    label="科"
                    :items="kaMst"
                    @change="changeKBK"
                    item-text="name"
                    item-value="code"
                    v-model="selectedKa"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    hide-details="auto"
                    ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="1" justify="center">
                <ValidationProvider
                  name="学年"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                <v-select
                  label="学年"
                  :items="gakunenMst"
                  item-text="name"
                  item-value="name"
                  v-model="selectedGakunen"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  hide-details="auto"
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="2" justify="center">
                <v-select
                  label="学級"
                  :items="gakkyuMst"
                  item-text="name"
                  item-value="name"
                  v-model="selectedGakkyu"
                  outlined
                  dense
                  clearable
                  multiple
                  chips
                  deletable-chips
                  hide-details="auto"
                  ></v-select>
              </v-col>
              <v-col cols="2" justify="center">
                <ValidationProvider name="受付日" rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model="receptionDate"
                    label="受付日"
                    placeholder=""
                    type="date"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    hide-details="auto"
                    ></v-text-field>
                </ValidationProvider>
              </v-col>  
              <v-col cols="1" justify="center">
                <v-btn color="accent" class="ml-auto mt-0 black--text" @click="onSearch" :disabled="invalid">検索</v-btn>
              </v-col>
          </v-row>
          </ValidationObserver>
        </v-card> 
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" justify="center">
        <v-card class="mx-auto text-center">
          <!-- 検索用のテキスト入力 -->
          <v-text-field
            v-model="search"
            label="氏名ｶﾅ検索"
            placeholder="氏名ｶﾅ検索"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          ></v-text-field>
        </v-card>
      </v-col>
      <v-col cols="2" justify="center">
      </v-col>  
      <v-col cols="2" justify="center">
        <div align="left">
          <v-btn color="accent" class="black--text" @click="toHealthRoomReception" width="90%">バーコード受付</v-btn>
        </div>
      </v-col>
      <v-col cols="2">
        <div align="left">
          <v-btn color="accent" class="black--text" @click="toHealthRoomRecodeEdit" width="90%">傷病の記録</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="userList"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          :loading="isListLoding" loading-text="読込中"
          dense
        >
          <template #[`item.userNmKana`]="{ item }">
            {{ item.userNm }}({{ item.userNmKana }})
          </template>

          <!-- 登録状況 -->
          <template #[`item.status`]="{ item }">
            <v-icon class="ml-2" v-if="item.existsFlg">mdi-check-outline</v-icon>
          </template>
          <!-- 入室ボタン 退室ボタン -->
          <template #[`item.checkInCheckOut`]="{ item }">
            <v-btn large v-if="!item.checkInFlg" class="red--text" @click="checkIn(item)">
              <v-icon x-large left>
                mdi-location-enter
              </v-icon>
              <span>入室</span>
            </v-btn>
            <v-btn large v-if="item.checkInFlg" class="blue--text" @click="checkOut(item)">
              <span>退室</span>

              <v-icon x-large right>
                mdi-location-exit
              </v-icon>
            </v-btn>
          </template>
      </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import Enumerable from "linq";
export default {
  data() {
    return {
      // KBK制御
      kateiMst: [],
      buMst: [],
      kaMst: [],
      gakunenMst: [],
      gakunenMstBase: [],
      gakkyuMst: [],
      gakkyuMstBase: [],
      selectedKatei: "01",
      selectedBu: "",
      selectedKa: "",
      selectedGakunen: "",
      selectedGakkyu: [],
      receptionDate: "",

      search: "",

      headers: [
        { text: "学級", value: "gakkyu", align: "start"},
        { text: "出席番号", value: "syussekiNo", align: "start"},
        { text: "生徒氏名(ｶﾅ)", value: "userNmKana", filter: this.userNmKanaFilter},
        { text: "登録状況", value: "status" },
        { text: "入室時刻", value: "checkInTime" },
        { text: "退室時刻", value: "checkOutTime" },
        { text: "", value: "checkInCheckOut" },
      ],
      userList: [],
      isListLoding: false,
    };
  },
  async created() {
    this.defaultDateSet();
    // KBKGCの取得
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    await schoolAidHomeLogined({funcName:"commonGetKBKG"}).then((ret) => {
      this.kateiMst = ret.data.kateiMst;
      this.buMst = ret.data.buMst;
      this.kaMst = ret.data.kaMst;
      this.gakunenMstBase = ret.data.gakunenMst;
      this.gakkyuMstBase = ret.data.gakkyuMst;
    });
  },
  methods: {
    // 課程・部・科設定後のセレクトボックス変更
    changeKBK() {
      if(this.selectedKatei
       && this.selectedBu
       && this.selectedKa){
        // 学年マスタをKBKでフィルタ
        this.gakunenMst = Enumerable.from(this.gakunenMstBase).where(i => 
        i.code.substring(0, 8) == (
          this.selectedKatei
          + "-"
          + this.selectedBu
          + "-"
          + this.selectedKa
          )).toArray();
        this.selectedGakunen = "";
        // 学級マスタをKBKでフィルタ
        this.gakkyuMst = Enumerable.from(this.gakkyuMstBase).where(i => 
        i.code.substring(0, 8) == (
          this.selectedKatei
          + "-"
          + this.selectedBu
          + "-"
          + this.selectedKa
          )).toArray();
        this.selectedGakkyu = [];
      }
    },
    // 検索
    async onSearch() {

      const data = {
        funcName: "searchHealthRoomReception",
        katei: this.selectedKatei,
        bu: this.selectedBu,
        ka: this.selectedKa,
        gakunen: this.selectedGakunen,
        gakkyu: this.selectedGakkyu,
        receptionDate: this.receptionDate,
      };
      this.userList = [];

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((ret) => {
        var buff = [];
        ret.data.forEach((user) => {
          var row = {
            gakkyu: user.gakkyu,
            syussekiNo: user.syussekiNo,
            userId: user.userId,
            yyyymmdd: user.yyyymmdd,
            userNm: user.userNm,
            userNmKana: user.userNmKana,
            checkInTime: user.checkInTime,
            checkOutTime: user.checkOutTime,
            existsFlg: user.existsFlg,
            checkInFlg: user.checkInFlg,
          };
          buff.push(row);
        });
        this.userList = buff;
        this.isListLoding = false;
      });
    },
    async checkIn(item) {
      const data = {
        funcName: "checkInHealthRoomReceptionRegist",
        userId: item.userId,
        receptionDate: item.yyyymmdd,
      };

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((ret) => {
        item.checkInTime = ret.data;
        item.checkOutTime = "";
        item.existsFlg = true;
        item.checkInFlg = true;
      });
    },
    async checkOut(item) {
      const data = {
        funcName: "checkOutHealthRoomReceptionRegist",
        userId: item.userId,
        receptionDate: item.yyyymmdd,
      };

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((ret) => {
        item.checkOutTime = ret.data;
        item.existsFlg = true;
        item.checkInFlg = false;
      });
    },
    // 氏名カナフィルタ
    userNmKanaFilter(value) {
      if (value.includes(this.search)) {
        return true;
      } else {
        return false;
      }
    },
    // 保健室受付画面へ
    toHealthRoomReception() {
      this.$router.push({
        name: "HealthRoomReception",
      });
    },
    // 傷病記録の登録
    toHealthRoomRecodeEdit() {
      this.$router.push({
        name: "HealthRoomRecodeEdit",
      });
    },
    // 検索条件日付の初期設定１
    defaultDateSet() {
      this.receptionDate = this.formatDate(new Date());
    },
    // 検索条件日付の初期設定２
    formatDate(dt){
      var y = dt.getFullYear();
      var m = ('00' + (dt.getMonth()+1)).slice(-2);
      var d = ('00' + dt.getDate()).slice(-2);
      return (y + '-' + m + '-' + d);
    },
  },
};
</script>