<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          {{ groupName }}のコース選択状況
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" sm="6" md="6" lg="6" xl="6">
        <!-- 検索用のテキスト入力 -->
        <v-text-field
          v-model="search"
          label="氏名ｶﾅ検索"
          placeholder="氏名ｶﾅ検索"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="6" md="6" lg="6" xl="6">

        <v-radio-group
          v-model="viewChange"
          row
          hide-details="auto"
        >
          <v-radio
          label="コース選択状況(個別相談用)"
            value="1"
          ></v-radio>
          <v-radio
            label="コース選択状況(一覧)"
            value="2"
          ></v-radio>

        </v-radio-group>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="showHeaders"
          :items="members"
          :items-per-page.sync="perPage"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          :loading="isListLoding" loading-text="読込中"
          :search="search"
          :custom-filter="filter"
        >

          <template #[`item.userNm`]="{ item }">
            {{ item.userNm }}({{ item.userNmKana }})
          </template>
          <template #[`header.KBKGCS`]="{}"> 
            <v-row dense><v-col cols="12" justify="center">
              課程/部/科
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              学年/学級/出席番号
            </v-col></v-row>
          </template>
          <template #[`item.KBKGCS`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">
              {{ item.kateiNm }}/{{ item.buNm }}/{{ item.kaNm }}
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              {{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}
            </v-col></v-row>
          </template>

          <!-- 未入力時は赤強調 -->
          <template #[`item.status`]="{ item }">
            <span v-if="item.needCourseSelectFlg && !item.existCourseSelectFlg" class="red--text">{{ item.status }}</span>
            <span v-else>{{ item.status }}</span>
          </template>

          <!-- 備考入力時は印表示 -->
          <template #[`item.biko`]="{ item }">
            <span v-if="item.needCourseSelectFlg && item.existCourseSelectFlg && item.biko" class="red--text">※</span>
          </template>

          <template #[`item.action1`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">
              <v-btn small color="green" class="black--text" @click="onClickViewCourseSelectDialog(item)" v-if="item.existCourseSelectFlg">詳細確認</v-btn>
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              <v-btn small color="blue" class="black--text" @click="onClickCourseSelectFix(item)" v-if="item.existCourseSelectFlg && !item.courselockFlg">確定</v-btn>
              <v-btn small color="red" class="black--text" @click="onClickCourseSelectNotFix(item)" v-if="item.existCourseSelectFlg && item.courselockFlg">確定解除</v-btn>
            </v-col></v-row>
          </template>
          <template #[`item.action2`]="{ item }">
            <v-btn small color="green" class="black--text" @click="onClickViewCourseSelectDialog(item)" v-if="item.existCourseSelectFlg">詳細確認</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row class="justify-start" dense>
      <v-col>
        <v-btn @click="onClicktoGroupSelect" color="grey" class="black--text" width="352">戻る</v-btn>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- コース選択詳細確認ダイアログ -->
    <v-dialog
      v-model="viewCourseSelectDialog"
      fullscreen
    >
      <v-card>
        <v-card-title class="justify-center">コース選択詳細</v-card-title>
        <v-card-text class="text-pre-wrap">
          <v-row dense>
            <v-col dense cols="12" justify="center">
              生徒氏名：{{ dialogViewUserNm }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              大学区分：{{ dialogViewDaigakuKbnName }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              志望する文理コース：{{ dialogViewSentakuCourseCdName }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              選択科目(数学)：{{ dialogViewYobi1 }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              選択科目(理科)：{{ dialogViewYobi2 }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              選択科目(地公)：{{ dialogViewYobi3 }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              選択科目(芸術)：{{ dialogViewYobi4 }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              備考：
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              {{ dialogViewBiko }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn color="grey" class="black--text" @click="viewCourseSelectDialog = false" width="100%">
              閉じる
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['groupName','groupId'],
  watch: {
    perPage: function(newVal) {
      this.$store.dispatch('setParPageUserList', newVal);
    },
  },
  computed: {
    showHeaders () {
      if(this.viewChange == "1"){
        return this.headers1;
      } else if(this.viewChange == "2"){
        return this.headers2;
      } else {
        return "";
      }
    }
  },
  data() {
    return {
      search: "",
      viewChange: "1",
      headers1: [
        { text: "氏名(ｶﾅ)", value: "userNm", align: "start", width: "200" },
        { text: "課程/部/科 学年/学級/出席番号", value: "KBKGCS", width: "200" },
        { text: "選択状況", value: "status", width: "100" },
        { text: "備考有無", value: "biko", width: "100" },
        { text: "", value: "action2" },
      ],
      headers2: [
        { text: "氏名(ｶﾅ)", value: "userNm", align: "start", width: "200" },
        { text: "課程/部/科 学年/学級/出席番号", value: "KBKGCS", width: "200" },
        { text: "選択状況", value: "status", width: "100" },
        { text: "大学区分", value: "daigakuKbnName", width: "200" },
        { text: "文理コース", value: "sentakuCourseCdName", width: "100" },
        { text: "選択科目(数学)", value: "yobi1", width: "10%" },
        { text: "選択科目(理科)", value: "yobi2", width: "10%" },
        { text: "選択科目(地公)", value: "yobi3", width: "10%" },
        { text: "選択科目(芸術)", value: "yobi4", width: "10%" },
        { text: "備考有無", value: "biko", width: "100" },
        { text: "", value: "action1", width: "10%" },
      ],
      members: [],
      isListLoding: false,
      perPage: 30,

      viewCourseSelectDialog: false,
      dialogViewUserNm: "",
      dialogViewDaigakuKbnName: "",
      dialogViewSentakuCourseCdName: "",
      dialogViewYobi1: "",
      dialogViewYobi2: "",
      dialogViewYobi3: "",
      dialogViewYobi4: "",
      dialogViewBiko: "",
    };
  },
  // 初期表示時データ読み込み
  async created() {
    // グループ未選択は選択画面へ
    if(!this.groupId){
      this.onClicktoGroupSelect();
    }
    this.perPage = this.$store.state.parPageUserList;
    await this.reload();
  },
  methods: {
    // ページ再読込
    async reload() {
      this.members = [];

      const data = {
        funcName: "loadCourseSelectView",
        groupId: this.groupId,
      };

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((users) => {
        var ret = [];
        users.data.forEach((user) => {
          var row = {
            userNm: user.userNm,
            userNmKana: user.userNmKana,
            kateiNm: user.kateiNm,
            buNm: user.buNm,
            kaNm: user.kaNm,
            gakunen: user.gakunen,
            gakkyu: user.gakkyu,
            syussekiNo: user.syussekiNo,
            studentId : user.loginId,
            groupId : this.groupId,

            // コース選択関連情報
            needCourseSelectFlg: user.needCourseSelectFlg,
            status: user.status,
            daigakuKbnName: user.daigakuKbnName,
            sentakuCourseCdName: user.sentakuCourseCdName,
            yobi1: user.yobi1,
            yobi2: user.yobi2,
            yobi3: user.yobi3,
            yobi4: user.yobi4,
            biko: user.biko,
            courselockFlg: user.courselockFlg,
            existCourseSelectFlg: user.existCourseSelectFlg,
          };
          ret.push(row);
        });
        this.isListLoding = false;
        this.members = ret;
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
    },
    // 氏名カナ検索
    filter(value, search, item) {
      if(!item.userNmKana)return false;
      return item.userNmKana.toString().includes(search)
    },
    // グループ選択に戻る
    onClicktoGroupSelect(){
      this.$router.push({
        name: "GroupSelect"
      });
    },
    async onClickCourseSelectFix(item){
      // ローディング開始
      this.$root.loading = true;

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      const data = {
        funcName: "courseSelectFix",
        studentId: item.studentId,
      };
      await schoolAidHomeLogined(data).then(() => {
        alert("確定しました");
      }).catch((e) =>{
        this.$root.loading = false;
        console.log(e.message);
        this.error = e.message;
      });

      // ローディング開始
      this.$root.loading = false;

      await this.reload();
    },
    async onClickCourseSelectNotFix(item){
      // ローディング開始
      this.$root.loading = true;

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      const data = {
        funcName: "courseSelectNotFix",
        studentId: item.studentId,
      };
      await schoolAidHomeLogined(data).then(() => {
        alert("確定解除しました");
      }).catch((e) =>{
        this.$root.loading = false;
        console.log(e.message);
        this.error = e.message;
      });

      // ローディング開始
      this.$root.loading = false;

      await this.reload();
    },
    onClickViewCourseSelectDialog(item){
      this.dialogViewUserNm = item.userNm
      this.dialogViewDaigakuKbnName = item.daigakuKbnName;
      this.dialogViewSentakuCourseCdName = item.sentakuCourseCdName;
      this.dialogViewYobi1 = item.yobi1;
      this.dialogViewYobi2 = item.yobi2;
      this.dialogViewYobi3 = item.yobi3;
      this.dialogViewYobi4 = item.yobi4;
      this.dialogViewBiko = item.biko;
      this.viewCourseSelectDialog = true;
    },
  },
};
</script>

<style>
</style>