<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center black--text" outlined>
          個別連絡一覧
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <v-autocomplete
          v-model="targetFilterValue"
          label="生徒検索"
          placeholder="生徒検索"
          :items="targetList"
          outlined
          dense
          clearable
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <v-autocomplete
          v-model="teacherFilterValue"
          label="教員指定"
          placeholder="教員指定"
          :items="teacherList"
          outlined
          dense
          clearable
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <v-switch 
          v-model="viewOnly"
          dense
          class="mt-1"
          label="表示中のみ"
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="talkRooms"
          :items-per-page.sync="perPage"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          disable-sort
          :loading="isLoding" loading-text="読込中"
        >

          <!-- 学内/学外 -->
          <template #[`item.inhouseFlg`]="{ item }">
            <div v-if="!item.inhouseFlg" class="font-weight-bold red--text">学外</div>
            <div v-if="item.inhouseFlg">学内</div>
          </template>
          
          <!-- 宛先 -->
          <template #[`item.studentNm`]="{ item }">
            <v-row dense><v-col cols="12" justify="center"><span style="white-space : nowrap;">{{ item.studentNm }}<span v-if="item.targetParentFlg">の保護者様</span></span></v-col></v-row>
          </template>

          <!-- 更新日と更新者 -->
          <template #[`item.lastMessage`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">{{ item.lastMessageDate }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">{{ item.lastMessageUserNm }}</v-col></v-row>
          </template>

          <!-- 連絡者/参加者 -->
          <template #[`item.userNms`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">{{ item.ownerNm }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">{{ item.assignUserNms }}</v-col></v-row>
          </template>

          <!-- ロック/表示制御 -->
          <template #[`item.viewFlg`]="{ item }">
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-tooltip top v-if="item.lockFlg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red" icon outlined v-bind="attrs" v-on="on" @click="changeLockStatus(item)"><v-icon>mdi-lock-outline</v-icon></v-btn>
                  </template>
                  <span>メッセージの追加・変更のロックを解除します</span>
                </v-tooltip>
                <v-tooltip top v-if="!item.lockFlg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="grey" icon outlined v-bind="attrs" v-on="on" @click="changeLockStatus(item)"><v-icon>mdi-lock-open-variant-outline</v-icon></v-btn>
                  </template>
                  <span>メッセージの追加・変更をロックします</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-tooltip bottom v-if="item.viewFlg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="accent" icon outlined v-bind="attrs" v-on="on" @click="changeViewStatus(item)" ><v-icon>mdi-eye-outline</v-icon></v-btn>
                  </template>
                  <span>個別連絡タブで非表示にします</span>
                </v-tooltip>
                <v-tooltip bottom v-if="!item.viewFlg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="grey" icon outlined v-bind="attrs" v-on="on" @click="changeViewStatus(item)"><v-icon>mdi-eye-off</v-icon></v-btn>
                  </template>
                  <span>個別連絡タブで表示します</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>

          <!-- 参照/編集 -->
          <template #[`item.assignFlg`]="{ item }">
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon outlined :disabled="!item.assignFlg || !item.viewFlg" v-bind="attrs" v-on="on" @click="onClickTalkRoom(item)"><v-icon>mdi-open-in-new</v-icon></v-btn>
                  </template>
                  <span>個別連絡の画面に遷移します</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon outlined v-bind="attrs" v-on="on" @click="onClickTalkRoomDialog(item)" ><v-icon>mdi-pencil</v-icon></v-btn>
                  </template>
                  <span>教員を変更します</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>

          <!-- 操作 -->
          <template #[`item.action`]="{ item }">
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green" icon outlined v-bind="attrs" v-on="on" :disabled="item.isExportingFlg" :loading="item.isExportingFlg" @click="exportTalkRoom(item)">
                      <v-icon>mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>メッセージ履歴を出力します</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red" icon outlined v-bind="attrs" v-on="on" @click="deleteTalkRoom(item)"><v-icon>mdi-delete</v-icon></v-btn>
                  </template>
                  <span>個別連絡を削除します</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- 個別連絡教員編集モーダル -->
    <v-dialog
      v-model="talkRoomDialog"
      max-width="800"
    >
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="800"
          color="#fff"
        >
          <v-card-subtitle class="d-flex justify-center" white-space="pre">
            <v-row dense>
              <v-col dense cols="4" justify="center">
                <v-btn color="grey" class="black--text" @click="talkRoomDialog = false" width="90%">
                  閉じる
                </v-btn>
              </v-col>
              <v-col dense cols="4" justify="center">
                <p>教員を変更する</p>
              </v-col>
              <v-col dense cols="4" justify="center">
                <v-btn color="accent" class="black--text" @click="changeTalkRoomAssign()" :disabled="invalid" width="90%">
                  変更
                </v-btn>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text class="d-flex justify-center flex-column">
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider name="管理者" rules="required" :skipIfEmpty="false" v-slot="{ errors }">
                  <v-autocomplete
                    v-model="talkRoomOwner"
                    label="管理者"
                    placeholder="管理者"
                    :items="baseTeacherList"
                    outlined
                    dense
                    @change="changeOwner"
                    :error-messages="errors[0]"
                    hide-details="auto"
                    background-color="white"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <v-autocomplete
                  v-model="talkRoomAssign"
                  label="教員"
                  placeholder="教員"
                  :items="buffTeacherList"
                  outlined
                  dense
                  multiple
                  deletable-chips
                  small-chips
                  hide-details="auto"
                  background-color="white"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </ValidationObserver>
    </v-dialog>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { saveAsCommon } from '../../common';
export default {
  data() {
    return {
      targetFilterValue: "",
      teacherFilterValue: "",
      viewOnly: true,
      targetList : [],
      teacherList : [],
      headers : [
        { text: "作成日時", value: "postDate" , width: "180" },
        { text: "学内/学外", value: "inhouseFlg" , width: "100" },
        { text: "宛先", value: "studentNm", filter: this.targetFilter },
        { text: "連絡管理者/教員", value: "userNms" , width: "200", filter: this.teacherFilter }, 
        { text: "参照/編集", value: "assignFlg" , width: "100" },
        { text: "メッセージ更新日時/更新者", value: "lastMessage", width: "200" },
        { text: "ロック/表示制御", value: "viewFlg" , width: "100", filter: this.viewFilter },
        { text: "メッセージ件数", value: "total", class: "px-0", width: "100" }, 
        { text: "操作", value: "action" , width: "100" },
      ],
      talkRooms: [],
      isLoding: false,
      perPage: 30,

      talkRoomDialog: false,
      talkRoomId: "",
      talkRoomOwner: "",
      talkRoomAssign: [],
      baseTeacherList: [],
      buffTeacherList: [],
    };
  },
  // 初期表示時データ読み込み
  async created() {
    // 教員リストの取得
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    schoolAidHomeLogined({funcName: "commonGetTeachers", includeFlg: true}).then((ret) => {
      this.baseTeacherList = ret.data.teacherList;
    })
    
    this.perPage = this.$store.state.parPageSurveyList;
    await this.reload();
  },
  watch: {
    perPage: function(newVal) {
      this.$store.dispatch('setParPageSurveyList', newVal);
    },
  },
  methods: {
    // 宛先フィルタ
    targetFilter(value) {
      if (!this.targetFilterValue) {
        return true;
      }
      return value.toString().includes(this.targetFilterValue);
    },
    // 教員フィルタ
    teacherFilter(value) {
      if (!this.teacherFilterValue) {
        return true;
      }
      return value.includes(this.teacherFilterValue);
    },
    // 表示フィルタ
    viewFilter(value) {
      if (!this.viewOnly) {
        return true;
      }
      return value;
    },
    // 教員宛個別連絡
    onClickTalkRoomDialog(item){
      this.talkRoomId = item.roomId;
      this.talkRoomOwner = item.ownerId;
      this.talkRoomAssign = item.assignUserIds;
      var buffTeacherList = [];
      for(var row of this.baseTeacherList){
        if(row.value != item.ownerId){
          buffTeacherList.push(row);
        }
      }
      this.buffTeacherList = buffTeacherList;
      this.talkRoomDialog = true;
    },
    changeOwner() {
      var buffTeacherList = [];
      for(var row of this.baseTeacherList){
        if(row.value != this.talkRoomOwner){
          buffTeacherList.push(row);
        }
      }
      this.buffTeacherList = buffTeacherList;
      var buffAssignUserIds = [];
      for(var row2 of this.talkRoomAssign){
        if(row2 != this.talkRoomOwner){
          buffAssignUserIds.push(row2);
        }
      }
      this.talkRoomAssign = buffAssignUserIds;
    },
    // 個別連絡の教員変更
    async changeTalkRoomAssign() {
      var param = {
        funcName: "changeTalkRoomAssign",
        roomId: this.talkRoomId,
        ownerId: this.talkRoomOwner,
        assignUserIds: this.talkRoomAssign,
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data.message) {
          alert("変更に失敗しました");
        }
      });
      this.talkRoomDialog = false;
      await this.reload();
    },
    // 個別連絡のロック状況変更
    async changeLockStatus(item) {
      var result = confirm("ロック状態を変更してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "lockChangeTalkRoom",
        roomId: item.roomId,
        lockFlg: item.lockFlg,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      item.lockFlg = !item.lockFlg;
    },
    // 個別連絡の表示状況変更
    async changeViewStatus(item) {
      var result = confirm("表示状態を変更してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "viewChangeTalkRoom",
        roomId: item.roomId,
        viewFlg: item.viewFlg,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      item.viewFlg = !item.viewFlg;
    },
    // メッセージのExcel出力
    async exportTalkRoom(item) {
      var result = confirm("メッセージ履歴を出力してよろしいですか？");
      if (!result) {
        return;
      }

      item.isExportingFlg = true;

      const data = {
        funcName: "exportTalkRoom",
        roomId: item.roomId,
      };

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((ret) => {
        if(ret.data.message){
          alert(ret.data.message);
        } else {
          saveAsCommon(ret.data.downloadUrl, ret.data.fileName);
        }
      });

      item.isExportingFlg = false;
    },
    // 個別連絡の削除
    async deleteTalkRoom(item) {
      var result = confirm("個別連絡を削除してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "deleteTalkRoom",
        roomId: item.roomId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      await this.reload();
    },
    async reload() {
      this.talkRooms = [];
      // 検索条件格納用配列
      var temp_targets = new Set();
      var temp_teachers = new Set();
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isLoding = true;
      var param = {
        funcName:"loadTalkRoomList",
      }
      await schoolAidHomeLogined(param).then((talkRooms) => {
        var ret = [];
        talkRooms.data.forEach((recode) => {
          // 検索条件を追加する
          if(recode.studentNm)temp_targets.add(recode.studentNm);
          temp_teachers.add(recode.ownerNm);
          recode.assignUserNms.forEach(item => temp_teachers.add(item));

          // 検索用配列に追加する
          var userNms = new Set();
          userNms.add(recode.ownerNm);
          recode.assignUserNms.forEach(item => userNms.add(item));

          var row = {
            roomId: recode.roomId,
            postDate: recode.postDate,
            inhouseFlg: recode.inhouseFlg,
            studentNm: recode.studentNm,
            targetParentFlg: recode.targetParentFlg,
            title: recode.title,
            ownerId: recode.ownerId,
            ownerNm: recode.ownerNm,
            userNms: Array.from(userNms),
            assignUserIds: recode.assignUserIds,
            assignUserNms: recode.assignUserNms.join(","),
            assignFlg: recode.assignFlg,
            lastMessageDate: recode.lastMessageDate,
            lastMessageUserNm: recode.lastMessageUserNm,
            lockFlg: recode.lockFlg,
            viewFlg: recode.viewFlg,
            total: recode.total,
            isExportingFlg: false,
          };
          ret.push(row);
        });
        this.talkRooms = ret;
        this.isLoding = false;
      });
      this.targetList = Array.from(temp_targets);
      this.teacherList = Array.from(temp_teachers);
    },
    // 個別連絡画面へ
    onClickTalkRoom(item) {
      this.$router.push({
        name: "TalkRoom",
        params: {
          roomId : item.roomId,
        } 
      });
    },
  },
};
</script>

<style scoped>
.modal-header, .modal-body {
  padding: 5px 25px;
}
.modal-header {
  border-bottom: 1px solid #ddd;
}

</style>