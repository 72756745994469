import { ValidationProvider, ValidationObserver,extend,localize } from "vee-validate";
import { required, email, numeric, max, min, confirmed } from 'vee-validate/dist/rules';
import ja from 'vee-validate/dist/locale/ja.json';
import { parse } from 'date-fns';

const taion = {
  message: '{_field_}は整数２桁と少数１桁で入力してください',
  validate(value) {
    if(!value)return true;
    var reg = new RegExp('\\d{2}\\.\\d{1}');
    return value.match(reg);
  }
};

//カスタムルール設定
const eisu = {
  message: '{_field_}は半角英数字で入力してください',
  validate(value) {
    if(!value)return true;
    var reg = new RegExp(/^[0-9a-zA-Z]*$/);
    return value.match(reg);
  }
};

// チェックボックスの必須チェック(複数ある場合)
const checkboxRequired = {
  message: '{_field_}は必須項目です',
  validate(value) {
    if(value.length === 0) {
      return false;
    } else {
      return true;
    }
  }
};

const dateAfter = {
  params: ["target"],
  validate: (value, params) => {
    const target = params["target"];
    const toDate = parse(value, "yyyy-MM-dd", new Date());
    const fromDate = parse(target, "yyyy-MM-dd", new Date());
    if (!fromDate || !toDate) return false;
    if(fromDate.getTime() <= toDate.getTime()){
      return true;
    } else {
      return false;
    }
  },
  message: "{_field_}は、{target}より同じかあとの日付を入力ください"
};
const datehourBefore = {
  params: ["target"],
  validate: (value, params) => {
    const target = params["target"];
    const toDate = parse(value, "yyyy-MM-dd'T'HH:mm", new Date());
    const fromDate = parse(target, "yyyy-MM-dd'T'HH:mm", new Date());
    if (!fromDate || !toDate) return false;
    if(fromDate.getTime() >= toDate.getTime()){
      return true;
    } else {
      return false;
    }
  },
  message: "{_field_}は、{target}と同じか前の日時を入力ください"
};
const datehourAfter = {
  params: ["target"],
  validate: (value, params) => {
    const target = params["target"];
    const toDate = parse(value, "yyyy-MM-dd'T'HH:mm", new Date());
    const fromDate = parse(target, "yyyy-MM-dd'T'HH:mm", new Date());
    if (!fromDate || !toDate) return false;
    if(fromDate.getTime() <= toDate.getTime()){
      return true;
    } else {
      return false;
    }
  },
  message: "{_field_}は、{target}と同じか後の日時を入力ください"
};
extend('required', required);
extend('numeric', numeric);
extend('eisu', eisu);
extend('email', email);
extend('max', max);
extend('min', min);
extend('confirmed', confirmed);
extend('taion', taion);
extend("checkboxRequired", checkboxRequired);
extend("dateAfter", dateAfter);
extend("datehourBefore", datehourBefore);
extend("datehourAfter", datehourAfter);
localize('ja', ja);

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};