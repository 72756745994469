  <template>
    <v-container fluid>
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card
          class="d-flex justify-center flex-column mx-auto my-6 flat"
          width="374"
          color="#fff"
        >
          <img :src="require('@/assets/logo.svg')" alt="Logo" class="logo" />
          <v-card-title class="d-flex justify-center pa-0 mt-6"
            >パスワード再設定</v-card-title
          >
          <v-card-text class="d-flex justify-center flex-column">
            <div class="mb-2">
              <ValidationProvider
                name="パスワード"
                rules="required|min:8|max:100|confirmed:confirm"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="password"
                  prepend-icon="$cet_required"
                  :type="'Password'"
                  label="パスワード"
                  placeholder="パスワード"
                  :error-messages="errors[0]"
                  outlined
                  single-line
                  dense
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </div>
            <div class="mb-2">
              <ValidationProvider
                name="パスワード再入力"
                rules="required|min:8|max:100"
                v-slot="{ errors }"
                vid="confirm"
              >
                <v-text-field
                  v-model="password2"
                  prepend-icon="$cet_required"
                  :type="'Password'"
                  label="パスワード再入力"
                  placeholder="パスワード再入力"
                  :error-messages="errors[0]"
                  outlined
                  single-line
                  dense
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </div>
            <div class="text-center">
              <v-btn class="primary" @click="passwordChange" :disabled="invalid">
                更新
              </v-btn>
            </div>
            <div class="text-danger">{{ error }}</div>
          </v-card-text>
          <v-card-text class="d-flex justify-center flex-column">
            <a style="color:#000;" href="#" @click.prevent.stop="toLoginPage()">ログイン画面へ</a>
          </v-card-text>
        </v-card>
      </ValidationObserver>
    </v-container>
  </template>
    
  <script>
  import { getFunctions, httpsCallable } from "firebase/functions";
  export default {
    props: ['collisionId'],
    data() {
      return {
        password: "",
        password2: "",
      };
    },
    // 初期表示時データ読み込み
    async created() {
      var data = {
        funcName: "initPasswordReset",
        collisionId: this.collisionId,
      }
      // ローディング開始
      this.$root.loading = true;
      // ID認証
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomePublic = httpsCallable(functions, "schoolAidHomePublic");
      await schoolAidHomePublic(data).then((res) => {
        if (res.data == "failure") {
          // ログイン画面へ
          console.log("failure");
          this.$router.push("/");
        } else {
          // 入力を受け付ける
          console.log("success");
        }
      }).catch(() => {
        this.$root.loading = false;
      });

      // ローディング終了
      this.$root.loading = false;
    },
    methods: {  
      // パスワード再設定登録処理
      passwordChange() {
        console.log("登録");
        var data = {
          funcName: "savePasswordReset",
          password: this.password,
          collisionId: this.collisionId,
        };
        this.$root.loading = true;
        const functions = getFunctions(this.$firebase);
        functions.region = "asia-northeast1";
        const schoolAidHomePublic = httpsCallable(functions, "schoolAidHomePublic");
        schoolAidHomePublic(data).then((res) => {
          // ローディング非表示
          this.$root.loading = false;
          if (res.data == "failure") {
            alert("パスワードの変更に失敗しました。");
          } else {
            alert("パスワードの変更を受け付けました。");
            // ログイン画面へ
            this.$router.push("/");
          }
        }).catch((e) => {
          this.error = e.message;
          this.$root.loading = false;
        });
      },
      // ログイン画面へ
      toLoginPage() {
        this.$router.push("/");
      },
    },
  };
  </script>

  <style>
  .logo {
    display: block;
    position: relative;
    overflow: hidden;
  }
  </style>