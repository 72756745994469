<template>
  <v-container fluid>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-2 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 my-2">
          アンケート編集
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="d-flex flex-column">
          <v-row dense>
            <v-col cols="12" sm="7" md="8" lg="8" xl="8" class="mb-2 text-subtitle-1">
              対象グループ：{{ groupName }}
            </v-col>
            <v-col cols="12" sm="5" md="4" lg="4" xl="4" class="d-flex justify-end mb-2">

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small color="accent" v-bind="attrs" v-on="on" class="ma-2 black--text" @click="onClickCopySurvey">コピー</v-btn>
                </template>
                <div>内容をコピーして新しいアンケートを作成します</div>
                <div>※添付ファイルは引継ぎません</div>
              </v-tooltip>
            </v-col>
          </v-row>
        
          <ValidationProvider name="ユーザー区分" rules="required|checkboxRequired" v-slot="{ errors }">
            <v-row dense>
              <v-col cols="12" class="d-flex mb-2">
                <v-row>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3" class="d-flex">
                    <v-icon v-if="isTargetSelect == false">$cet_required</v-icon><div class="ml-2">対象ユーザー区分選択</div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-2" v-bind="attrs" v-on="on" color="orange">mdi-help-circle-outline</v-icon>
                      </template>
                      <div>グループに所属するどのユーザー区分へ</div>
                      <div>アンケートを依頼するか選択してください</div>
                    </v-tooltip>
                  </v-col>  
                  <v-col cols="4" sm="3" md="3" lg="3" xl="3" class="d-flex">
                    <v-checkbox v-model="selectedUserKbn"
                      label="生徒"
                      value="0"
                      class="mt-0"
                      :disabled="isTargetSelect == true || selectedUserKbnOld.has('0')"
                      hide-details="auto"
                    ></v-checkbox><div class="ml-2 mt-1" v-if="isTargetSelect">({{ studentIds.length }}人)</div>
                  </v-col>
                  <v-col cols="4" sm="3" md="3" lg="3" xl="2" class="d-flex">
                    <v-checkbox v-model="selectedUserKbn"
                      label="保護者"
                      value="1"
                      class="mt-0"
                      :disabled="isTargetSelect == true || selectedUserKbnOld.has('1')"
                      hide-details="auto"
                    ></v-checkbox><div class="ml-2 mt-1" v-if="isTargetSelect">({{ parentIds.length }}人)</div>
                  </v-col>
                  <v-col cols="4" sm="3" md="3" lg="3" xl="3" class="d-flex">
                    <v-checkbox v-model="selectedUserKbn"
                      label="教員"
                      value="8"
                      class="mt-0"
                      :disabled="isTargetSelect == true || selectedUserKbnOld.has('8')"
                      hide-details="auto"
                    ></v-checkbox><div class="ml-2 mt-1" v-if="isTargetSelect">({{ teacherIds.length }}人)</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <p v-if="isTargetSelect == false" class="error">{{ errors[0] }}</p>
          </ValidationProvider>
          <v-divider></v-divider>
          <v-row dense>
            <v-col cols="12" class="d-flex mt-4 justify-end">
              下書き
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="green" v-bind="attrs" v-on="on" small class="black--text mx-2" @click="openDraftSelect">参照</v-btn>
                </template>
                <span>保存されている下書き一覧を表示します</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="green" v-bind="attrs" v-on="on" small class="black--text mx-2" @click="addDraft">保存</v-btn>
                </template>
                <span>タイトルと本文を下書きとして保存します</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <ValidationProvider name="アンケートタイトル" rules="required|max:50" v-slot="{ errors }">
            <v-row dense>
              <v-col cols="12" class="d-flex mb-0 mt-4">
                <v-text-field
                  v-model="title"
                  prepend-icon="$cet_required"
                  label="アンケートタイトル"
                  placeholder=""
                  :error-messages="errors[0]"
                  outlined
                  dense
                  :counter="50"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </ValidationProvider>
          <ValidationProvider name="アンケート本文" rules="required|max:3000" v-slot="{ errors }">
            <v-row dense>
              <v-col cols="12" class="d-flex mb-0">
                <v-textarea
                  v-model="body"
                  prepend-icon="$cet_required"
                  label="アンケート本文"
                  placeholder=""
                  :error-messages="errors[0]"
                  outlined
                  dense
                  auto-grow
                  :counter="3000"
                  hide-details="auto"
                ></v-textarea>
              </v-col>
            </v-row>
          </ValidationProvider>

          <ValidationProvider name="公開開始日" rules="required" v-slot="{ errors }">
            <v-row dense>
              <v-col class="d-flex mb-2" cols="12" sm="12" md="12" lg="12" xl="12">
                <v-text-field
                  v-model="openDate"
                  prepend-icon="$cet_required"
                  label="公開開始日"
                  type="datetime-local"
                  placeholder=""
                  :error-messages="errors[0]"
                  outlined
                  dense
                  :disabled="lockOpenDateFlg"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </ValidationProvider>

          <ValidationProvider name="回答締切日" rules="required|datehourAfter:@公開開始日|datehourBefore:@公開終了日" v-slot="{ errors }">
            <v-row dense>
              <v-col class="d-flex mb-2" cols="12" sm="12" md="12" lg="12" xl="12">
                <v-text-field
                  v-model="limitDate"
                  prepend-icon="$cet_required"
                  label="回答締切日"
                  placeholder=""
                  type="datetime-local"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-checkbox class="ml-2 mt-1 hidden-xs-only" v-model="limitBlockFlg" label="締切後は回答不可" hide-details="auto" />
                    </span>
                  </template>
                  <div>チェックすると回答締切日から公開終了日の間に</div>
                  <div>対象ユーザーは登録・更新ができません</div>
                  <div>※回答内容の閲覧は公開終了日まで可能です</div>
                </v-tooltip>
              </v-col>
              <v-col class="d-flex mb-3 justify-end d-sm-none" cols="12">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-checkbox class="my-0" v-model="limitBlockFlg" label="締切後は回答不可" hide-details="auto" />
                    </span>
                  </template>
                  <div>チェックすると回答締切日から公開終了日の間に</div>
                  <div>対象ユーザーは登録・更新ができません</div>
                  <div>※回答内容の閲覧は公開終了日まで可能です</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </ValidationProvider>

          <ValidationProvider name="公開終了日" rules="required|datehourAfter:@回答締切日" v-slot="{ errors }">
            <v-row dense>
              <v-col cols="12" class="d-flex mb-2">
                <v-text-field
                  v-model="closeDate"
                  prepend-icon="$cet_required"
                  label="公開終了日"
                  placeholder=""
                  type="datetime-local"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </ValidationProvider>

          <v-divider></v-divider>
          <v-row dense>
            <v-col cols="12" class="d-flex my-3">
              <v-icon>$cet_required</v-icon><span class="ml-2">設問</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-2" v-bind="attrs" v-on="on" color="orange">mdi-help-circle-outline</v-icon>
                </template>
                <span>非公開かつ回答をクリアしてあれば編集可能です</span>
              </v-tooltip>
            </v-col>
          </v-row>
          
          <v-card v-if="!questionList.length" class="ml-0 mt-0 mb-4">
            <div class="ml-2 mt-2">
              <v-row no-gutters>
                <v-col cols="12" class="d-flex justify-center text-h6 red--text">
                  設問がありません
                </v-col>
              </v-row>
            </div>
          </v-card>

          <!-- アンケートの明細定義 -->
          <v-card v-for="(question, index) in questionList" :key="question.id" class="ml-0 mt-0 mb-4">
            
            <!-- 文章回答のアンケート -->
            <div class="ml-2 mt-2" v-if="question.type == '0'">
              <!-- ヘッダー -->
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="d-flex pl-3">
                  <div>{{ (index + 1) }} 問目：{{ question.typeName }}</div>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="d-flex justify-end">
                  <v-checkbox v-model="question.imageSetFlg" class="mt-0 mb-2 mr-2" dense hide-details :disabled="questionDisabled">
                    <template v-slot:label>
                      <div class="my-1 text-caption">
                        画像を添える(上限:5MB)
                      </div>
                    </template>
                  </v-checkbox>
                  <v-checkbox v-model="question.required" class="mt-0 mb-2 mr-2" dense hide-details :disabled="questionDisabled">
                    <template v-slot:label>
                      <div class="my-1 text-caption">
                        回答必須に設定する
                      </div>
                    </template>
                  </v-checkbox>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" small class="info mb-2 mr-2" @click="removeQuestion(question.id)" :disabled="questionDisabled">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <span>設問を削除します</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <!-- 設問 -->
              <ValidationProvider name="質問" rules="required|max:400" v-slot="{ errors }">
                <v-row no-gutters>
                  <v-col cols="12" class="py-0 pr-2">
                    <v-textarea
                      v-model="question.content"
                      prepend-icon="$cet_required"
                      label="質問"
                      placeholder=""
                      :error-messages="errors[0]"
                      outlined
                      dense
                      :counter="400"
                      hide-details="auto"
                      :disabled="questionDisabled"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </ValidationProvider>
              <!-- 画像設定 -->
              <ValidationProvider v-if="question.imageSetFlg" name="画像設定" rules="required" v-slot="{ errors }">
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex py-0 pr-2 mb-3">
                    <v-icon class="mr-2">$cet_required</v-icon>
                    <ImgInputer v-model="question.imageId" :readOnly="questionDisabled" :errorMsg="errors[0]"></ImgInputer>
                  </v-col>
                </v-row>
              </ValidationProvider>
              <ValidationProvider name="回答文字数制限" rules="required|numeric|max:4" v-slot="{ errors }">
                <v-row  no-gutters>
                  <v-col cols="12" class="pr-0">
                    <v-text-field
                      v-model="question.charLimit"
                      prepend-icon="$cet_required"
                      label="回答文字数制限"
                      outlined
                      class="number-input pr-2"
                      :error-messages="errors[0]"
                      dense
                      :counter="4"
                      hide-details="auto"
                      @blur="formatNum(question)"
                      :disabled="questionDisabled"
                    >
                      <template v-slot:append>
                        <div class="my-1 text-caption">
                          文字
                        </div>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </ValidationProvider>
            </div>

            <!-- チェックボックス回答のアンケート -->
            <div class="ml-2 mt-2" v-if="question.type == '1'">
              <!-- ヘッダー -->
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="d-flex pl-3">
                  <div>{{ (index + 1) }} 問目：{{ question.typeName }}</div>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="d-flex justify-end">
                  <v-checkbox v-model="question.etcInputFlg" class="mt-0 mb-2 mr-2" dense hide-details :disabled="questionDisabled">
                    <template v-slot:label>
                      <div class="my-1 text-caption">
                        その他入力を追加する
                      </div>
                    </template>
                  </v-checkbox>
                  <v-checkbox v-model="question.imageSetFlg" class="mt-0 mb-2 mr-2" dense hide-details :disabled="questionDisabled">
                    <template v-slot:label>
                      <div class="my-1 text-caption">
                        画像を添える(上限:5MB)
                      </div>
                    </template>
                  </v-checkbox>
                  <v-checkbox v-model="question.required" class="mt-0 mb-2 mr-2" dense hide-details :disabled="questionDisabled">
                    <template v-slot:label>
                      <div class="my-1 text-caption">
                        回答必須に設定する
                      </div>
                    </template>
                  </v-checkbox>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" small class="info mb-2 mr-2" @click="removeQuestion(question.id)" :disabled="questionDisabled">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <span>設問を削除します</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <!-- 設問 -->
              <ValidationProvider name="質問" rules="required|max:400" v-slot="{ errors }">
                <v-row no-gutters>
                  <v-col cols="12" class="py-0 pr-2">
                    <v-textarea
                      v-model="question.content"
                      prepend-icon="$cet_required"
                      label="質問"
                      placeholder=""
                      :error-messages="errors[0]"
                      outlined
                      dense
                      :counter="400"
                      hide-details="auto"
                      :disabled="questionDisabled"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </ValidationProvider>
              <!-- 画像設定 -->
              <ValidationProvider v-if="question.imageSetFlg" name="画像設定" rules="required" v-slot="{ errors }">
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex py-0 pr-2 mb-3">
                    <v-icon class="mr-2">$cet_required</v-icon>
                    <ImgInputer v-model="question.imageId" :readOnly="questionDisabled" :errorMsg="errors[0]"></ImgInputer>
                  </v-col>
                </v-row>
              </ValidationProvider>
              <!-- 選択肢 -->
              <ValidationProvider v-for="checkOption in question.option" :key="checkOption.id" name="選択肢" rules="required|max:50" v-slot="{ errors }">
                <v-row  no-gutters>
                  <v-col cols="12" class="d-flex py-0">
                    <v-checkbox placeholder="" dense hide-details disabled background-color="grey lighten-2"></v-checkbox>
                    <v-text-field
                      v-model="checkOption.title"
                      prepend-icon="$cet_required"
                      label="選択肢"
                      :error-messages="errors[0]"
                      outlined
                      dense
                      :counter="50"
                      hide-details="auto"
                      :disabled="questionDisabled"
                    ></v-text-field>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" x-small v-if="checkOption.id != 0" class="ma-2 warning" @click="removeCheckOption(question.id, checkOption.id)" :disabled="questionDisabled">
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </template>
                      <span>選択肢を削除します</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </ValidationProvider>
              <v-row no-gutters v-if="question.etcInputFlg == true">
                <v-col cols="12" class="d-flex py-0">
                  <v-checkbox placeholder="" dense hide-details disabled background-color="grey lighten-2"></v-checkbox>
                  <div class="pa-2">
                    その他
                  </div>
                  <v-text-field
                    label="回答者の自由入力"
                    outlined
                    dense
                    disabled
                    :counter="50"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- 追加ボタン -->
              <v-row no-gutters>
                <v-col cols="12" class="d-flex justify-end py-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" x-small class="mx-2 mb-2 primary" @click="addCheckOption(question.id)" :disabled="questionDisabled">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>選択肢を追加します</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>

            <!-- ラジオボタン回答のアンケート -->
            <div class="ml-2 mt-2" v-if="question.type == '2'">
              <!-- ヘッダー -->
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="d-flex pl-3">
                  <div>{{ (index + 1) }} 問目：{{ question.typeName }}</div>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="d-flex justify-end">
                  <v-checkbox v-model="question.etcInputFlg" class="mt-0 mb-2 mr-2" dense hide-details :disabled="questionDisabled">
                    <template v-slot:label>
                      <div class="my-1 text-caption">
                        その他入力を追加する
                      </div>
                    </template>
                  </v-checkbox>
                  <v-checkbox v-model="question.imageSetFlg" class="mt-0 mb-2 mr-2" dense hide-details :disabled="questionDisabled">
                    <template v-slot:label>
                      <div class="my-1 text-caption">
                        画像を添える(上限:5MB)
                      </div>
                    </template>
                  </v-checkbox>
                  <v-checkbox v-model="question.required" class="mt-0 mb-2 mr-2" dense hide-details :disabled="questionDisabled">
                    <template v-slot:label>
                      <div class="my-1 text-caption">
                        回答必須に設定する
                      </div>
                    </template>
                  </v-checkbox>
                  <!-- 設問削除ボタン -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" small class="info mb-2 mr-2" @click="removeQuestion(question.id)" :disabled="questionDisabled">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <span>設問を削除します</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <!-- 設問 -->
              <ValidationProvider name="質問" rules="required|max:400" v-slot="{ errors }">
                <v-row no-gutters>
                  <v-col cols="12" class="py-0 pr-2">
                    <v-textarea
                      v-model="question.content"
                      prepend-icon="$cet_required"
                      label="質問"
                      placeholder=""
                      :error-messages="errors[0]"
                      outlined
                      dense
                      :counter="400"
                      hide-details="auto"
                      :disabled="questionDisabled"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </ValidationProvider>
              <!-- 画像設定 -->
              <ValidationProvider v-if="question.imageSetFlg" name="画像設定" rules="required" v-slot="{ errors }">
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex py-0 pr-2 mb-3">
                    <v-icon class="mr-2">$cet_required</v-icon>
                    <ImgInputer v-model="question.imageId" :readOnly="questionDisabled" :errorMsg="errors[0]"></ImgInputer>
                  </v-col>
                </v-row>
              </ValidationProvider>
              <!-- 選択肢 -->
              <ValidationProvider v-for="radioOption in question.option" :key="radioOption.id" name="選択肢" rules="required|max:50" v-slot="{ errors }">
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex py-0">
                    <v-radio-group class="align-start mt-1" background-color="grey lighten-2">
                      <v-radio dense hide-details disabled />
                    </v-radio-group>
                    <v-text-field
                      v-model="radioOption.title"
                      prepend-icon="$cet_required"
                      label="選択肢"
                      :error-messages="errors[0]"
                      outlined
                      dense
                      :counter="50"
                      hide-details="auto"
                      :disabled="questionDisabled"
                    ></v-text-field>
                    <!-- 選択肢削除ボタン -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" x-small v-if="radioOption.id > 1" class="ma-2 warning" @click="removeRadioOption(question.id, radioOption.id)" :disabled="questionDisabled">
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </template>
                      <span>選択肢を削除します</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </ValidationProvider>
              <!-- 選択肢追加ボタン -->
              <v-row no-gutters v-if="question.etcInputFlg == true">
                <v-col cols="12" class="d-flex py-0">
                  <v-radio-group class="align-start mt-1" background-color="grey lighten-2">
                    <v-radio dense hide-details disabled />
                  </v-radio-group>
                  <div class="pa-2">
                    その他
                  </div>
                  <v-text-field
                    label="回答者の自由入力"
                    outlined
                    dense
                    disabled
                    :counter="50"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="d-flex justify-end py-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" x-small class="mx-2 mb-2 primary" @click="addRadioOption(question.id)" :disabled="questionDisabled">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>選択肢を追加します</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>

            <!-- １行回答のアンケート -->
            <div class="ml-2 mt-2" v-if="question.type == '3'">
              <!-- ヘッダー -->
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="d-flex pl-3">
                  <div>{{ (index + 1) }} 問目：{{ question.typeName }}</div>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="d-flex justify-end">
                  <v-checkbox v-model="question.imageSetFlg" class="mt-0 mb-2 mr-2" dense hide-details :disabled="questionDisabled">
                    <template v-slot:label>
                      <div class="my-1 text-caption">
                        画像を添える(上限:5MB)
                      </div>
                    </template>
                  </v-checkbox>
                  <v-checkbox v-model="question.required" class="mt-0 mb-2 mr-2" dense hide-details :disabled="questionDisabled">
                    <template v-slot:label>
                      <div class="my-1 text-caption">
                        回答必須に設定する
                      </div>
                    </template>
                  </v-checkbox>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" small class="info mb-2 mr-2" @click="removeQuestion(question.id)" :disabled="questionDisabled">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <span>設問を削除します</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <!-- 設問 -->
              <ValidationProvider name="質問" rules="required|max:400" v-slot="{ errors }">
                <v-row no-gutters>
                  <v-col cols="12" class="py-0 pr-2">
                    <v-textarea
                      v-model="question.content"
                      prepend-icon="$cet_required"
                      label="質問"
                      placeholder=""
                      :error-messages="errors[0]"
                      outlined
                      dense
                      :counter="400"
                      hide-details="auto"
                      :disabled="questionDisabled"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </ValidationProvider>
              <!-- 画像設定 -->
              <ValidationProvider v-if="question.imageSetFlg" name="画像設定" rules="required" v-slot="{ errors }">
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex py-0 pr-2 mb-3">
                    <v-icon class="mr-2">$cet_required</v-icon>
                    <ImgInputer v-model="question.imageId" :readOnly="questionDisabled" :errorMsg="errors[0]"></ImgInputer>
                  </v-col>
                </v-row>
              </ValidationProvider>
              <ValidationProvider name="回答文字数制限" rules="required|numeric|max:4" v-slot="{ errors }">
                <v-row  no-gutters>
                  <v-col cols="12" class="pr-0">
                    <v-text-field
                      v-model="question.charLimit"
                      prepend-icon="$cet_required"
                      label="回答文字数制限"
                      outlined
                      class="number-input pr-2"
                      :error-messages="errors[0]"
                      dense
                      :counter="4"
                      hide-details="auto"
                      @blur="formatNum(question)"
                      :disabled="questionDisabled"
                    >
                      <template v-slot:append>
                        <div class="my-1 text-caption">
                          文字
                        </div>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </ValidationProvider>

              <ValidationProvider name="入力チェック" rules="required" v-slot="{ errors }" :skipIfEmpty="false">
                <v-row  no-gutters>
                  <v-col cols="12" class="pr-2">
                    <v-select
                      label="入力チェック"
                      :items="checkTypeList"
                      class="pb-2"
                      item-text="name"
                      item-value="code"
                      v-model="question.checkType"
                      :error-messages="errors[0]"
                      outlined
                      dense
                      hide-details="auto"
                      :disabled="questionDisabled"
                    ></v-select>
                  </v-col>
                </v-row>
              </ValidationProvider>
            </div>
          </v-card>

          <v-row no-gutters>
            <v-col cols="12" sm="3" md="3" lg="3" xl="3" class="d-flex justify-center mb-0">
              <v-btn small class="ma-2 primary" @click="addInputRadio()" :disabled="questionDisabled">
                <div>＋[単選択型]設問追加</div>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3" xl="3" class="d-flex justify-center mb-0">
              <v-btn small class="ma-2 primary" @click="addInputCheck()" :disabled="questionDisabled">
                <div>＋[複数選択型]設問追加</div>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3" xl="3" class="d-flex justify-center mb-0">
              <v-btn small class="ma-2 primary" @click="addInputText()" :disabled="questionDisabled">
                <div>＋[文章型]設問追加</div>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3" xl="3" class="d-flex justify-center mb-0">
              <v-btn small class="ma-2 primary" @click="addInputLineText()" :disabled="questionDisabled">
                <div>＋[一行型]設問追加</div>
              </v-btn>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="d-flex mb-0">
              <v-checkbox class="mt-0" v-model="clearFlg" label="既存の回答をクリアする" hide-details="auto" />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="d-flex mb-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-checkbox class="mt-0" v-model="anonymousFlg" label="匿名でアンケート依頼する" hide-details="auto" disabled/>
                  </span>
                </template>
                <div>チェックすると回答をExcel出力する際に</div>
                <div>個人が特定されない形で出力されます</div>
              </v-tooltip>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="d-flex mb-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-checkbox class="mt-0" v-model="allowUpdateFlg" label="回答後の更新を許可する" hide-details="auto" />
                  </span>
                </template>
                <div>チェックすると対象ユーザーは何回でも更新可能となります</div>
                <div>チェックをはずすと１回のみ登録可能となります</div>
                <div>※登録後は公開期間終了まで回答内容の閲覧が可能です</div>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pt-0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    ファイル名
                  </th>
                  <th class="text-right">
                    削除
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="viewfileList.length == 0">
                  <td class="text-left">添付ファイル無し</td>
                  <td class="text-right"></td>
                </tr>
                <tr v-for="item in viewfileList" :key="item.attachmentId">
                  <td class="text-left"><a @click="downloadItem(item)" class="text-decoration-none">{{ item.fileName }}</a></td>
                  <td class="text-right">
                    <v-row class="justify-end pb-4">
                      <v-checkbox
                        v-model="delFileList"
                        :value="item.attachmentId"
                        :id="item.attachmentId"
                        color="primary"
                        hide-details="auto"
                      />
                  </v-row>
                  </td>     
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <dashboard :uppy="uppy" :props="{
              theme: 'light',
              inline: true,
              width: 3000,
              height: 350,
              disableThumbnailGenerator:true,
              showRemoveButtonAfterComplete:true,
              proudlyDisplayPoweredByUppy:false,
            }" />
            ※添付ファイルは(容量：50MB、個数：10)が上限となっております
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey" class="mr-auto mt-0 black--text" @click="canselSurveyEdit"  width="45%">戻る</v-btn>
          <v-btn color="accent" class="ml-auto mt-0 black--text" @click="saveSurveyEdit" :disabled="invalid || (!tounendoFlg)" width="45%">更新</v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
    <!-- 下書き選択ダイアログ -->
    <v-dialog
      v-model="viewDraftSelectDialog"
      max-width="864"
    >
      <DraftSelect
        draftType="1"
        v-bind:openTime="openTime"
        @closeDialog="closeDialog"
        @setDraft="setDraft"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { Dashboard } from '@uppy/vue'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import ja_JP from '../../plugins/puppy_lang'
import FirebaseCloudStorage from "../../plugins/FirebaseCloudStorage";
import { getStorage } from "firebase/storage";
import { saveAsCommon } from '../../common';
import { httpsCallable, getFunctions } from "firebase/functions";

import * as Validator from 'validatorjs';
Validator.useLang('ja');
import DraftSelect from '../common/DraftSelect.vue'
import ImgInputer from './components/ImgInputer.vue'

export default {
  props: ['id'],
  // ファイルアップロード実装 START-------------------------
  components: {
    Dashboard,
    DraftSelect,
    ImgInputer,
  },
  computed: {
    uppy() {
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var uppy = new Uppy()
      .use(FirebaseCloudStorage, { storage, storagePath });
      return uppy;
    },
  },
  mounted() {
    this.uppy.on('upload', () => {
      this.$root.loading = true;
    });
    this.uppy.on('upload-success', (file) => {
      // 配列にファイルを追加
      this.addFileList.add(file.meta.refId);
    });
    this.uppy.on('complete', () => {
      this.$root.loading = false;
    });
    this.uppy.on('file-removed', (file) => {
      // 配列からファイルを削除
      this.addFileList.delete(file.meta.refId);
    });
    // 50MB制限+10ファイル制限
    this.uppy.setOptions({
      debug: true,
      locale: ja_JP,
      autoProceed: true,
      restrictions: {
        maxFileSize: (50 * 1024 * 1024),
        maxNumberOfFiles: 10,
      },
    });
  },
  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },
  // ファイルアップロード実装 END-------------------------
  data() {
    return {

      // 対象グループ
      groupId: "",
      groupName: "",

      // 対象選択フラグ
      isTargetSelect: false,

      // 下書き選択ダイアログ
      viewDraftSelectDialog: false,
      openTime: 0,

      // 対象ユーザー区分
      selectedUserKbn: [],
      selectedUserKbnOld: new Set(),

      // 対象選択ユーザーID
      studentIds: [],
      parentIds: [],
      teacherIds: [],

      title: "",
      body: "",
      openDate: "" ,
      closeDate : "",
      limitDate : "",
      limitBlockFlg: false,
      viewfileList: [],
      addFileList: new Set(),
      delFileList: [],
      clearFlg: false,

      questionList: [],

      checkTypeList:[
        { code: "0", name: "文字数制限のみ" },
        { code: "1", name: "文字数＋半角数字限定" },
        { code: "2", name: "文字数＋半角英数限定" },
      ],

      questionDisabled: false,
      tounendoFlg: false,
      lockOpenDateFlg: false,
      anonymousFlg: false,
    };
  },
  async created() {
    // ローディング開始
    this.$root.loading = true;

    const data = {
      funcName: "loadSurveyEdit",
      surveyId: this.id
    };

    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
    await schoolAidHomeLogined(data).then((res) => {
      this.groupId = res.data.groupId;
      this.groupName = res.data.groupName;
      
      this.selectedUserKbn = res.data.selectedUserKbn;
      // ユーザー区分削除抑制用
      this.selectedUserKbnOld = new Set(res.data.selectedUserKbn);
      this.isTargetSelect = res.data.targetLimitedFlg;

      this.title = res.data.title;
      this.body = res.data.body;
      this.openDate = res.data.openDate;
      this.closeDate = res.data.closeDate;
      this.limitDate = res.data.limitDate;
      this.limitBlockFlg = res.data.limitBlockFlg;
      this.allowUpdateFlg = res.data.allowUpdateFlg;
      
      this.viewfileList = res.data.fileList;
      this.studentIds = res.data.studentIds;
      this.parentIds = res.data.parentIds;
      this.teacherIds = res.data.teacherIds;

      this.questionList = res.data.questionList;
      this.questionDisabled = res.data.questionDisabled;
      this.tounendoFlg = res.data.tounendoFlg;
      this.lockOpenDateFlg = res.data.lockOpenDateFlg;
      this.anonymousFlg = res.data.anonymousFlg;

      // ローディング終了
      this.$root.loading = false;
    }).catch((e) => {
      console.log(e.message);
      this.$root.loading = false;
    });
  },
  methods: {
    // テキストアンケート追加
    addInputText () {
      var createId;
      if (this.questionList.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id: createId,
        content: "",
        type: "0",
        typeName: "[文章型]設問",
        charLimit: "",
        required: false,
        checkType: "",
        etcInputFlg: false,
        imageSetFlg: false,
        imageId: "",
        option: [],
      };
      this.questionList.push(row);
    },
  
    // チェックボックスアンケート追加
    addInputCheck () {
      var createId;
      if (this.questionList.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id: createId,
        content: "",
        type: "1",
        typeName: "[複数選択型]設問",
        charLimit: null,
        required: false,
        checkType: "",
        etcInputFlg: false,
        imageSetFlg: false,
        imageId: "",
        option: [{
          id : 0,
          title : "",
        }],
      };
      this.questionList.push(row);
    },
  
    // ラジオボタンアンケート追加
    addInputRadio () {
      var createId;
      if (this.questionList.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id : createId,
        content: "",
        type: "2",
        typeName: "[単選択型]設問",
        charLimit: null,
        required: true,
        checkType: "",
        etcInputFlg: false,
        imageSetFlg: false,
        imageId: "",
        option: [{
          id : 0,
          title : "",
        },
        {
          id : 1,
          title : "",
        }],
      };
      this.questionList.push(row);
    },

    // テキスト(１行)アンケート追加
    addInputLineText () {
      var createId;
      if (this.questionList.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id: createId,
        content: "",
        type: "3",
        typeName: "[一行型]設問",
        charLimit: "",
        required: false,
        checkType: "0",
        etcInputFlg: false,
        imageSetFlg: false,
        imageId: "",
        option: [],
      };
      this.questionList.push(row);
    },

    // アンケート削除
    removeQuestion (id) {
      this.questionList = this.questionList.filter(function(item) {
        return item.id != id;
      });
    },
  
    // チェックボックス選択肢追加
    addCheckOption (id) {
      var index = this.questionList.findIndex(item => item.id == id);
      var createId;
      if (this.questionList[index].option.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList[index].option.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id : createId,
        title : "",
      };
      this.questionList[index].option.push(row);
    },
  
    // ラジオボタン選択肢追加
    addRadioOption (id) {
      var index = this.questionList.findIndex(item => item.id == id);
      var createId;
      if (this.questionList[index].option.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList[index].option.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id : createId,
        title : "",
      };
      this.questionList[index].option.push(row);
    },
  
    // チェックボックス選択肢削除
    removeCheckOption (questionId, checkId) {
      var index = this.questionList.findIndex(item => item.id == questionId);
      this.questionList[index].option = this.questionList[index].option.filter(function(item) {
        return item.id != checkId;
      });
    },
  
    // ラジオボタン選択肢削除
    removeRadioOption (checkId, radioId) {
      var index = this.questionList.findIndex(item => item.id == checkId);
      this.questionList[index].option = this.questionList[index].option.filter(function(item) {
        return item.id != radioId;
      });
    },

    // アンケート情報編集
    async saveSurveyEdit() {
      for (var i = 0; i < this.questionList.length; i++) {
        if (this.questionList[i].type == "1" || this.questionList[i].type == "2") {
          if (this.questionList[i].option.length == 0){
            alert("選択肢を作成してください。");
            return;
          }
        }
      }
      if (this.questionList.length == 0) {
        alert("設問を作成してください");
        return;
      }
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "saveSurveyEdit",
        surveyId: this.id,
        selectedUserKbn: this.selectedUserKbn, 
        title: this.title,
        body: this.body,
        openDate: this.openDate,
        closeDate: this.closeDate,
        limitDate: this.limitDate,
        limitBlockFlg: this.limitBlockFlg,
        allowUpdateFlg: this.allowUpdateFlg,
        questionList : this.questionList,
        delFileList : this.delFileList,
        addFileList : Array.from(this.addFileList),
        clearFlg: this.clearFlg,
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        if (res.data == "success"){
          alert("更新しました。");
        }  
        else {
          alert("データ更新に失敗しました。");
        }
      });
      this.$router.push({
        name: "SurveyList",
      });
    },
    canselSurveyEdit(){
      this.$router.push({
        name: "SurveyList",
      });
    },
    // アンケートコピー
    onClickCopySurvey() {
      if(this.tounendoFlg == true){
        this.$router.push({
          name: "SurveyRegist",
          params: {
            groupId: this.groupId,
            groupName: this.groupName,
            paramUserKbns:this.selectedUserKbn,
            paramTitle:this.title,
            paramBody:this.body,
            paramOpenDate:this.openDate,
            paramCloseDate:this.closeDate,
            paramLimitDate:this.limitDate,
            paramLimitBlockFlg:this.limitBlockFlg,
            paramAllowUpdateFlg:this.allowUpdateFlg,
            paramAnonymousFlg:this.anonymousFlg,
            paramQuestionList:this.questionList,
            paramStudentIds:this.studentIds,
            paramParentIds:this.parentIds,
            paramTeacherIds:this.teacherIds,
          }
        });
      } else {
        this.$router.push({
          name: "SurveyRegist",
          params: {
            groupId: this.groupId,
            groupName: this.groupName,
            paramUserKbns:this.selectedUserKbn,
            paramTitle:this.title,
            paramBody:this.body,
            paramOpenDate:this.openDate,
            paramCloseDate:this.closeDate,
            paramLimitDate:this.limitDate,
            paramLimitBlockFlg:this.limitBlockFlg,
            paramAllowUpdateFlg:this.allowUpdateFlg,
            paramAnonymousFlg:this.anonymousFlg,
            paramQuestionList:this.questionList,
            paramStudentIds:[],
            paramParentIds:[],
            paramTeacherIds:[],
          }
        });
      }
    },
    async downloadItem(item){
      saveAsCommon(item.url, item.fileName);
    },
    // ダイアログを閉じる
    closeDialog(){
      this.viewDraftSelectDialog = false;
    },
    // 下書き選択ダイアログを表示
    async openDraftSelect(){
      this.openTime = new Date().getTime();
      this.viewDraftSelectDialog = true;
    },
    // 下書き選択ダイアログから反映
    setDraft(data){
      this.title = data.title;
      this.body = data.body;
    },
    // 下書き保存
    async addDraft(){
      // 入力値チェック
      let rules = {
        アンケートタイトル: 'max:50',
        アンケート本文:'max:3000',
      }
      let data = {
        アンケートタイトル: this.title,
        アンケート本文: this.body,
      }
      let validation = new Validator(data, rules);
      if(validation.fails()){
        var hash = validation.errors.all();
        var errors = [];
        for (let key in hash) {
          errors.push(...hash[key]);
        }
        alert(errors.join("\r\n"));
        return;
      }
      // 両方未入力チェック
      if(!this.title && !this.body){
        alert("タイトルと本文が未入力です");
        return;
      }

      var param = {
        funcName: "addDraft",
        draftType: "1",
        title: this.title,
        body: this.body,
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data == "success") {
          alert("下書きに登録しました。");
        }
      });
    },
  },
};
</script>

<style scoped>
.number-input >>> input {
  text-align: right
}
</style>