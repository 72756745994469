<template>
  <v-container fluid>
    <!-- 課題ブロック -->
    <v-card
      class="d-flex justify-center flex-column mx-auto my-6 flat"
      width="1200"
      color="#fff"
    >
      <v-card-title class="d-flex justify-left">課題：{{ titleHW }}</v-card-title>
      <v-card-text class="d-flex justify-center flex-column">
        <p style="white-space:pre-wrap;" v-html="autoLink(bodyHW)"></p>
      </v-card-text>
    </v-card>

    <!-- 活動記録ブロック -->
    <v-card
      class="d-flex justify-center flex-column mx-auto my-6 flat"
      width="1200"
      color="#fff"
    >
      <v-card-title class="d-flex justify-left">活動記録</v-card-title>
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <ValidationProvider v-if="editNameFlg" name="活動記録名" rules="required|max:50" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-text-field
            v-model="title"
            prepend-icon="$cet_required"
            label="活動記録名"
            placeholder=""
            :error-messages="errors[0]"
            outlined
            dense
            :counter="50"
            hide-details="auto"
            ></v-text-field>
          </div>
        </ValidationProvider>
        <ValidationProvider name="本文・設問" rules="required|max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="body"
              prepend-icon="$cet_required"
              label="本文・設問"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <v-card-text justify="center">
          <dashboard :uppy="uppy" :props="{
              theme: 'light',
              inline: true,
              width: 3000,
              height: 350,
              disableThumbnailGenerator:true,
              showRemoveButtonAfterComplete:true,
              proudlyDisplayPoweredByUppy:false,
            }" />
            ※添付ファイルは(容量：50MB、個数：10)が上限となっております
        </v-card-text>
        <v-card-actions>
          <v-row class="justify-center">
            <v-col cols="6" justify="center">
              <v-btn color="accent" class="ml-auto mt-0 black--text" @click="poSavePoActivityRecordRegist(false)" :disabled="invalid" width="90%">活動記録の保存(未提出)</v-btn>
            </v-col>
            <v-col cols="6" justify="center">
              <v-btn color="accent" class="ml-auto mt-0 black--text" @click="poSavePoActivityRecordRegist(true)" :disabled="invalid" width="90%">活動記録の提出</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
    <div id="return-to-bottom"></div>
  </v-container>
</template>

<script>
import { Dashboard } from '@uppy/vue'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import ja_JP from '../../../plugins/puppy_lang'
import FirebaseCloudStorage from "../../../plugins/FirebaseCloudStorage";
import { getStorage } from "firebase/storage";
import Autolinker from 'autolinker';

import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['homeworkId'],
  // ファイルアップロード実装 START-------------------------
  components: {
    Dashboard
  },
  computed: {
    uppy() {
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var uppy = new Uppy()
      .use(FirebaseCloudStorage, { storage, storagePath });
      return uppy;
    },
  },
  mounted() {
    this.uppy.on('upload', () => {
      this.$root.loading = true;
    });
    this.uppy.on('upload-success', (file) => {
      // 配列にファイルを追加
      this.fileList.add(file.meta.refId);
    });
    this.uppy.on('complete', () => {
      this.$root.loading = false;
    });
    this.uppy.on('file-removed', (file) => {
      // 配列からファイルを削除
      this.fileList.delete(file.meta.refId);
    });
    // 50MB制限+10ファイル制限
    this.uppy.setOptions({
      debug: true,
      locale: ja_JP,
      autoProceed: true,
      restrictions: {
        maxFileSize: (50 * 1024 * 1024),
        maxNumberOfFiles: 10,
      },
    });
  },
  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },
  // ファイルアップロード実装 END-------------------------
  data() {
    return {
      groupId: "",
      groupName: "",
      titleHW: "",
      bodyHW: "",
      editNameFlg: false,
      useTemplateFlg: false,
      useCategoryFlg: false,

      title : "",
      body : "",
      fileList: new Set(),
    };
  },
  // 初期表示時データ読み込み
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var data = {
        funcName: "poLoadPoActivityRecordRegist",
        homeworkId : this.homeworkId
      }
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((doc) => {
        this.groupId = doc.data.groupId;
        this.groupName = doc.data.groupName;
        this.titleHW = doc.data.titleHW;
        this.bodyHW = doc.data.bodyHW;
        this.editNameFlg = doc.data.editNameFlg;
        this.useTemplateFlg = doc.data.useTemplateFlg;
        this.useCategoryFlg = doc.data.useCategoryFlg;
      });

      // ローディング非表示
      this.$root.loading = false;
    },
    // 活動記録保存
    async poSavePoActivityRecordRegist(submissionFlg) {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "poSavePoActivityRecordRegist",
        homeworkId: this.homeworkId,
        title: this.title,
        body: this.body,
        submissionFlg: submissionFlg,
        fileList : Array.from(this.fileList),
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        if (res.data == "success"){
          alert("活動記録を登録しました。");
        }  
        else {
          alert("データ更新に失敗しました。");
        }
      });
      this.$router.push({
        name: "StudentTopPage",
      });
    },
    autoLink(text) {
      const autolinker = new Autolinker();
      return autolinker.link(text);
    },
  },  
};
</script>