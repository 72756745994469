<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="members"
          :items-per-page="-1"
          :footer-props="{'disable-items-per-page': true}"
          dense
          disable-sort
          >
          <template #[`item.status`]="{ item }">
            <div v-if="!item.inputFlg">未入力</div>
            <div v-else-if="item.inputFlg && !item.readSchoolFlg">学校未確認</div>
            <div v-else>学校確認済</div>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn color="accent" class="black--text" large @click="toAttendanceRegist(item)">出欠連絡</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  data() {
    return {
      headers: [
        { text: "氏名", value: "name", align: "start"},
        { text: "課程", value: "kateiNm" },
        { text: "部", value: "buNm" },
        { text: "科", value: "kaNm" },
        { text: "学年", value: "gakunen" },
        { text: "学級", value: "gakkyu" },
        { text: "出席番号", value: "syussekiNo" },
        { text: "状況", value: "status" },
        { text: "早退時刻", value: "gatePassageTime" },
        { text: "", value: "actions" },
      ],
      members: [],
    };
  },
  // 初期表示時データ読み込み
  async created() {
    // ローディング開始
    this.$root.loading = true;

    // 被保護者読込
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    var ret = [];

    await schoolAidHomeLogined({funcName:"loadStudentSelect"}).then((res) => {
      res.data.forEach((user) => {
        var row = {
          studentId : user.key,
          name: user.userNm,
          buNm: user.buNm,
          kaNm: user.kaNm,
          kateiNm: user.kateiNm,

          gakunen: user.gakunen,
          gakkyu: user.gakkyu,
          syussekiNo: user.syussekiNo,
          inputFlg: user.inputFlg,
          readSchoolFlg: user.readSchoolFlg,
          gatePassageTime: user.gatePassageTime,
        };
        ret.push(row);
      });
    });

    this.members = ret;
    // ローディング終了
    this.$root.loading = false;
  },
  methods: {
    // 出欠登録/健康報告画面へ
    async toAttendanceRegist(item) {
      this.$router.push({
        name: "AttendanceRegist",
        params: {
          id:item.studentId,
        }
      });
    },
  },
};
</script>