<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          課題グループ選択
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <!-- 検索用のテキスト入力 -->
        <v-text-field
          v-model="groupFilterValue"
          label="ｸﾞﾙｰﾌﾟ名検索"
          placeholder="ｸﾞﾙｰﾌﾟ名検索"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-text-field>
      </v-col>
      <v-col cols="8" sm="8" md="8" lg="8" xl="8">
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="groups"
          :items-per-page.sync="perPage"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          disable-sort
          :loading="isGroupsLoding" loading-text="読込中"
          >
          <template #[`item.favoriteFlg`]="{ item }">
            <v-tooltip top v-if="item.favoriteFlg">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon outlined v-bind="attrs" v-on="on" @click="changeFavoriteGroup(item)"><v-icon color="orange">mdi-star</v-icon></v-btn>
              </template>
              <span>お気に入りを解除する</span>
            </v-tooltip>
            <v-tooltip top v-if="!item.favoriteFlg">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon outlined v-bind="attrs" v-on="on" @click="changeFavoriteGroup(item)"><v-icon>mdi-star-outline</v-icon></v-btn>
              </template>
              <span>お気に入りに追加する</span>
            </v-tooltip>
          </template>
          <template #[`item.toPoHomeworkRegist`]="{ item }">
            <v-btn color="accent" class="black--text" large  @click="onClicktoPoHomeworkRegist(item)">課題登録</v-btn>
          </template>
          <template #[`item.toPoTargetSelect`]="{ item }">
            <v-btn color="accent" class="black--text" large  @click="onClicktoPoTargetSelect(item)">対象選択</v-btn>
          </template>
          <template #[`item.toPoHomeworkList`]="{ item }">
            <v-btn color="accent" class="black--text" large  @click="onClicktoPoHomeworkList(item)">課題一覧</v-btn>
          </template>
          <template #[`item.toPoUserList`]="{ item }">
            <v-btn color="accent" class="black--text" large @click="onClicktoPoUserList(item)">生徒一覧</v-btn>
          </template>
          <template #[`item.toPoStoryListForGroup`]="{ item }">
            <v-btn color="accent" class="black--text" large @click="onClicktoPoStoryListForGroup(item)">学習のまとめ一覧</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
</v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  data() {
    return {
      groupFilterValue: "",
      headers: [
        { text: "グループ名", value: "groupName", filter: this.groupFilter },
        { text: "お気に入り", value: "favoriteFlg" },
        { text:"" ,value:"toPoHomeworkRegist"},
        { text:"" ,value:"toPoTargetSelect"},
        { text:"" ,value:"toPoHomeworkList"},
        { text:"" ,value:"toPoUserList"},
        { text:"" ,value:"toPoStoryListForGroup"},
      ],
      groups: [],
      isGroupsLoding: false,
      perPage: 30,
    };
  },
  watch: {
    perPage: function(newVal) {
      this.$store.dispatch('setParPageGroupSelect', newVal);
    },
  },
  // 初期表示時データ読み込み
  async created() {
    this.perPage = this.$store.state.parPageGroupSelect;  
    await this.reload();
  },
  methods: {
    // グループ名フィルタ
    groupFilter(value) {
      if (!this.groupFilterValue) {
        return true;
      }
      return value.toString().includes(this.groupFilterValue);
    },
    // ページ再読込
    async reload() {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isGroupsLoding = true;
      schoolAidHomeLogined({funcName:"poLoadGroupSelect"}).then((groups) => {
        var ret = [];
        groups.data.forEach((group) => {
          var row = {
            groupId : group.groupId,
            favoriteFlg: group.favoriteFlg,
            groupName: group.groupName,
            groupKbn: group.groupKbn,
          };
          ret.push(row);
        });
        this.groups = ret;
        this.isGroupsLoding = false;
      });
    },
    async changeFavoriteGroup(item)  {      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "changeFavoriteGroup",
        groupId: item.groupId,
        favoriteFlg: item.favoriteFlg
      };
      await schoolAidHomeLogined(data);
      this.reload();
    },
    // 課題登録
    onClicktoPoHomeworkRegist(item) {
      console.log(item.groupName, item.groupId);
      this.$router.push({
        name: "PoHomeworkRegist",
        params: {
          groupName:item.groupName,
          groupId:item.groupId,
        }
      });
    },
    // 課題登録(対象選択)
    onClicktoPoTargetSelect(item) {
      this.$router.push({
        name: "PoTargetSelect",
        params: {
          groupName:item.groupName,
          groupId:item.groupId,
        }
      });
    },
    // 課題一覧(グループ指定)
    onClicktoPoHomeworkList(item) {
      this.$router.push({
        name: "PoHomeworkListForGroup",
        params: {
          groupName:item.groupName,
          groupId:item.groupId,
        }
      });
    },
    // 生徒一覧
    onClicktoPoUserList(item) {
      this.$router.push({
        name: "PoUserList",
        params: {
          groupName:item.groupName,
          groupId:item.groupId,
        }
      });
    },
    // 学習のまとめ一覧
    onClicktoPoStoryListForGroup(item) {
      this.$router.push({
        name: "PoStoryListForGroup",
        params: {
          groupId:item.groupId,
        }
      });
    },
  },
};
</script>