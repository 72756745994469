<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          対象生徒選択({{ groupName }})
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="2" justify="center">
        <v-btn color="grey" class="ml-auto mt-0 black--text" @click="toPoGroupSelect" width="90%">戻る</v-btn>
      </v-col>
      <v-col cols="8" justify="center">
        <v-card class="mx-auto text-center">
          <v-card-actions class="justify-center">
            <v-btn color="blue" icon outlined @click="checkAllStudents"><v-icon>mdi-plus</v-icon></v-btn>
            全選択/全解除
            <v-btn color="red" icon outlined @click="uncheckAllStudents"><v-icon>mdi-minus</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="2" justify="center">
        <v-btn color="accent" class="ml-auto mt-0 black--text" :disabled="invalid" width="90%" @click="toPoHomeworkRegist">課題登録</v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :headers="studentHed"
          :items="students"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          :loading="isListLoding" loading-text="読込中">
          <template #[`item.studentFlg`]="{ item }">
            <v-simple-checkbox
                v-model="item.studentFlg"
                hide-details
                color="indigo"
                style="width: 25px" 
                class="pa-0 ma-0 ml-5"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row dense :justify="center">
      <v-col cols="2">
        <v-btn color="grey" class="ml-auto mt-0 black--text" @click="toPoGroupSelect" width="90%">戻る</v-btn>
      </v-col>
      <v-col cols="8" justify="center">
        <v-card class="mx-auto text-center">
          <v-card-actions class="justify-center">
            <v-btn color="blue" icon outlined @click="checkAllStudents"><v-icon>mdi-plus</v-icon></v-btn>
            全選択/全解除
            <v-btn color="red" icon outlined @click="uncheckAllStudents"><v-icon>mdi-minus</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="2" justify="center">
        <v-btn color="accent" class="ml-auto mt-0 black--text" :disabled="invalid" width="90%" @click="toPoHomeworkRegist">課題登録</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['groupId','groupName'],
  data() {
    return {
      studentHed: [
        { text: "氏名", value: "userNm", align: "start"},
        { text: "課程", value: "kateiNm" },
        { text: "部", value: "buNm" },
        { text: "科", value: "kaNm" },
        { text: "学年", value: "gakunen" },
        { text: "学級", value: "gakkyu" },
        { text: "出席番号", value: "syussekiNo" },
        { text: "対象", value: "studentFlg" },
      ],
      students: [],
      isListLoding: false,
      isSearch : false,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    await this.loadTargetSelect();
  },
  methods: {
    async loadTargetSelect(){
      const data = {
        funcName: "loadTargetSelect",
        groupId: this.groupId,
      };
      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((ret) => {
        this.students = ret.data.students;
      });
      this.isListLoding = false;
      this.isSearch = true;
    },
    // ユーザー区分変更：生徒
    checkAllStudents() {
      for (const user of this.students) {
        user.studentFlg = true;
      }
    },
    uncheckAllStudents() {
      for (const user of this.students) {
        user.studentFlg = false;
      }
    },

    // 課題登録画面へ
    toPoHomeworkRegist(){
      var studentIds = [];

      // 対象集計
      for (const user of this.students) {
        if(user.studentFlg){
          studentIds.push(user.loginId)
        }
      }
      if (studentIds.length == 0) {
        alert("対象を選択してください");
        return;
      }

      this.$router.push({
        name: "PoHomeworkRegist",
        params: {
          groupName: this.groupName,
          groupId: this.groupId,
          paramStudentIds: studentIds,
        }
      });
    },
    // グループ選択画面遷移
    toPoGroupSelect(){
      this.$router.push({
        name: "PoGroupSelect",
      });
    },
  },
};
</script>