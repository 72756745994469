<template>
  <div class="text-center">
    <!-- 画像サムネイル表示 -->
    <v-card
      :height="imgHeight"
      :width="imgWidth"
    >
      <v-img
        v-if="image"
        :max-height="imgHeight"
        :max-width="imgWidth"
        contain
        style="cursor:zoom-in"
        @click="view"
        :src="image">
        <div class="d-flex justify-end">
          <v-spacer></v-spacer>
          <v-tooltip left v-if="image">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs" v-on="on"
                fab
                depressed
                x-small
                color="transparent"
                style="cursor:zoom-in"
                @click="view"
              >
                <v-icon>mdi-magnify-plus-outline</v-icon>
              </v-btn>
            </template>
            <span>拡大</span>
          </v-tooltip>
        </div>
      </v-img>
      <div v-if="!image">指定画像なし<div>
    </v-card>
    <!-- ファイル選択 -->
    <v-file-input 
      v-model="file"
      :disabled="readOnlyFlg"
      :error-messages="errorMsg"
      @change="fileChange"
      placeholder="画像を選択してください"
      dense
      accept=".png, .jpg, .jpeg"
      prepend-icon="mdi-image"
      class="mt-0 pt-0"
      truncate-length="15"
      hide-details="auto"
      show-size>
    </v-file-input>
    <v-dialog
      v-model="viewDialog"
      scrollable
    >
      <v-img
        :src="image"
        style="cursor:pointer"
        contain
        @click="close"
      >
      </v-img>
    </v-dialog>
  </div>
</template>
  
<script lang="ts">
import { getStorage, getMetadata, getBlob, ref, uploadBytes } from "firebase/storage";

export default {
  // 親画面との値連動設定
  props: ["value", "height", "width", "readOnly", "errorMsg"],
  computed: {
    tempId: {
      get() {return this.value;},
      set(newValue) {this.$emit("input", newValue);},
    },
  },
  // 当コンポーネントの変数
  data() {
    return {
      image: "",
      file: null,
      readOnlyFlg: false,
      imgHeight: 150,
      imgWidth: 250,
      viewDialog: false,
    }
  },
  async created() {
    // 初期表示時
    if(this.readOnly == true)this.readOnlyFlg = true;
    if(this.height)this.imgHeight = this.height;
    if(this.width)this.imgWidth = this.width;
    if(this.value) {
      // DL準備
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var path = storagePath.replace("{uuid}", this.value);
      const mountainsRef = ref(storage, path);
      // ファイル名の取得
      var fileName = "";
      await getMetadata(mountainsRef).then((metadata) => {
        fileName = metadata.customMetadata.fileName;
      })
      .catch((error) => {
        console.log(error);
      });
      // ファイルダウンロード
      await getBlob(mountainsRef).then((ret) => {
        // 画面オブジェクトに設定
        this.file = new File([ret], fileName, { type: ret.type });
        this.image = window.URL.createObjectURL(this.file);
      });
    }
  },
  methods: {
    // ファイル変更時
    async fileChange(file) {
      if(file != null){
        // ファイルサイズチェック
        if(file.size > 5242880) {
          alert("ファイルサイズが5MBを超過しています。サイズの縮小をお願いします。");
          this.tempId = "";
          this.image = "";
          this.file = null;
          return;
        }
        // ファイル設定時
        // ファイルアップロード
        const storage = getStorage(this.$firebase);
        const storagePath = this.$root.uploadPath;
        // ランダム文字列生成
        const refId = crypto.randomUUID();
        var path = storagePath.replace("{uuid}", refId);
        const mountainsRef = ref(storage, path);
        var fileName = 'inline; filename="{DL_FILE_NAME}";'.replace('{DL_FILE_NAME}', file.name);
        var encfileName = encodeURI(file.name);
        const metaData = {
            contentType: file.type,
            contentDisposition: fileName + "filename*=UTF-8''" + encfileName, 
            customMetadata: {
                'fileName': file.name,
            }
        };
        const uploadTask = uploadBytes(mountainsRef, file, metaData);
        await uploadTask.then(() => {
          this.tempId = refId;
          this.image = window.URL.createObjectURL(file);
        }).catch((e) => {
          console.error(e);
          alert("アップロードに失敗しました。再度選択をお願いします。");
          this.tempId = "";
          this.image = "";
          this.file = null;
        });
      } else {
        // ファイルクリア時
        this.tempId = "";
        this.image = "";
      }
    },
    // 原寸画像ダイアログを表示
    view(){
      if(this.image) {
        this.viewDialog = true;
      }
    },
    // 原寸画像ダイアログを閉じる
    close(){
      this.viewDialog = false;
    },
  }
}
</script>