<template>
  <v-card color="white">
    <v-row no-gutters>
      <v-col cols="12" class="pa-2">
        <v-card class="text-center" color="blue-grey" dark>
          対象選択
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="text-center py-2" cols="4" sm="4" md="2" lg="2" xl="2">
        <span v-if="exsitsStudents">生徒</span>
        <v-tooltip bottom v-if="exsitsStudents">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="blue" v-bind="attrs" v-on="on" small icon outlined @click="checkAllStudents"><v-icon>mdi-plus</v-icon></v-btn>
          </template>
          <span>生徒を全て選択する</span>
        </v-tooltip>
        <v-tooltip bottom v-if="exsitsStudents">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="red" v-bind="attrs" v-on="on" small icon outlined @click="uncheckAllStudents"><v-icon>mdi-minus</v-icon></v-btn>
          </template>
          <span>生徒を全て解除する</span>
        </v-tooltip>
      </v-col>
      <v-col class="text-center py-2" cols="4" sm="4" md="2" lg="2" xl="2">
        <span v-if="exsitsParents">保護者</span>
        <v-tooltip bottom v-if="exsitsParents">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="blue" v-bind="attrs" v-on="on" small icon outlined @click="checkAllParents"><v-icon>mdi-plus</v-icon></v-btn>
          </template>
          <span>保護者を全て選択する</span>
        </v-tooltip>
        <v-tooltip bottom v-if="exsitsParents">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="red" v-bind="attrs" v-on="on" small icon outlined @click="uncheckAllParents"><v-icon>mdi-minus</v-icon></v-btn>
          </template>
          <span>保護者を全て解除する</span>
        </v-tooltip>
      </v-col>
      <v-col class="text-center py-2" cols="4" sm="4" md="2" lg="2" xl="2">
        <span v-if="exsitsTeachers">教職員</span>
        <v-tooltip bottom v-if="exsitsTeachers">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="blue" v-bind="attrs" v-on="on" small icon outlined @click="checkAllTeachers"><v-icon>mdi-plus</v-icon></v-btn>
          </template>
          <span>教職員を全て選択する</span>
        </v-tooltip>
        <v-tooltip bottom v-if="exsitsTeachers">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="red" v-bind="attrs" v-on="on" small icon outlined @click="uncheckAllTeachers"><v-icon>mdi-minus</v-icon></v-btn>
          </template>
          <span>教職員を全て解除する</span>
        </v-tooltip>
      </v-col>
      <v-col class="px-2 py-2" cols="7" sm="8" md="3" lg="3" xl="3">
        <!-- 検索用のテキスト入力 -->
        <v-text-field
          v-model="userNmFilterValue"
          label="氏名ｶﾅ検索"
          placeholder="氏名ｶﾅ検索"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-text-field>
      </v-col>
      <v-col class="px-2 py-2" cols="5" sm="2" md="2" lg="2" xl="2">
        <v-switch 
          v-model="viewTargetOnly"
          dense
          class="mt-1"
          label="選択者のみ表示"
          hide-details="auto"
        />
      </v-col>
      <v-col class="d-flex justify-end" cols="12" sm="2" md="1" lg="1" xl="1">
        <v-btn color="accent" class="mr-2 my-2 black--text" @click="closeDialog">
          適用
        </v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row no-gutters>
      <v-col cols="12">
        <v-tabs
          v-model="tabSelect"
          fixed-tabs
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows>
          <v-tab :disabled="!viewTab1" href="#students">
            生徒と保護者
          </v-tab>
          <v-tab :disabled="!viewTab2" href="#teachers">
            教職員
          </v-tab>

          <v-tab-item value="students">
            <v-col cols="12">
              <v-data-table
                :headers="studentHed"
                :items="filteredItems"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
                mobile-breakpoint="0"
                :loading="isListLoding" loading-text="読込中">

                <template #[`item.mobile`]="{ item }">
                  <!-- モバイル用まとめ -->
                  <v-row dense>
                    <v-col cols="12" justify="center">
                      <!-- １行目(生徒氏名) -->
                      <v-row no-gutters>
                        <v-col cols="9" class="py-2 text-caption">
                          {{ item.userNm }}
                        </v-col>
                        <v-col cols="3" class="py-2 d-flex text-caption">
                          生徒
                          <v-simple-checkbox
                            v-model="item.studentFlg"
                            hide-details
                            class="ml-2"
                            color="indigo"
                          />
                        </v-col>
                      </v-row>
                      <!-- ２行目(課程部科学年学級出席番号) -->
                      <v-row no-gutters>
                        <v-col cols="9" class="py-2 text-caption">
                          {{ item.KBKGCS }}
                        </v-col>
                        <v-col cols="3" class="py-2 d-flex text-caption">
                          保護者
                          <v-simple-checkbox
                            v-model="item.parentFlg"
                            v-if="item.existsParentFlg"
                            hide-details
                            class="ml-2"
                            color="indigo"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </template>
                <template #[`item.studentFlg`]="{ item }">
                  <v-simple-checkbox
                      v-model="item.studentFlg"
                      hide-details
                      color="indigo"
                  />
                </template>
                <template #[`item.parentFlg`]="{ item }">
                  <v-simple-checkbox
                      v-model="item.parentFlg"
                      v-if="item.existsParentFlg"
                      hide-details
                      color="indigo"
                      class="pa-0 ma-0 ml-5"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-tab-item>
          <v-tab-item value="teachers">
            <v-col cols="12">
              <v-data-table
                :headers="teacherHed"
                :items="teachers"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
                mobile-breakpoint="0"
                :loading="isListLoding" loading-text="読込中">
                <template #[`item.targetFlg`]="{ item }">
                  <v-simple-checkbox
                      v-model="item.targetFlg"
                      hide-details
                      color="indigo"
                      style="width: 25px" 
                      class="pa-0 ma-0 ml-5"
                      :disabled="item.lockFlg"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-card>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import Enumerable from "linq";
export default {
  props: ['groupId', 'studentIds', 'parentIds', 'teacherIds'],
  data() {
    return {
      userNmFilterValue: "",
      // 対象のみ選択フィルタ
      viewTargetOnly: false,

      studentHed: [
        { text: "", value: "mobile", class: "d-none", cellClass: "d-sm-none"},
        { text: "氏名(ｶﾅ)", value: "userNm", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.userNmFilter},
        { text: "課程/部/科/学年/学級/出席番号", value: "KBKGCS", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "生徒", value: "studentFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "保護者", value: "parentFlg", class: " hidden-xs-only", cellClass: "hidden-xs-only" },
      ],
      students: [],
      teacherHed: [
        { text: "氏名(ｶﾅ)", value: "userNm", class: "hidden-xs-only", filter: this.userNmFilter},
        { text: "", value: "targetFlg", class: "hidden-xs-only", filter: this.targetFilter },
      ],
      teachers: [],
      isListLoding: false,
      exsitsStudents: false,
      exsitsParents: false,
      exsitsTeachers: false,
      viewTab1 : true,
      viewTab2 : true,
      tabSelect : "",
    };
  },
  // 初期表示時データ読み込み
  async created() {
    await this.loadTargetSelect();
  },
  // 親画面でグループID変更時
  watch: {
    async groupId(newValue) {
      console.log("groupId change!", newValue);
      await this.loadTargetSelect();
    },
  },
  computed: {
    // 対象フィルタメソッド
    filteredItems() {
      return this.students.filter((i) => {
        return (this.viewTargetOnly)?(i.studentFlg || i.parentFlg):true;
      })
    }
  },
  methods: {
    userNmFilter(value) {
      if (!this.userNmFilterValue) {
        return true;
      }
      return value.toString().includes(this.userNmFilterValue);
    },
    // 対象フィルタ
    targetFilter(value) {
      if (!this.viewTargetOnly) {
        return true;
      }
      return value;
    },
    async loadTargetSelect(){
      const data = {
        funcName: "loadTargetSelect",
        groupId: this.groupId,
      };
      this.students = [];
      this.teachers = [];
      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((ret) => {
        this.students = ret.data.students;
        this.teachers = ret.data.teachers;
      });
      if(this.teachers.length > 0){
        this.viewTab2 = true;
        this.exsitsTeachers = true;
        this.tabSelect = "teachers";
      } else {
        this.viewTab2 = false;
      }
      if(this.students.length > 0){
        this.viewTab1 = true;
        this.exsitsStudents = true;
        this.tabSelect = "students";
      } else {
        this.viewTab1 = false;
      }
      this.isListLoding = false;
      var parentList = Enumerable.from(this.students).where(i => i.existsParentFlg == true).toArray();
      if(parentList.length > 0){
        this.exsitsParents = true;
      }

      // 引数を元に設定
      if(this.studentIds.length > 0 || this.parentIds.length > 0){
        var studentIdsSet = new Set(this.studentIds);
        var parentIdsSet = new Set(this.parentIds);
        for(let row of this.students){
          row.studentFlg = false;
          row.parentFlg = false;
          if(studentIdsSet.has(row.loginId))row.studentFlg = true;
          if(parentIdsSet.has(row.loginId))row.parentFlg = true;
        }
      }
      if(this.teacherIds.length > 0){
        var teacherIdsSet = new Set(this.teacherIds);
        for(let row of this.teachers){
          row.targetFlg = false;
          if(teacherIdsSet.has(row.loginId))row.targetFlg = true;
        }
      }
    },
    // ユーザー区分変更：生徒
    checkAllStudents() {
      for (const user of this.students) {
        user.studentFlg = true;
      }
    },
    uncheckAllStudents() {
      for (const user of this.students) {
        user.studentFlg = false;
      }
    },
    // ユーザー区分変更：保護者
    checkAllParents() {
      for (const user of this.students) {
        if(user.existsParentFlg){
          user.parentFlg = true;
        }
      }
    },
    uncheckAllParents() {
      for (const user of this.students) {
        if(user.existsParentFlg){
          user.parentFlg = false;
        }
      }
    },
    // ユーザー区分変更：教員
    checkAllTeachers() {
      for (const user of this.teachers) {
        user.targetFlg = true;
      }
    },
    uncheckAllTeachers() {
      for (const user of this.teachers) {
        if(user.lockFlg == false){
          user.targetFlg = false;
        }
      }
    },
    // 対象選択クローズ
    closeDialog(){
      this.userNmFilterValue = "";
      this.viewTargetOnly = false;

      var studentIdsRet = [];
      var parentIdsRet = [];
      var teacherIdsRet = [];
      var userKbn = new Set();
      // 対象集計
      for (const user of this.students) {
        if(user.studentFlg){
          studentIdsRet.push(user.loginId)
          userKbn.add('0');
        }
        if(user.parentFlg && user.existsParentFlg){
          parentIdsRet.push(user.loginId);
          userKbn.add('1');
        }
      }
      for (const user of this.teachers) {
        if(user.targetFlg){
          teacherIdsRet.push(user.loginId);
          userKbn.add('8');
        }
      }

      this.$emit('setUserKbns', Array.from(userKbn));
      this.$emit('setStudentIds', studentIdsRet);
      this.$emit('setParentIds', parentIdsRet);
      this.$emit('setTeacherIds', teacherIdsRet);
      // 親コンポーネントのメソッド呼び出し
      this.$emit('closeDialog');
    },
  },
};
</script>