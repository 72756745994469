<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          生徒・保護者割当({{ groupName }})
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="left">
        <v-card class="mx-auto text-left" color="white">
          課程・部・科・学年を指定し、検索した後に割当を行ってください。(※学級は任意指定となります)
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="white">
          <ValidationObserver ref="obs" v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="2" justify="center">
                <ValidationProvider
                  name="課程"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                    label="課程"
                    :items="kateiMst"
                    @change="changeKBK"
                    item-text="name"
                    item-value="code"
                    v-model="selectedKatei"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :disabled="isSearch"
                    hide-details="auto"
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="2" justify="center">
                <ValidationProvider
                  name="部"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                    label="部"
                    :items="buMst"
                    @change="changeKBK"
                    item-text="name"
                    item-value="code"
                    v-model="selectedBu"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :disabled="isSearch"
                    hide-details="auto"
                    ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="2" justify="center">
                <ValidationProvider
                  name="科"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                    label="科"
                    :items="kaMst"
                    @change="changeKBK"
                    item-text="name"
                    item-value="code"
                    v-model="selectedKa"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :disabled="isSearch"
                    hide-details="auto"
                    ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="2" justify="center">
                <ValidationProvider
                  name="学年"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                <v-select
                  label="学年"
                  :items="gakunenMst"
                  item-text="name"
                  item-value="name"
                  v-model="selectedGakunen"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  :disabled="isSearch"
                  hide-details="auto"
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="2" justify="center">
                <v-select
                  label="学級"
                  :items="gakkyuMst"
                  item-text="name"
                  item-value="name"
                  v-model="selectedGakkyu"
                  outlined
                  dense
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :disabled="isSearch"
                  hide-details="auto"
                  ></v-select>
              </v-col>
              <v-col cols="1" justify="center">
                <v-btn color="grey" class="ml-auto mt-0 black--text" @click="onClear" :disabled="!isSearch">クリア</v-btn>
              </v-col>
              <v-col cols="1" justify="center">
                <v-btn color="accent" class="ml-auto mt-0 black--text" @click="onSearch" :disabled="isSearch||invalid">検索</v-btn>
              </v-col>
          </v-row>
          </ValidationObserver>
        </v-card> 
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2" >
        <div align="left">
          <v-btn color="grey" class="black--text" @click="onClicktoGroupList" width="90%">戻る</v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6" justify="center">
        <v-card class="mx-auto text-center">
          <v-layout wrap>
            <v-flex>
              <v-card-actions class="justify-center">
                生徒
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="blue" v-bind="attrs" v-on="on" icon outlined @click="checkAllStudents" :disabled="!isSearch"><v-icon>mdi-plus</v-icon></v-btn>
                  </template>
                  <span>全員選択します</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red" v-bind="attrs" v-on="on" icon outlined @click="uncheckAllStudents" :disabled="!isSearch"><v-icon>mdi-minus</v-icon></v-btn>
                  </template>
                  <span>全員選択から除外します</span>
                </v-tooltip>
              </v-card-actions>
            </v-flex>
            <v-flex>
              <v-card-actions class="justify-center">
                保護者
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="blue" v-bind="attrs" v-on="on" icon outlined @click="checkAllParents" :disabled="!isSearch"><v-icon>mdi-plus</v-icon></v-btn>
                  </template>
                  <span>全員選択します</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red" v-bind="attrs" v-on="on" icon outlined @click="uncheckAllParents" :disabled="!isSearch"><v-icon>mdi-minus</v-icon></v-btn>
                  </template>
                  <span>全員選択から除外します</span>
                </v-tooltip>
              </v-card-actions>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
        <v-card-actions>
          <v-radio-group
            v-model="viewHistoryKbn"
            hide-details="auto"
            class="mt-0"
            row
          >
            <v-radio
              label="履歴共有しない"
              value="0"
            ></v-radio>
            <v-radio
              label="履歴共有する"
              value="1"
            ></v-radio>
          </v-radio-group>
        </v-card-actions>
      </v-col>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
        <div align="right">
          <v-btn color="accent" class="black--text" @click="onClicktoGroupAttach" width="90%" :disabled="!isSearch">更新</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="userList"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          :loading="isListLoding" loading-text="読込中"
          dense
        >
          <!-- 生徒所属チェックボックス -->
          <template #[`item.attachStudentFlg`]="{ item }">
            <v-simple-checkbox
                v-model="item.attachStudentFlg"
                :disabled="item.attachParentFlg"
                hide-details
                color="indigo"
                style="width: 25px" 
                class="pa-0 ma-0 ml-5"
            />
          </template>
          <!-- 保護者所属チェックボックス -->
          <template #[`item.attachParentFlg`]="{ item }">
            <v-simple-checkbox
                v-model="item.attachParentFlg"
                @click="onClickParent(item)"
                hide-details
                color="indigo"
                style="width: 25px" 
                class="pa-0 ma-0 ml-5"
            />
          </template>
      </v-data-table>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2" >
        <div align="left">
          <v-btn color="grey" class="black--text" @click="onClicktoGroupList" width="90%">戻る</v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
      </v-col>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
        <div align="right">
          <v-btn color="accent" class="black--text" @click="onClicktoGroupAttach" width="90%" :disabled="!isSearch">更新</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import Enumerable from "linq";
export default {
  props: ['groupName','groupId'],
  data() {
    return {
      // KBK制御
      kateiMst: [],
      buMst: [],
      kaMst: [],
      gakunenMst: [],
      gakunenMstBase: [],
      gakkyuMst: [],
      gakkyuMstBase: [],
      selectedKatei: "01",
      selectedBu: "",
      selectedKa: "",
      selectedGakunen: "",
      selectedGakkyu: [],
      isSearch : false,

      headers: [
        { text: "学級", value: "gakkyu", align: "start"},
        { text: "出席番号", value: "syussekiNo", align: "start"},
        { text: "生徒氏名", value: "userNmStudent"},
        { text: "保護者氏名", value: "userNmParent"},
        { text: "生徒割当", value: "attachStudentFlg" },
        { text: "保護者割当", value: "attachParentFlg" },
      ],
      userList: [],
      isListLoding: false,
      viewHistoryKbn: "0",
    };
  },
  async created() {
    console.log("props",this.groupName,this.groupId);
    // KBKGCの取得
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    await schoolAidHomeLogined({funcName:"commonGetKBKG"}).then((ret) => {
      this.kateiMst = ret.data.kateiMst;
      this.buMst = ret.data.buMst;
      this.kaMst = ret.data.kaMst;
      this.gakunenMstBase = ret.data.gakunenMst;
      this.gakkyuMstBase = ret.data.gakkyuMst;
    });
  },
  methods: {
    // 課程・部・科設定後のセレクトボックス変更
    changeKBK() {
      if(this.selectedKatei
       && this.selectedBu
       && this.selectedKa){
        // 学年マスタをKBKでフィルタ
        this.gakunenMst = Enumerable.from(this.gakunenMstBase).where(i => 
        i.code.substring(0, 8) == (
          this.selectedKatei
          + "-"
          + this.selectedBu
          + "-"
          + this.selectedKa
          )).toArray();
        this.selectedGakunen = "";
        // 学級マスタをKBKでフィルタ
        this.gakkyuMst = Enumerable.from(this.gakkyuMstBase).where(i => 
        i.code.substring(0, 8) == (
          this.selectedKatei
          + "-"
          + this.selectedBu
          + "-"
          + this.selectedKa
          )).toArray();
        this.selectedGakkyu = [];
      }
    },
    // クリア
    async onClear() {
      var updateStudents = this.userList.filter(user => user.attachStudentFlg !== user.attachStudentFlgBefore);
      var updateParents = this.userList.filter(user => user.attachParentFlg !== user.attachParentFlgBefore);

      if(updateStudents.length > 0 || updateParents.length > 0){
        var result = confirm("変更した割当は反映されません、よろしいですか？");
        if (!result) {
          return;
        }
      }

      this.userList = [];
      this.isSearch = false;
    },
    // 検索
    async onSearch() {
      const data = {
        funcName: "searchGroupAttach",
        groupId: this.groupId,
        katei: this.selectedKatei,
        bu: this.selectedBu,
        ka: this.selectedKa,
        gakunen: this.selectedGakunen,
        gakkyu: this.selectedGakkyu,
      };
      this.userList = [];

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((ret) => {
        var buff = [];
        ret.data.forEach((user) => {
          var row = {
            gakkyu: user.gakkyu,
            syussekiNo: user.syussekiNo,
            userIdStudent: user.userIdStudent,
            userNmStudent: user.userNmStudent,
            userIdParent: user.userIdParent,
            userNmParent: user.userNmParent,
            attachStudentFlg: user.attachStudentFlg,
            attachStudentFlgBefore: user.attachStudentFlg,
            attachParentFlg: user.attachParentFlg,
            attachParentFlgBefore: user.attachParentFlg,
          };
          buff.push(row);
        });
        this.userList = buff;
        this.isListLoding = false;
      });
      this.isSearch = true;
    },
    checkAllStudents() {
      for (const user of this.userList) {
        user.attachStudentFlg = true;
      }
    },
    uncheckAllStudents() {
      for (const user of this.userList) {
        user.attachStudentFlg = false;
        user.attachParentFlg = false;
      }
    },
    checkAllParents() {
      for (const user of this.userList) {
        user.attachStudentFlg = true;
        user.attachParentFlg = true;
      }
    },
    uncheckAllParents() {
      for (const user of this.userList) {
        user.attachParentFlg = false;
      }
    },
    onClickParent(item) {
      if(item.attachParentFlg){
        item.attachStudentFlg = true;  
      }
    },
    async onClicktoGroupAttach() {
      const data=  {
        funcName: "updateGroupAttach",
        groupId: this.groupId,
        viewHistoryKbn: this.viewHistoryKbn,
        updateStudents: this.userList.filter(user => user.attachStudentFlg !== user.attachStudentFlgBefore),
        updateParents: this.userList.filter(user => user.attachParentFlg !== user.attachParentFlgBefore),
      }

      if(data.updateStudents.length == 0 && data.updateParents.length == 0){
        alert("更新対象がありません。");
        return;
      }

      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        if (res.data === "success") {
              alert("保存しました。");
              this.userList = [];
              this.activeClear = false;
              this.activeSearch = true;
              this.isSearch = false;
              this.viewHistoryKbn = "0";
        } else {
              alert("保存に失敗しました");
        }
      });
    },
    // グループ設定画面へ(戻るボタン)
    onClicktoGroupList() {
      this.$router.push({
        name: "GroupList",
      });
    },
  },
};
</script>