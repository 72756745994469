<template>
  <v-container fluid>
    <v-card
      class="d-flex justify-center flex-column mx-auto my-6 flat"
      width="374"
      color="#fff"
    >
      <v-card-title class="d-flex justify-center pa-0 mt-6"
        >利用規約の確認</v-card-title
      >
      <v-card-text class="d-flex justify-center flex-column">
        <v-textarea readonly label="利用規約本文" 
         style="white-space:pre; font-size: small;" 
         rows="11"
         v-model="textfield"
         hide-details="auto"
        >
        </v-textarea>
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column">
        <v-row>
          <v-col cols="1" class="pr-0">
            <v-checkbox v-model="mycheck" label="" hide-details="auto" />
          </v-col>
          <v-col cols="11" class="pt-6">
            <p>利用規約及び<a href="#" @click.prevent.stop="privacyPolicyClick()">プライバシーポリシー</a>に同意する。</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="accent" class="black--text" @click="consentSave" :disabled="!this.mycheck" width="100%"
          >次へ</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  data() {
    return {
      mycheck: false,
      textfield: `「SCHOOLAID  学校支援システム」  利用規約
第１条（本規約の目的）
１．本規約は、「SCHOOLAID  学校支援システム」（以下、当サイトという）の利用に関して、教職員及び生徒及び保護者（以下、利用者等という）と「当サイト」の運営者であるキヤノン電子テクノロジー株式会社（以下、当社という)との間に適用されるものとします。


第２条（著作権） 
「当サイト」の著作権は当社に帰属します。「利用者等」としての利用以外の、 複製、転載、頒布、販売、改変、その他著作権侵害となるような行為は禁止されております。


第３条（個人情報の取り扱いについて）
１．「当社」では「利用者等」が登録する個人情報を第三者に提供することはございません。
２．「当サイト」に登録する個人情報は、「当サイト」のサービスを利用するために入力する情報であり、「当社」が利用することはございません。ただし、「利用者等」本人及び学校様より「当サイト」の調査などの依頼があった場合は、調査のために参照する可能性がございます。
３．入力した個人情報の開示は学校様受付窓口にお問い合わせください。


第４条（「当サイト」のサービス停止） 
１．「当社」は利用者等に事前に通知することなく、以下の理由により当サイトの全部又は一部を停止又は中断することができるものとします。
・サービスの提供に必要なコンピューター・システム等の点検又は保守作業を定期的又は緊急に行う場合
・コンピューター、通信回線等が事故（当社の責に帰すべき事由によるものであるか否かを問わない）により停止した場合
・火災、停電、天災地変などの不可抗力により当サイトの運営ができなくなった場合
・その他、「当社」が停止又は中断を必要と判断した場合


第５条（「当サイト」の変更、終了） 
「当社」は「利用者等」に事前の通知なく「当サイト」の運営の諸条件、 サービス内容の変更を行うことができるものとします。また営業上、技術上の理由等により「当サイト」を終了することができるものとします。 


第６条(連絡・通知)
「当サイト」に関する問い合わせ、連絡もしくは通知、その他「当社」から「利用者等」に対する連絡もしくは通知は、メール連絡や「当サイト」への掲示など、「当社」の定める方法で行うものとします。
第７条（免責） 
「利用者等」の誤入力や着信拒否等の設定に起因するメール不達などにより「利用者等」に損害・損失が発生した場合、 天災、紛争、暴動その他当社の責めに帰すことのできない不可抗力の事由により、 「利用者等」に損害が発生した場合のいずれについても、「当社」は一切の損害賠償等の責任を負わないものとします。 


第８条（本規約の変更） 
「当社」は運営上の都合等により、本規約を随時変更できるものとします。 その場合は変更後の規約を速やかに「当サイト」内に掲示するものとします。 


第９条（合意管轄裁判所） 
「当社」と利用者等の間で、訴訟の必要が生じた場合には東京地方裁判所を第一審の専属的合意管轄裁判所とします。 


【推奨環境】
    ・スマートフォン／タブレット
      OS：iOS 15以上/Android 10.0以上
      ブラウザ：iOS標準ブラウザ(Safari) /Android標準ブラウザ(Chrome)

    ・パソコン
      OS：Windows 8.1、10 、11/ Mac OS Ⅹ10.9以上
      ブラウザ： Edge / Google Chrome / Safari

※推奨環境下でのご利用の場合でも、お客様のPC・スマートフォン・タブレット・ブラウザ等の設定およびご使用の端末によっては、動作や表示が正しく行われない場合がございます。あらかじめご了承ください。`,
    };
  },

  methods: {
    // 利用規約同意保存
    async consentSave() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");

      await schoolAidHomeLogined({funcName:"saveConsent"}).then((res) => {
        this.$root.loading = false;
        // ログインフラグを立てる
        this.$root.isLogin = true;
        
        var userKbn = res.data.userKbn;
        var adminFlg = res.data.adminFlg;
        var nextPage = "";

        // ユーザー区分に応じた制御
        switch (userKbn) {
          case "0":
            nextPage = "StudentTopPage";
            this.$root.isStudentMenu = true;
            break;
          case "1":
            nextPage = "GuardianTopPage";
            this.$root.isParentMenu = true;  
            break;
          case "8":
            nextPage = "TeacherTopPage";
            this.$root.isKyoinMenu = true;
            if(adminFlg == true){
              this.$root.menuPattern = 2;
            } else {
              this.$root.menuPattern = 1;
            }
            break;
          case "9":
            nextPage = "TeacherTopPage";
            this.$root.isKyoinMenu = true;
            this.$root.menuPattern = 2;
            break;
          default:
            break;
        }
        // 利用規約同意済
        this.$router.push({
          name: nextPage
        });
      });
    },
    // プライバシーポリシー情報ページを開く
    async privacyPolicyClick() {
      var data = {
        funcName: "getPrivacyPolicyUrl",
      };
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomePublic = httpsCallable(functions, "schoolAidHomePublic");
      this.windowReference = window.open();
      await schoolAidHomePublic(data).then((res) => {
        this.$root.loading = false;
        this.windowReference.location = res.data.privacyUrl;
      });
    }
  },
};
</script>