<template>
  <v-container fluid>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title>
          <v-row dense>
            <v-col cols="1">
              <v-btn color="grey" class="mx-auto black--text" @click="canselPoHomeworkEdit">戻る</v-btn>
            </v-col>
            <v-col cols="10">
              <div class="d-flex justify-center">
                課題編集
              </div>
            </v-col>
            <v-col cols="1">
              
            </v-col>
          </v-row>
        </v-card-title>
        <v-row dense>
          <v-col cols="12" justify="center">
            <div class="mx-10 mt-0">
              <div>対象グループ</div>
              <v-layout wrap>
                {{ groupName }}
              </v-layout>
            </div>
          </v-col>
        </v-row>
        <ValidationProvider name="配信課題名" rules="required|max:50" v-slot="{ errors }">
          <div class="mx-10 mt-4">
            <v-text-field
            v-model="title"
            prepend-icon="$cet_required"
            label="配信課題名"
            placeholder=""
            :error-messages="errors[0]"
            outlined
            dense
            :counter="50"
            hide-details="auto"
            ></v-text-field>
          </div>
        </ValidationProvider>
        <ValidationProvider name="説明" rules="required|max:3000" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="body"
              prepend-icon="$cet_required"
              label="説明"
              placeholder=""
              :error-messages="errors[0]"
              outlined
              dense
              :counter="3000"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>

        <ValidationProvider name="配信開始日" rules="required" v-slot="{ errors }">
          <div class="mx-10 mt-0"> 
            <v-text-field
              v-model="openDate"
              prepend-icon="$cet_required"
              label="配信開始日"
              placeholder=""
              type="datetime-local"
              :error-messages="errors[0]"
              outlined
              dense
              hide-details="auto"
              ></v-text-field>
          </div>
        </ValidationProvider>

        <ValidationProvider name="提出期日" rules="required|datehourAfter:@配信開始日|datehourBefore:@配信終了日" v-slot="{ errors }">
          <div class="mx-10 mt-2"> 
            <v-text-field
              v-model="limitDate"
              prepend-icon="$cet_required"
              label="提出期日"
              placeholder=""
              type="datetime-local"
              :error-messages="errors[0]"
              outlined
              dense
              hide-details="auto"
              ></v-text-field>
          </div>
        </ValidationProvider>

        <ValidationProvider name="配信終了日" rules="required|datehourAfter:@提出期日" v-slot="{ errors }">
          <div class="mx-10 mt-2"> 
            <v-text-field
              v-model="closeDate"
              prepend-icon="$cet_required"
              label="配信終了日"
              placeholder=""
              type="datetime-local"
              :error-messages="errors[0]"
              outlined
              dense
              hide-details="auto"
              ></v-text-field>
          </div>
        </ValidationProvider>
        <v-card-text justify="center">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    ファイル名
                  </th>
                  <th class="text-right">
                    削除
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in viewfileList"
                  :key="item.attachmentId"
                >
                  <td class="text-left"><a @click="downloadItem(item)" class="text-decoration-none">{{ item.fileName }}</a></td>
                  <td class="text-right">
                    <v-row class="justify-end">
                      <v-checkbox
                        v-model="selectData"
                        :value="item.attachmentId"
                        :id="item.attachmentId"
                        color="primary"
                        hide-details="auto"
                      />
                  </v-row>
                  </td>     
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <dashboard :uppy="uppy" :props="{
              theme: 'light',
              inline: true,
              width: 3000,
              height: 350,
              disableThumbnailGenerator:true,
              showRemoveButtonAfterComplete:true,
              proudlyDisplayPoweredByUppy:false,
            }" />
            ※添付ファイルは(容量：300MB、個数：10)が上限となっております
        </v-card-text>
        <div class="mx-10 mt-0">
          <v-row dense>
            <v-col cols="12">
              <v-switch class="ml-3 mt-1 mb-2" label="活動記録名を生徒が指定する" v-model="editNameFlg" hide-details="auto"></v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch class="ml-3 mt-1 mb-2" label="活動記録テンプレートを生徒が指定する" v-model="useTemplateFlg" :disabled="true" hide-details="auto"></v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch class="ml-3 mt-1 mb-2" label="活動記録カテゴリーを生徒が指定する" v-model="useCategoryFlg" :disabled="true" hide-details="auto"></v-switch>
            </v-col>
          </v-row>
        </div>
        <v-card-actions>
          <v-btn color="grey" class="mr-auto mt-0 black--text" @click="canselPoHomeworkEdit"  width="45%">戻る</v-btn>
          <v-btn color="accent" class="ml-auto mt-0 black--text" @click="savePoHomeworkEdit" :disabled="invalid || (!tounendoFlg)" width="45%">更新</v-btn>
        </v-card-actions>  
      </v-card>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { Dashboard } from '@uppy/vue'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import ja_JP from '../../../plugins/puppy_lang'
import FirebaseCloudStorage from "../../../plugins/FirebaseCloudStorage";
import { getStorage } from "firebase/storage";
import { httpsCallable, getFunctions } from "firebase/functions";
import { saveAsCommon } from '../../../common';
export default {
  // 初期処理宣言
  beforeRouteEnter(to, from, next){next(vm => {vm.setup()})},
  props: ['homeworkId', 'backPageName'],
  // ファイルアップロード実装 START-------------------------
  components: {
    Dashboard
  },
  computed: {
    uppy() {
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var uppy = new Uppy()
      .use(FirebaseCloudStorage, { storage, storagePath });
      return uppy;
    },
  },
  mounted() {
    this.uppy.on('upload', () => {
      this.$root.loading = true;
    });
    this.uppy.on('upload-success', (file) => {
      // 配列にファイルを追加
      this.fileList.add(file.meta.refId);
    });
    this.uppy.on('complete', () => {
      this.$root.loading = false;
    });
    this.uppy.on('file-removed', (file) => {
      // 配列からファイルを削除
      this.fileList.delete(file.meta.refId);
    });
    // 50MB制限+10ファイル制限
    this.uppy.setOptions({
      debug: true,
      locale: ja_JP,
      autoProceed: true,
      restrictions: {
        maxFileSize: (300 * 1024 * 1024),
        maxNumberOfFiles: 10,
      },
    });
  },
  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },
  // ファイルアップロード実装 END-------------------------
  data() {
    return {
      groupId: "",
      groupName: "",
      title: "",
      body: "",
      openDate: "" ,
      limitDate: "",
      closeDate : "",
      viewfileList: [],
      fileList: new Set(),
      selectData: [],
      clearFlg: false,
      tounendoFlg: false,
      lockOpenDateFlg: false,
      editNameFlg: true,
      useTemplateFlg: true,
      useCategoryFlg: true,
    };
  },
  methods: {
    async setup() {
      // ローディング開始
      this.$root.loading = true;
      const data = {
        funcName: "poLoadHomeworkEdit",
        homeworkId: this.homeworkId
      };
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((res) => {
        this.groupId = res.data.groupId;
        this.groupName = res.data.groupName;
        this.title = res.data.title;
        this.body = res.data.body;
        this.openDate = res.data.openDate;
        this.limitDate = res.data.limitDate;
        this.closeDate = res.data.closeDate;
        this.viewfileList = res.data.fileList;
        this.tounendoFlg = res.data.tounendoFlg;
        this.lockOpenDateFlg = res.data.lockOpenDateFlg;
        this.editNameFlg = res.data.editNameFlg;
        this.useTemplateFlg = res.data.useTemplateFlg;
        this.useCategoryFlg = res.data.useCategoryFlg;

        // ローディング終了
        this.$root.loading = false;
      }).catch((e) => {
        console.log(e.message);
        this.$root.loading = false;
      });
    },
    // 課題登録
    async savePoHomeworkEdit() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "poSaveHomeworkEdit",
        homeworkId: this.homeworkId,
        title: this.title,
        body: this.body,
        openDate: this.openDate,
        limitDate: this.limitDate,
        closeDate: this.closeDate,
        delFileList: this.selectData,
        fileList: Array.from(this.fileList),
        clearFlg: this.clearFlg,
        editNameFlg: this.editNameFlg,
        useTemplateFlg: this.useTemplateFlg,
        useCategoryFlg: this.useCategoryFlg,
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        if (res.data == "success"){
          alert("更新しました。");
          this.$router.push({
            name: this.backPageName,
            params: {
              groupId: this.groupId,
            }
          });
        } else {
          alert(res.data);
        }
      });
    },
    // 戻るボタン
    canselPoHomeworkEdit(){
      this.$router.push({
        name: this.backPageName,
        params: {
          groupId: this.groupId,
          groupName: this.groupName,
        }
      });
    },
    async downloadItem(item){
      saveAsCommon(item.url, item.fileName);
    },
  },
};
</script>