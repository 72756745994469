<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          生徒選択({{ groupName }})
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="2" justify="center">
        <v-btn color="grey" class="ml-auto mt-0 black--text" @click="onClicktoPoGroupSelect" width="90%">戻る</v-btn>
      </v-col>
      <v-col cols="10" justify="center">
        <!-- 検索用のテキスト入力 -->
        <v-text-field
          v-model="search"
          label="生徒氏名ｶﾅ検索"
          placeholder="生徒氏名ｶﾅ検索"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="members"
          :items-per-page.sync="perPage"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          :loading="isListLoding" loading-text="読込中"
          :search="search"
          :custom-filter="filter"
          >
          <template #[`item.userNm`]="{ item }">
            {{ item.userNm }}({{ item.userNmKana }})
          </template>
          <template #[`item.action1`]="{ item }">
            <v-icon class="ml-2" v-if="!item.syukketuApprovalFlg">mdi-check-outline</v-icon>
          </template>
          <template #[`item.action2`]="{ item }">
            <v-btn  color="accent" class="black--text" @click="onClicktoPoActivityRecordListForStudent(item)">活動記録一覧</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row class="justify-start" dense>
      <v-col cols="2" justify="center">
        <v-btn color="grey" class="ml-auto mt-0 black--text" @click="onClicktoPoGroupSelect" width="90%">戻る</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['groupName','groupId'],
  watch: {
    perPage: function(newVal) {
      this.$store.dispatch('setParPageUserList', newVal);
    },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "生徒氏名(ｶﾅ)", value: "userNm", align: "start", width: "30%" },
        { text: "課程", value: "kateiNm" },
        { text: "部", value: "buNm" },
        { text: "科", value: "kaNm" },
        { text: "学年", value: "gakunen" },
        { text: "学級", value: "gakkyu" },
        { text: "出席番号", value: "syussekiNo", width: "80" },
        { text: "未読数", value: "unreadCount", width: "80" },
        { text: "未提出数", value: "unsubmissionCount", width: "80" },
        { text: "", value: "action2", align: "end", width: "80" },
      ],
      members: [],
      isListLoding: false,
      perPage: -1,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    // グループ未選択は選択画面へ
    if(!this.groupId){
      this.onClicktoPoGroupSelect();
    }
    this.reload();
    this.perPage = this.$store.state.parPageUserList;
  },
  methods: {
    // ページ再読込
    async reload() {
      this.members = [];
      const data = {
        funcName: "poLoadUserList",
        groupId: this.groupId,
      };

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((users) => {
        var ret = [];
        users.data.forEach((user) => {
          var row = {
            userNm: user.userNm,
            userNmKana: user.userNmKana,
            kateiNm: user.kateiNm,
            buNm: user.buNm,
            kaNm: user.kaNm,
            gakunen: user.gakunen,
            gakkyu: user.gakkyu,
            syussekiNo: Number(user.syussekiNo),
            unreadCount: user.unreadCount,
            unsubmissionCount: user.unsubmissionCount,
            studentId : user.loginId,
          };
          ret.push(row);
        });
        this.isListLoding = false;
        this.members = ret;
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
    },
    // 氏名カナ検索
    filter(value, search, item) {
      if(!item.userNmKana)return false;
      return item.userNmKana.toString().includes(search)
    },
    // グループ選択に戻る
    onClicktoPoGroupSelect(){
      this.$router.push({
        name: "PoGroupSelect"
      });
    },
    // 活動記録一覧(生徒別)を表示
    onClicktoPoActivityRecordListForStudent(item){
      this.$router.push({
        name: "PoActivityRecordListForStudent",
        params: {
          studentId: item.studentId,
          groupId: this.groupId,
          groupName: this.groupName,
        }
      });
    },
  },
};
</script>