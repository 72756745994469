<template>
  <v-card color="white">
    <v-row no-gutters>
      <v-col cols="10" class="d-flex justify-center pa-2">
          下書き選択
      </v-col>
      <v-col cols="2" class="d-flex justify-end pa-2">
        <v-btn color="grey" small class="black--text mt-1" @click="close">閉じる</v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row no-gutters>
      <v-col cols="9" class="d-flex pa-2">
        <!-- 検索用のテキスト入力 -->
        <v-text-field
          v-model="search"
          label="検索ワード"
          placeholder="検索ワード"
          outlined
          dense
          clearable
          hide-details="auto"
          background-color="white"
        ></v-text-field>
      </v-col>
      <v-col cols="3" class="d-flex justify-end pa-2">
        <v-switch 
          v-model="viewFavoriteOnly"
          dense
          class="mt-1"
          label="お気に入りのみ"
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row no-gutters>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="draftList"
          :search="search"
          items-per-page="7"
          hide-default-header
          disable-sort
          mobile-breakpoint="0"
          :loading="isListLoding" loading-text="読込中">

          <template #[`item`]="{ item }">
            <v-row no-gutters>
              <v-col cols="8" class="d-flex flex-column text-caption justify-start mt-2 px-4">
                <span>登録日時：{{ item.insDate }}</span>
                <span>タイトル：{{ item.title }}</span>
              </v-col>
              <v-col cols="4" class="d-flex justify-end my-2 px-4">
                <v-tooltip bottom v-if="!item.viewBodyFlg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small outlined class="mt-1 mx-2" v-bind="attrs" v-on="on" @click="viewBody(item)"><v-icon>mdi-eye-outline</v-icon></v-btn>
                  </template>
                  <span>本文を表示する</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.viewBodyFlg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small outlined class="mt-1 mx-2" v-bind="attrs" v-on="on" @click="viewBody(item)"><v-icon>mdi-eye-off</v-icon></v-btn>
                  </template>
                  <span>本文を表示しない</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.favoriteFlg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small outlined class="mt-1 mx-2" v-bind="attrs" v-on="on" @click="changeFavoriteDraft(item)"><v-icon color="orange">mdi-star</v-icon></v-btn>
                  </template>
                  <span>お気に入りを解除する</span>
                </v-tooltip>
                <v-tooltip bottom v-if="!item.favoriteFlg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small outlined class="mt-1 mx-2" v-bind="attrs" v-on="on" @click="changeFavoriteDraft(item)"><v-icon>mdi-star-outline</v-icon></v-btn>
                  </template>
                  <span>お気に入りに追加する</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small outlined class="mt-1 mx-2" v-bind="attrs" v-on="on" @click="delDraft(item)">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>下書きを削除する</span>
                </v-tooltip>

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green" v-bind="attrs" v-on="on" small class="black--text mt-1 mx-2" @click="setDraft(item)">反映</v-btn>
                  </template>
                  <span>呼出元画面にタイトルと本文を反映します</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="item.viewBodyFlg">
              <v-col cols="12" class="d-flex px-4 mb-0">
                <v-textarea
                  v-model="item.body"
                  label="本文"
                  placeholder=""
                  readonly
                  auto-grow
                  outlined
                  dense
                  style="font-size:1em"
                  background-color="grey lighten-3"
                  :counter="3000"
                  hide-details="auto"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['draftType', 'openTime'],
  data() {
    return {
      search : "",
      viewFavoriteOnly: false,
      headers: [
        {text: "", value: "title", cellClass: "d-none"},
        {text: "", value: "body", cellClass: "d-none"},
        {text: "", value: "favoriteFlg", cellClass: "d-none", filter: this.favoriteFilter},
        {text: "", value: "delFlg", cellClass: "d-none", filter: this.delFlgFilter},
      ],
      draftList: [],
      isListLoding: false,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    await this.loadDraftList();
  },
  // 親画面でグループID変更時
  watch: {
    async openTime(newValue) {
      console.log("reOpen!", newValue);
      await this.loadDraftList();
    },
  },
  methods: {
    // お気に入りフィルタ
    favoriteFilter(value) {
      if (!this.viewFavoriteOnly) {
        return true;
      }
      return value;
    },
    // 削除行の非表示
    delFlgFilter(value) {
      return !value;
    },
    // 下書き一覧の読み込み
    async loadDraftList(){
      // 検索条件のクリア
      this.search = "";
      this.viewFavoriteOnly = false;

      const data = {
        funcName: "loadDraftList",
        draftType: this.draftType,
      };
      this.draftList = [];
      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((ret) => {
        this.draftList = ret.data;
      });

      this.isListLoding = false;
    },
    // 下書きの削除
    async delDraft(item){
      var result = confirm("削除してよろしいですか？");
      if (!result) {
        return;
      }

      const data = {
        funcName: "delDraft",
        id: item.id,
      };
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data);
      // 明細を非表示
      item.delFlg = true;
    },
    // お気に入り切り替え
    async changeFavoriteDraft(item)  {      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "changeFavoriteDraft",
        id: item.id,
        favoriteFlg: item.favoriteFlg
      };
      await schoolAidHomeLogined(data);
      item.favoriteFlg = !item.favoriteFlg;
    },
    // 親画面へ下書き反映
    setDraft(item){
      var data = {
        title: item.title,
        body: item.body,
      }
      // 親コンポーネントのメソッド呼び出し
      this.$emit('setDraft', data);
      this.$emit('closeDialog');
    },
    // 本文の表示を切り替える
    viewBody(item){
      item.viewBodyFlg = !item.viewBodyFlg;
    },
    // ダイアログを閉じる
    close(){
      this.$emit('closeDialog');
    },
  },
};
</script>